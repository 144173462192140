<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex row wrap="false" xs4 sm4 md4 lg4 box pa4>
        <v-btn @click="confirm">Initiate Password Reset</v-btn>
      </v-flex>
      <v-flex row wrap="false" xs4 sm4 md4 lg4 box pa4>
        <v-icon v-if="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex row wrap="false" xs4 sm4 md4 lg4 box pa4>
        <v-icon v-if="success">check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'password',
    data() {
      return {
        error: false,
        success: false,
      };
    },
    methods: {
      confirm() {
        this.$refs.confirm
          .open(
            'Initiate Password Reset',
            'Are you sure you want to initiate a password reset? This will send a reset email to ' +
              this.$store.getters.account.email
          )
          .then((confirm) => {
            if (confirm) {
              this.submit();
            } else {
              //no-op
            }
          });
      },
      submit() {
        axios
          .post(
            this.resetPasswordURL(),
            JSON.stringify({
              accountID: this.$store.getters.account.accountID,
            }),
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.success = true;
            this.error = false;
          })
          .catch((err) => {
            this.error = true;
          });
      },
      resetPasswordURL() {
        return (
          this.$store.getters.serviceURL + '/accounts/admin/forgotpassword'
        );
      },
    },
  };
</script>
