<template>
  <v-container>
    <v-layout row>
      <v-flex>
        <v-alert
          v-if="isSuccess"
          :value="isSuccess"
          type="success"
          dismissible
          @input="success = ''"
        >
          {{ success }}
        </v-alert>
        <v-alert
          v-if="isError"
          :value="isError"
          type="error"
          dismissible
          @input="error = ''"
        >
          {{ error }}
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row pb-5>
      <v-flex>
        <h2>
          <b>{{ roleAction }} Roles In Bulk via .CSV</b>
        </h2>
      </v-flex>
    </v-layout>
    <v-layout row pb-4>
      <DomainsSelector
        @domainsSelected="
          (newDomain) => {
            this.domainsSelected = newDomain;
          }
        "
      />
      <v-flex pl-5>
        <v-switch v-model="isAdd" :label="`${roleAction} Roles`"></v-switch>
      </v-flex>
    </v-layout>
    <v-layout row pb-5>
      <DomainRolesSelector
        :domain="domainsSelected"
        @newRoles="
          (newRoles) => {
            this.domainRolesSelected = newRoles;
          }
        "
      />
    </v-layout>
    <v-layout row pb-5>
      <GameRolesSelector
        :domains-selected="domainsSelected"
        @gameRoleUpdate="
          (newRoles) => {
            this.gamesSelected = newRoles;
          }
        "
      />
    </v-layout>
    <v-layout row pb-3>
      <CsvSelector
        @filePicked="
          (newFile) => {
            this.accountsFile = newFile;
          }
        "
      >
        <div>
          "persona_id","email_address","domain_id"
          <br />
          "PersonaID1","email1@wizards.com","wizards"
          <br />
          "PersonaID2","email2@wizards.com","wizards"
          <br />
        </div>
      </CsvSelector>
    </v-layout>
    <v-layout row>
      <v-btn :disabled="!isPageValid" @click.stop="updateRoles">Update</v-btn>
      <Poller
        :showing="dialog"
        :initialBulkChangeDetails="bulkJobDetails"
        :ready="processing"
        :isCreate="false"
        @create="updateRoles"
        @hide="dialog = false"
        @done="processing = false"
      ></Poller>
    </v-layout>
  </v-container>
</template>

<script>
  import DomainsSelector from './DomainsSelector';
  import GameRolesSelector from './GameRolesSelector';
  import DomainRolesSelector from './DomainRolesSelector';
  import CsvSelector from '../CsvSelector';
  import Poller from './Poller.vue';
  import moment from 'moment';
  import axios from 'axios';

  export default {
    name: 'Change-Roles',
    components: {
      CsvSelector,
      DomainsSelector,
      DomainRolesSelector,
      GameRolesSelector,
      Poller,
    },
    data() {
      return {
        isAdd: true,
        domainsSelected: '',
        domainRolesSelected: [],
        gamesSelected: [],
        accountsFile: null,
        bulkJobDetails: {
          progress: 0,
          jobID: '',
          invitationID: '',
          expiresIn: moment().add(1, 'days'),
          success: 0,
          fail: 0,
        },
        processing: false,
        dialog: false,
        error: '',
        success: '',
      };
    },
    methods: {
      updateRoles() {
        let formBody = new FormData();
        formBody.append('domainID', this.domainsSelected);
        formBody.append(
          'domainRoles',
          JSON.stringify(this.domainRolesSelected)
        );
        formBody.append('games', JSON.stringify(this.gamesSelected));
        formBody.append('isCreate', this.isAdd);
        formBody.append('accountsFile', this.accountsFile);

        axios
          .post(
            this.$store.getters.serviceURL + '/admin/bulk/roles',
            formBody,
            this.$store.getters.fileOpts
          )
          .then((response) => {
            this.bulkJobDetails = response.data;
            if (!this.bulkJobDetails.jobID) {
              throw new Error();
            } else {
              this.processing = true;
              this.dialog = true;
            }
          })
          .catch((_) => {
            this.error = 'Failed to update roles.';
            this.processing = false;
          });

        setTimeout(() => {
          this.processing = false;
        }, 1000);
      },
    },
    computed: {
      roleAction() {
        return this.isAdd ? 'Add' : 'Remove';
      },
      isPageValid() {
        let isValid = false;
        // a domain and a file were provided
        if (
          this.domainsSelected &&
          this.domainsSelected.length > 0 &&
          this.accountsFile != null
        ) {
          if (this.domainRolesSelected.length > 0) {
            isValid = true;
          }
          // invalid if a game is provided with no associated game roles
          for (let i = 0; i < this.gamesSelected.length; i++) {
            if (this.gamesSelected[i].Roles.length < 1) {
              isValid = false;
              break;
            } else {
              isValid = true;
            }
          }
        }
        return isValid;
      },
      isError() {
        return this.error.length > 0;
      },
      isSuccess() {
        return this.success.length > 0;
      },
    },
  };
</script>

<style scoped></style>
