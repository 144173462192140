<template lang="html">
  <v-container>
    <v-layout display-2 row justify-center mt-2>Generating Codes</v-layout>
    <v-layout row justify-center mt-3>
      <v-progress-circular :value="progress" size="100">
        {{ progress }} %
      </v-progress-circular>
    </v-layout>
    <v-layout display-2 row justify-center mt-2>
      {{ batchDetails.availableCodes }} / {{ batchDetails.totalCodes }}
    </v-layout>
    <v-layout v-if="progress == 100" row justify-center mt-2>
      <v-btn @click="$emit('close')" color="success">done</v-btn>
    </v-layout>
  </v-container>
</template>

<script lang="js">
    export default  {
      name: 'batch-creation-poller',
      props: ['offerID','initalBatchDetails'],
      mounted() {
        this.batchDetails = this.initalBatchDetails
        this.canPoll = true
        this.pollID = setTimeout(this.fetchBatchDetails, this.pollInterval)
      },
      unmounted() {
        this.canPoll = false
        clearTimeout(this.pollID)
      },
      data() {
        return {
          canPoll: false,
          pollID: 0,
          pollInterval: 500,
          batchDetails: {
            id: '',
            tag: '',
            totalCodes: 1, //default to 1 to prevent divide by 0
            domainID: '',
            availableCodes: 0,
          },
        }
      },
      methods: {
        fetchBatchDetails() {
          return this.$store.dispatch('fetchRedemptionBatch', {offerID: this.offerID, batchID: this.batchDetails.id}).then(resp => {
            this.batchDetails = resp.data;
            if (this.canPoll && this.batchDetails.availableCodes != this.batchDetails.totalCodes) {
              this.pollID = setTimeout(this.fetchBatchDetails, this.pollInterval)
            }
          });
        }
      },
      computed: {
        progress() {
          let percentage = Math.floor((this.batchDetails.availableCodes / this.batchDetails.totalCodes) * 100)
          return Math.min(percentage, 100)
        },
      }
  }
</script>

<style scoped lang="scss">
  .batch-creation-poller {
  }
</style>
