<template lang="html">
  <v-container>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
    <v-snackbar
      v-model="showSuccessSnackbar"
      :timeout="3000"
      color="success"
      top
    >
      Update Successful!
      <v-btn flat @click.native="showSuccessSnackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout row justify-center>
      <v-container px-0 pt-0>
        <!-- Header -->
        <v-layout row justify-center>
          <v-flex xs12 sm12 md12 lg12>
            <v-toolbar>
              <v-toolbar-title>Offer Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="info" :disabled="saveDisabled" @click="saveChanges">
                Save Offer
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>

        <v-layout row justify-center>
          <v-flex xs12 sm12 md12 lg12>
            <v-form ref="form">
              <v-container>
                <v-layout row justify-center>
                  <!-- Col 1 -->
                  <v-layout column justify-center>
                    <v-container>
                      <v-layout row justify-center>
                        <v-flex>
                          <v-text-field
                            name="offerName"
                            label="Name"
                            v-model="offer.name"
                            :rules="[validationRules.required]"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout row justify-center>
                        <v-flex mt-3>
                          <v-textarea
                            box
                            auto-grow
                            name="offerDesc"
                            label="Description"
                            v-model="offer.description"
                            :rules="[validationRules.required]"
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>

                  <!-- Col 2 -->
                  <v-layout column justify-center>
                    <v-container>
                      <v-layout row justify-left mb-3>
                        <v-flex>
                          <date-time-picker
                            title="Start Date"
                            v-model="startDate"
                            hint="Start dates are only applied in the production 'wizards' domain. Test domains allowed redeem codes regardless of offer start date."
                            persistent-hint
                          />
                        </v-flex>
                      </v-layout>
                      <v-layout row justify-left>
                        <v-flex>
                          <date-time-picker
                            title="End Date"
                            v-model="endDate"
                            :rules="[
                              validationRules.required,
                              validationRules.afterStartDate,
                            ]"
                          />
                        </v-flex>
                      </v-layout>

                      <v-layout row justify-left>
                        <v-flex>
                          <v-text-field
                            v-model="offer.codeMaxUses"
                            :disabled="unlimitedUses"
                            type="number"
                            label="Maximum Code Uses"
                            hint="The number of times a single code can be redeemed."
                            persistent-hint
                            :color="warnForUseCount ? 'warning' : 'primary'"
                            :rules="[
                              validationRules.required,
                              validationRules.validCodeMaxUse,
                            ]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex ml-3 xs2 sm2 md2 lg2>
                          <v-checkbox
                            label="Unlimited"
                            v-model="unlimitedUses"
                            :value="offer.codeMaxUses === -1"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                      <v-layout row justify-left mt-2>
                        <v-flex>
                          <v-text-field
                            v-model="offer.accountMaxUses"
                            type="number"
                            label="Maximum Account Uses"
                            hint="The number of times an account can redeem this offer using one or more codes."
                            persistent-hint
                            :rules="[
                              validationRules.required,
                              validationRules.greaterThanZero,
                            ]"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>

    <v-layout row justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-tabs grow icons-and-text centered color="gray" v-model="activeTab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-grants">
            Grants
            <v-icon>shop</v-icon>
          </v-tab>
          <v-tab v-if="!isNewOffer" href="#tab-batches">
            Batches
            <v-icon>more_horiz</v-icon>
          </v-tab>
          <v-tab v-if="!isNewOffer" href="#tab-tags">
            Tags
            <v-icon>more</v-icon>
          </v-tab>
          <v-tab v-if="!isNewOffer" href="#tab-codes">
            Custom Codes
            <v-icon>view_list</v-icon>
          </v-tab>
          <v-tab
            v-if="!isNewOffer && permissionAllowed('admin/audit')"
            href="#tab-audit"
          >
            Audit Trail
            <v-icon>search</v-icon>
          </v-tab>
          <v-tab-item value="tab-grants">
            <GrantList
              :grants="offer.grants"
              :onRemove="removeGrant"
              v-on:addGrant="addGrant"
            ></GrantList>
          </v-tab-item>
          <v-tab-item v-if="!isNewOffer" value="tab-batches">
            <BatchList :offerID="this.offerID" />
          </v-tab-item>
          <v-tab-item v-if="!isNewOffer" value="tab-tags">
            <TagList />
          </v-tab-item>
          <v-tab-item v-if="!isNewOffer" value="tab-codes">
            <CodeList :offerID="this.offerID" />
          </v-tab-item>
          <v-tab-item
            v-if="!isNewOffer && permissionAllowed('admin/audit')"
            value="tab-audit"
          >
            <Audit :targetIDProp="this.offerID"></Audit>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="js">

  import DateTimePicker from './DateTimePicker.vue';
  import GrantList from './GrantList.vue';
  import BatchList from './BatchList.vue';
  import TagList from './TagList.vue';
  import CodeList from './CodeList.vue';
  import moment from 'moment';
  import _ from 'lodash';
  import Audit from '../Audit.vue'

  export default  {
    name: 'offer-details',
    props: ['offerID'],
    components: {
      DateTimePicker,
      GrantList,
      BatchList,
      TagList,
      CodeList,
      Audit,
    },
    created() {
      let now = moment()
      this.startDate = now.format('YYYY-MM-DDTHH:mm:SS') + 'Z';
      this.endDate = now.add(1,'minutes').format("YYYY-MM-DDTHH:mm:SS") + 'Z';
      if (!_.isNil(this.offerID)) {
        this.$store.dispatch('fetchRedemptionOffer', this.offerID)
        .then(() => {
          this.offer = _.cloneDeep(this.$store.getters.redemptionCurrentOffer);
          if (this.offer.codeMaxUses == -1) {
            this.unlimitedUses = true;
          }
          this.startDate = moment.utc(this.offer.startDate).local().format("YYYY-MM-DDTHH:mm:SS");
          this.endDate = moment.utc(this.offer.endDate).local().format("YYYY-MM-DDTHH:mm:SS");
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.hasChanges) {
        let confirmed = window.confirm("Leaving this page will lose any unsaved changes. Do you want to continue?")
        if (!confirmed) {
          next(false)
          return
        }
      }
      next()
    },
    data() {
      return {
        activeTab: null,
        hasChanges: false,
        unlimitedUses: false,
        showSuccessSnackbar: false,
        offer: {
          id: "",
          name: "",
          description: "",
          startDate: "2019-06-03T00:00:00Z",
          endDate: "2019-06-03T00:00:01Z",
          grants: [
          ],
          codeMaxUses: 1,
          accountMaxUses: 1
        },
        startDate: "2019-06-03T00:00:00Z",
        endDate: "2019-06-03T00:00:00Z",
        formIsValid: true,
        validationRules: {
          required: value => !!value || 'Required',
          greaterThanZero: value => value > 0 || 'Must be greater than 0',
          validCodeMaxUse: value => (value === -1 || value > 0) || 'Must be greater than 0 or set to unlimited',
          afterStartDate: value => moment.utc(this.offer.endDate).isAfter(moment.utc(this.offer.startDate)) || 'Must be after start date'
        }
      }
    },
    methods: {
      saveChanges() {
        let confirmed = Promise.resolve(true); //assume confirmed in case the confirmation is not needed

        if (this.warnForUseCount) {
          confirmed = this.$refs.confirm.open("High Maximum Code Uses",
            `A large value for Maximum Code Uses where 'Unlimited' would be appropriate may be inefficient. Really save this offer with ${this.offer.codeMaxUses} uses?`,
            {
              color: 'warning',
              width: 350
            })
        }

        confirmed.then(confirmResult => {
          if (confirmResult && this.$refs.form.validate()) {
            if (this.isNewOffer) {
              this.$store.dispatch('createRedemptionOffer', this.createPayload())
              .then((newOffer) => {
                this.hasChanges = false;
                this.$router.push(`/redemption/offers/${newOffer.id}`).catch(e=>{});
              })
            } else {
              this.$store.dispatch('updateRedemptionOffer', this.createPayload())
              .then((newOffer) => {
                this.offer = _.cloneDeep(newOffer);
                this.showSuccessSnackbar = true;
              })
            }
          }
        });
      },
      addGrant(newGrant) {
        this.offer.grants.push(newGrant)
      },
      removeGrant(grantIndex) {
        this.offer.grants.splice(grantIndex,1)
      },
      createPayload() {
        let payload = _.cloneDeep(this.offer);
        payload.codeMaxUses = parseInt(payload.codeMaxUses);
        payload.accountMaxUses = parseInt(payload.accountMaxUses);
        return payload;
      },
      updateHasChanges() {

        if (this.isNewOffer) {
          //when it's a new offer, always act like we have changes
          this.hasChanges = true;
          return;
        }

        let state = this.$store.getters.redemptionCurrentOffer;
        let startIsEqual = Math.abs(moment(this.offer.startDate).diff(moment(state.startDate), 'minute')) <= 1;
        let endIsEqual = Math.abs(moment(this.offer.endDate).diff(moment(state.endDate), 'minute')) <= 1;
        let grantsAreEqual = _.isEqual(this.offer.grants, state.grants)

        let isEqual = startIsEqual
        && endIsEqual
        && grantsAreEqual
        && this.offer.name == state.name
        && this.offer.description == state.description
        && this.offer.codeMaxUses == state.codeMaxUses
        && this.offer.accountMaxUses == state.accountMaxUses;

        this.hasChanges = !isEqual;
      },
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      }
    },
    computed: {
      isNewOffer() {
        return _.isNil(this.offerID)
      },
      saveDisabled() {
        if (this.isNewOffer) {
          return false
        } else {
          return !this.hasChanges
        }
      },
      warnForUseCount() {
      return this.offer.codeMaxUses >= 200
      }
    },
    watch: {
      offer: {
        deep: true,
        handler: function(newVal, oldVal) {
          this.updateHasChanges()
        },
      },
      unlimitedUses(newVal, oldVal) {
        if (newVal) {
          this.offer.codeMaxUses = -1
        } else {
          this.offer.codeMaxUses = 1
        }
      },
      startDate() {
        this.offer.startDate = moment(this.startDate).utc().format('YYYY-MM-DDTHH:mm:SS') + 'Z'
      },
      endDate() {
        this.offer.endDate = moment(this.endDate).utc().format('YYYY-MM-DDTHH:mm:SS') + 'Z'
      },
    }
  }
</script>

<style scoped></style>
