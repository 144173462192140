<template>
  <div>
    <v-layout row justify-center align-center>
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <h2>
          Search Results
          <span v-if="exact">
            <em>Exact match not found, using fuzzy search...</em>
          </span>
        </h2>
        <br />
        <v-data-table
          :headers="headers"
          :items="results"
          :rows-per-page-items="rowsOptions"
          :loading="loading"
          :must-sort="true"
        >
          <template slot="items" slot-scope="props">
            <tr
              @click="
                loadResult(props.item.source.id, props.item.source.user_type)
              "
            >
              <td>{{ props.item.score }}</td>
              <td>{{ props.item.source.display_name }}</td>
              <td>{{ props.item.source.email }}</td>
              <td>{{ props.item.source.id }}</td>
              <td>{{ props.item.source.user_type || 'account' }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    name: 'searchResults',
    props: {
      domain: String,
      exact: Boolean,
    },
    data() {
      return {
        loading: false,
        rowsOptions: [10],
        headers: [
          { text: 'Rank', align: 'left', sortable: true, value: 'score' },
          {
            text: 'Display Name',
            align: 'left',
            sortable: true,
            value: 'source.display_name',
          },
          {
            text: 'Email Address',
            align: 'left',
            sortable: true,
            value: 'source.email',
          },
          {
            text: 'Account or Persona ID',
            align: 'left',
            sortable: true,
            value: 'source.id',
          },
          {
            text: 'Type',
            align: 'left',
            sortable: true,
            value: 'source.user_type',
          },
        ],
      };
    },
    watch: {
      results() {
        this.loading = false;
      },
    },
    methods: {
      loadResult(account, userType) {
        let url = '/edit/' + this.domain + '/' + account;
        if (userType == 'externalpersona') {
          url = '/editexternal/' + this.domain + '/' + account;
        }
        this.$store.commit('setSearchResults', []);
        this.$router.push(url);
      },
    },
    computed: {
      results() {
        return this.$store.getters.searchResults;
      },
      totalItems() {
        return this.$store.getters.searchResultCount;
      },
    },
  };
</script>

<style scoped>
  h2 span {
    font-size: 12px;
    font-weight: normal;
    color: #a22;
    padding-left: 25px;
  }
  tr {
    cursor: pointer;
  }
</style>
