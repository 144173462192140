<template lang="html">
  <div>
    <v-toolbar>
      <v-toolbar-title><h2>Code Status</h2></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="hasStatus" @click="hasStatus = false">Go Back</v-btn>
    </v-toolbar>
    <div class="overlay-container">
      <form v-if="!hasStatus" @submit.prevent="lookupCode">
        <v-container>
          <v-layout row justify-center>
            <v-flex xs4 ma-2>
              <v-select
                :items="domains"
                v-model="domain"
                label="Domain ID"
              ></v-select>
            </v-flex>
            <v-flex xs4 ma-2>
              <v-text-field
                name="code"
                label="Code"
                id="code"
                v-model="code"
              ></v-text-field>
            </v-flex>
            <v-flex xs2 ma-2 mt-3 align-self-center>
              <v-btn @click="getCodeHistory(1)">Query</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </form>
      <v-container v-else>
        <v-layout row justify-center>
          <v-flex xs3 mx-2>
            <div>
              <h2>Code</h2>
              <v-divider></v-divider>
              <div class="body-2 my-1">{{ result.code.id }}</div>
            </div>
          </v-flex>
          <v-flex xs3 mx-2>
            <div>
              <h2>Domain</h2>
              <v-divider></v-divider>
              <div class="body-2 my-1">{{ result.code.domainID }}</div>
            </div>
          </v-flex>
          <v-flex xs3 mx-2>
            <div>
              <h2>Offer ID</h2>
              <v-divider></v-divider>
              <div class="body-2 my-1">
                <a @click="navigateOffer">{{ result.code.offerID }}</a>
              </div>
            </div>
          </v-flex>
          <v-flex xs3 mx-2>
            <div>
              <h2>Times Used</h2>
              <v-divider></v-divider>
              <div class="body-2 my-1">{{ result.code.useCount }}</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row justify-center my-3>
          <v-flex xs12>
            <Redemptions
              :redemptions="result.redemptionList.redemptions"
              :count="result.redemptionList.count"
              :accountView="false"
              :enableSearch="false"
              :loadResultsByPage="true"
              v-on:paginate="getCodeHistory"
            ></Redemptions>
          </v-flex>
        </v-layout>
      </v-container>
      <v-sheet v-if="loading" class="overlay">
        <v-progress-circular
          class="centered"
          indeterminate
          size="100"
        ></v-progress-circular>
      </v-sheet>
    </div>
  </div>
</template>

<script lang="js">
    import axios from 'axios';
    import Redemptions from './RedemptionList.vue'

    export default  {
      name: 'code-status',
      props: [],
      components: {
        Redemptions,
      },
      created() {
        this.$store.dispatch('getDomains');
      },
      data() {
        return {
          loading: false,
          hasStatus: false,
          domain:"",
          code:"",
          redemptionsPerPage: 10,
          result: {
            code: {},
            redemptionList:{},
          }
        }
      },
      methods: {
        getCodeHistory(page) {
          this.loading = true;
          let serviceURL = this.$store.getters.serviceURL;
          let headers = this.$store.getters.bearerAuthHeaders;
          let offset = (page - 1) * this.redemptionsPerPage;
          axios.get(serviceURL + `/admin/redemption/history/code/${this.domain}/${this.code}?offset=${offset}&limit=${this.redemptionsPerPage}`, headers).then((resp) => {
            this.result = resp.data
            this.loading = false;
            this.hasStatus = true
          }).catch(() => {
            this.loading = false;
          })
        },
        navigateOffer() {
          this.$router.push('/redemption/offers/' + this.result.code.offerID)
        }
      },
      computed: {
        domains() {
          return this.$store.getters.allowedDomains
        },
      }
  }
</script>

<style scoped lang="scss">
  .overlay-container {
    position: relative;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
</style>
