<template>
  <v-app dark>
    <nav-bar></nav-bar>
    <main>
      <router-view></router-view>
      <jwt></jwt>
    </main>
  </v-app>
</template>

<script>
  import Vue from 'vue';
  import VueMoment from 'vue-moment';
  import MomentTZ from 'moment-timezone';
  import Moment from 'moment';
  import NavBar from './components/NavBar';
  import Jwt from './components/Jwt';
  import Confirm from './components/Confirm.vue';

  Vue.use(VueMoment, {
    MomentTZ,
  });

  Vue.component('confirmDialogue', Confirm);

  export default {
    name: 'App',
    components: { NavBar, Jwt, Confirm },
  };
</script>

<style></style>
