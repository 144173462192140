<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex xs8 sm8 md8 lg8 box pa8>
        <v-text-field
          label="First Name"
          type="text"
          :error="error"
          :success="success"
          v-model="newFirstName"
          :maxlength="maxNameLength"
        ></v-text-field>
      </v-flex>
      <v-flex xs2 sm2 md2 lg2>
        <v-btn :disabled="disableUpdate" @click="confirm">Update</v-btn>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="success">check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';

  export default {
    name: 'FirstNameForm',
    data() {
      return {
        newFirstName: '',
        initialFirstName: '',
        error: false,
        success: false,
        maxNameLength: 255,
      };
    },
    mounted() {
      this.getFirstName;
    },
    watch: {
      getFirstName: function () {
        this.newFirstName = this.getFirstName;
        // without this here the initialFirstName is showing as blank
        // on the computed disable check
        this.initialFirstName = this.getFirstName;

        // Odd case on refresh -- initial retrieval would set initial values to undefined, and then update newLastName
        // again, leaving initialLastName as undefined.
        // However, some accounts can be created without a first or last name field, so just ignore otherwise.
        if (
          _.isUndefined(this.initialFirstName) &&
          !_.isUndefined(this.newFirstName)
        )
          this.initialFirstName = this.getFirstName;
      },
    },
    methods: {
      confirm() {
        this.$refs.confirm
          .open(
            'Update First Name',
            "Are you sure you want to update this account's first name?"
          )
          .then((confirm) => {
            if (confirm) {
              this.submit(confirm);
            } else {
              this.newFirstName = this.getFirstName;
            }
          });
      },
      submit(confirm) {
        axios
          .put(
            this.$store.getters.serviceURL + '/profile/admin/' + this.accountID,
            { firstName: this.newFirstName },
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setFirstName', response.data.firstName);
            this.initialFirstName = this.newFirstName;
            this.error = false;
          })
          .catch((err) => {
            this.newFirstName = this.getFirstName;
            console.error(err);
            this.error = true;
          });
      },
    },
    computed: {
      getError() {
        return this.error;
      },
      accountID() {
        return this.$store.getters.account.accountID;
      },
      getFirstName() {
        return this.$store.getters.userProfile.firstName;
      },
      disableUpdate() {
        return (
          this.initialFirstName == this.newFirstName ||
          _.isEmpty(this.newFirstName)
        );
      },
    },
  };
</script>
