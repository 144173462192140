<template>
  <v-dialog width="610" full-width>
    <v-text-field
      :rules="rules"
      slot="activator"
      v-model="displayTime"
      :label="title"
      :hint="hint"
      :persistent-hint="persistentHint"
      readonly
    />
    <v-card width="610">
      <v-container>
        <v-layout column>
          <v-flex row style="padding-top: 4px; padding-bottom: 4px">
            <v-date-picker v-model="datePart" />
            <v-time-picker v-model="timePart" format="24hr" width="272" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'DateTimePicker',

    props: {
      title: {
        type: String,
      },

      hint: {
        type: String,
        default: '',
      },

      persistentHint: {
        type: Boolean,
        default: false,
      },

      value: {
        type: String,
      },
      rules: {},
      outline: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {
      this.lazyValue = this.value;
    },

    data: () => ({
      dateFormatRegex: new RegExp(
        '^[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}(\.[\d]{3})?Z$'
      ),
      lazyValue: '2019-01-01T00:00:00',
    }),

    methods: {
      isISO8601ZFormat(val) {
        return dateFormatRegex.test(val);
      },

      emitInput() {
        this.$emit('input', this.lazyValue);
      },
    },

    computed: {
      internalValue: {
        get() {
          return this.lazyValue;
        },
        set(val) {
          // TODO: check against regex
          this.lazyValue = val;
        },
      },

      datePart: {
        get() {
          return this.lazyValue.slice(0, 10);
        },
        set(val) {
          this.internalValue = `${val}T${this.timePart}`;
          this.emitInput();
        },
      },

      timePart: {
        get() {
          return this.lazyValue.slice(11, 19);
        },
        set(val) {
          this.internalValue = `${this.datePart}T${val}:00`;
          this.emitInput();
        },
      },
      displayTime() {
        return moment(this.lazyValue, 'YYYY-MM-DDTHH:mm:ss')
          .tz(moment.tz.guess())
          .format('LLL z');
      },
    },

    watch: {
      value(val) {
        // TODO: check against regex
        this.lazyValue = val;
      },
    },
  };
</script>
