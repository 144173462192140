<template>
  <div>
    <div v-if="valid">
      <div :if="showSuccess">
        <v-alert :value="showSuccess" type="success" dismissible>
          {{ this.domainSuccessMessage }}
        </v-alert>
      </div>
      <div :if="showError">
        <v-alert :value="showError" type="error" dismissible>
          {{ this.domainErrorMessage }}
        </v-alert>
      </div>
      <v-container>
        <v-layout row justify-left>
          <v-flex xs6 sm6 md6 lg6 box pa6>
            <v-layout row justify-left>
              <v-flex xs8 sm8 md8 lg8 box pa8>
                <v-text-field label="Account" v-model="accountID" readonly />
              </v-flex>
            </v-layout>
            <v-layout row justify-left>
              <v-flex xs8 sm8 md8 lg8 box pa8>
                <v-text-field label="Created" v-model="created" readonly />
              </v-flex>
            </v-layout>

            <v-layout row justify-left align-center>
              <v-flex xs8 sm8 md8 lg8 box pa8>
                <v-select
                  :items="allAllowedDomains"
                  :multiple="true"
                  :chips="true"
                  v-model="currentDomains"
                  @change="disableConfirmDomain"
                  label="Domain(s)"
                />
              </v-flex>
              <v-flex xs4 sm4 md4 lg4 box pa4>
                <v-btn
                  :disabled="disableConfirmDomainButton"
                  @click="confirmDomainUpdate"
                >
                  Update
                </v-btn>
              </v-flex>
            </v-layout>
            <confirm-dialogue ref="confirm" />
            <display-name />
            <email />
            <password />
            <require-reset />
            <consent-status :status="consentStatus" />
          </v-flex>
          <v-flex xs6 sm6 md6 lg6 box pa6>
            <first-name />
            <last-name />
            <country />
            <language />
            <opt-ins />
            <delete
              v-if="
                permissionAllowed(domainID + '/account_admin', false, 'delete')
              "
            />
          </v-flex>
        </v-layout>
      </v-container>
      <v-container class="pb-0 pt-0 pr-3">
        <v-layout row justify-right>
          <v-flex xs12 sm12 md12 lg12 box pa12>
            <v-btn class="black" style="float: right" @click="openNoteDialog"
              >+ Account Note</v-btn
            >
            <v-dialog v-model="noteDialog" max-width="500">
              <v-card>
                <v-card-title class="grey darken-3">
                  <h3>ACCOUNT NOTE</h3>
                </v-card-title>
                <v-card-text class="grey darken-2">
                  <v-textarea
                    v-model="note"
                    placeholder="Type here..."
                    rows="6"
                    type="textbox"
                    outline
                  >
                  </v-textarea>
                </v-card-text>
                <v-card-actions class="grey darken-3">
                  <v-spacer></v-spacer>
                  <v-btn class="close" @click="closeNoteDialog()">
                    Cancel
                  </v-btn>
                  <v-btn @click="addNoteToAccount(note)" :disabled="note == ''">
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container class>
        <v-tabs centered grow color="gray lighten-1" dark icons-and-text>
          <v-tabs-slider color="blue-grey lighten-3" />
          <v-tab href="#tab-bans">
            Bans
            <v-icon>highlight_off</v-icon>
          </v-tab>
          <v-tab href="#tab-personas">
            Personas
            <v-icon>assignment_ind</v-icon>
          </v-tab>
          <v-tab href="#tab-persona-history">
            Persona History
            <v-icon>history</v-icon>
          </v-tab>
          <v-tab
            href="#tab-domain-roles"
            v-if="permissionAllowed('domain_roles', true)"
          >
            Domain Roles
            <v-icon>toc</v-icon>
          </v-tab>
          <v-tab
            href="#tab-game-roles"
            v-if="permissionAllowed('game_roles', true)"
          >
            Game Roles
            <v-icon>dvr</v-icon>
          </v-tab>
          <v-tab href="#tab-clients-and-scopes">
            Clients & Scopes
            <v-icon>add_circle</v-icon>
          </v-tab>
          <v-tab
            href="#tab-entitlements"
            v-if="permissionAllowed('entitlements', true)"
          >
            Entitlements
            <v-icon>shopping_basket</v-icon>
          </v-tab>
          <v-tab
            href="#tab-redemptions"
            v-if="permissionAllowed('redemption', true)"
          >
            Redemptions
            <v-icon>receipt</v-icon>
          </v-tab>
          <v-tab href="#tab-audit" v-if="permissionAllowed('admin/audit')">
            Audit
            <v-icon>search</v-icon>
          </v-tab>
          <v-tab-item value="tab-bans">
            <bans />
          </v-tab-item>
          <v-tab-item value="tab-personas">
            <persona />
          </v-tab-item>
          <v-tab-item value="tab-persona-history">
            <persona-history />
          </v-tab-item>
          <v-tab-item
            value="tab-domain-roles"
            v-if="permissionAllowed('domain_roles', true)"
          >
            <roles />
          </v-tab-item>
          <v-tab-item
            value="tab-game-roles"
            v-if="permissionAllowed('game_roles', true)"
          >
            <game-roles />
          </v-tab-item>
          <v-tab-item value="tab-clients-and-scopes">
            <clients-and-scopes />
          </v-tab-item>
          <v-tab-item
            value="tab-entitlements"
            v-if="permissionAllowed('entitlements', true)"
          >
            <entitlements type="profile" />
          </v-tab-item>
          <v-tab-item
            value="tab-redemptions"
            v-if="permissionAllowed('redemption', true)"
          >
            <Redemptions
              :redemptions="redemptions"
              :count="redemptionCount"
              :accountView="true"
              :enableSearch="true"
              :loadResultsByPage="false"
            />
          </v-tab-item>
          <v-tab-item value="tab-audit" v-if="permissionAllowed('admin/audit')">
            <Audit :targetIDProp="account" />
          </v-tab-item>
        </v-tabs>
      </v-container>
    </div>
    <div v-else>
      <v-container v-if="checked">
        <v-layout row justify-center>
          <v-flex xs6 sm6 md6 lg6 box pa6>
            <h2>The Account you are looking for was not found</h2>
            <p>
              <br />
              <b>Account ID:</b>
              {{ accountID || $props.account }}
              <br />
              <b>Domain:</b>
              {{ $props.domain }}
            </p>
            <p>
              The account does not exist or you do not have the permissions to
              view it here.
            </p>
            <p>
              <v-btn @click="$router.go(-1)" color="grey darken-2">
                Go Back
              </v-btn>
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click="snackbar.visible = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import axios from 'axios';
  import DisplayName from './DisplayName.vue';
  import Email from './Email.vue';
  import FirstName from './FirstName.vue';
  import LastName from './LastName.vue';
  import Country from './Country.vue';
  import Language from './Language.vue';
  import OptIns from './OptIns.vue';
  import GameRoles from './GameRoles.vue';
  import Persona from './Persona.vue';
  import Roles from './Roles.vue';
  import Password from './Password.vue';
  import Delete from './Delete.vue';
  import Bans from './Bans.vue';
  import PersonaHistory from './PersonaHistory.vue';
  import Entitlements from './Entitlements.vue';
  import Redemptions from './redemption/RedemptionList.vue';
  import Audit from './Audit.vue';
  import RequireReset from './RequireReset';
  import ClientsAndScopes from './ClientsAndScopes.vue';
  import ConsentStatus from './ConsentStatus.vue';

  export default {
    name: 'edit',
    data() {
      return {
        valid: false,
        checked: false,
        redemptionsPerPage: 10,
        currentDomains: [], // all domains account is currently in
        initialDomains: [], // needed for disabling logic and value comparisons
        disableConfirmDomainButton: true,
        success: true,
        domainSuccessMessage: '',
        domainErrorMessage: '',
        note: '',
        noteDialog: false,
        snackbar: {
          text: 'Undefined',
          color: 'error',
          visible: false,
        },
        consentStatus: '',
        consentStatusNone: 3,
      };
    },
    props: {
      account: {
        type: String,
        default: null,
      },
      domain: {
        type: String,
        default: null,
      },
    },
    watch: {
      personaID: function () {
        this.getProfile();
      },
      accountID: function () {
        this.getConsentStatus();
      },
    },
    beforeCreated: function () {
      this.$store.commit('setIsExternalPersona', false);
    },
    created: function () {
      this.getAccount().then(() => this.getRedemptions());
      if (this.$props.domain != null) {
        this.fetchGames();
      }
    },
    mounted() {
      this.domainSuccessMessage = '';
      this.domainErrorMessage = '';
      this.$store.dispatch('getDomains');
      this.getConsentStatus;
    },
    methods: {
      closeNoteDialog() {
        this.noteDialog = false;
        this.note = '';
      },
      openNoteDialog() {
        this.noteDialog = true;
      },
      addNoteToAccount(note) {
        let formattedNote = note.replaceAll(/\r?\n/g, '\\n');
        return axios
          .put(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.$props.account +
              '/addNote',
            { Comment: formattedNote },
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.note = '';
            this.noteDialog = false;
            this.snackbar = {
              text:
                'Your note has been recorded and placed on the audit tab! Wait a minute and refresh to see it.',
              color: 'success',
              visible: true,
            };
          })
          .catch((err) => {
            this.setError('Failed to Add Note ' + note + ': ' + err);
            this.note = '';
            this.snackbar = {
              text: 'Failed to add note. ' + err,
              color: 'error',
              visible: true,
            };
          });
      },
      addDomain(domainID) {
        return axios
          .put(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.$props.account +
              '/domains',
            { domainID: domainID },
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('addDomains', response.data);
            this.success = true;
          })
          .catch((err) => {
            this.setError('Failed to Add Domain ' + domainID + ': ' + err);
            this.success = false;
          });
      },
      removeDomain(domainID) {
        // Construct the request body along with the auth header -- axios does not accept data for DELETE requests.
        let opts = this.$store.getters.bearerAuthHeaders;
        opts['data'] = { domainID: domainID };

        axios
          .delete(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.$props.account +
              '/domains',
            opts
          )
          .then((response) => {
            this.$store.commit('removeDomains', response.data);
            this.success = true;
          })
          .catch((err) => {
            this.setError('Failed to Remove Domain ' + domainID + ': ' + err);
            this.success = false;
          });
      },
      getAccount() {
        return axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.$props.account,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setAccount', response.data);
            this.setError('');
            this.valid = true;
            this.currentDomains = this.$store.getters.accountDomains;
            this.initialDomains = this.currentDomains;
          })
          .catch((err) => {
            this.checked = true;
            this.setError('Failed to Get Account: ' + err);
          });
      },
      getProfile() {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/profile/admin/persona/' +
              this.personaID,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setProfile', response.data);
            this.setError('');
          })
          .catch((err) => {
            this.setError('Failed to Get Profile: ' + err);
          });
      },
      fetchGames() {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/domain/' +
              this.$props.domain +
              '/game',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            if ('games' in response.data) {
              this.setError('');
              this.$store.commit('setGames', response.data.games);
            } else {
              this.setError(
                this.error + ' Games for ' + domain + ' undefined.'
              );
            }
          })
          .catch((err) => {
            this.setError(err);
          });
      },
      getRedemptions() {
        this.$store.dispatch('fetchAccountRedemptions', {
          accountID: this.accountID,
        });
      },
      getConsentStatus() {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.accountID +
              '/parental-consent',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            if (response.data.state) {
              this.consentStatus = response.data.state;
            } else {
              this.consentStatus = 0;
            }
          })
          .catch(() => {
            // Throws a 404 error if user does not have consentcheq status
            this.consentStatus = this.consentStatusNone;
          });
      },
      setError(err) {
        this.$data.error = '' + err;
      },
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
      disableConfirmDomain() {
        // If we haven't changed the domains from initial state, or if we are trying to remove ALL domains from the
        // account, do not allow the update.
        if (!this.currentDomains || this.currentDomains.length === 0) {
          this.disableConfirmDomainButton = true;
        } else if (
          this.currentDomains &&
          this.initialDomains &&
          this.currentDomains.length === this.initialDomains.length
        ) {
          this.disableConfirmDomainButton = true;
          for (let i = 0; i < this.currentDomains.length; i++) {
            if (this.currentDomains[i] !== this.initialDomains[i]) {
              this.disableConfirmDomainButton = false;
              break;
            }

            if (!this.disableConfirmDomainButton) {
              break;
            }
          }
        } else {
          this.disableConfirmDomainButton = false;
        }
      },
      confirmDomainUpdate() {
        let addedDomains = this.currentDomains.filter((item) => {
          return this.initialDomains.indexOf(item) < 0;
        });
        let removedDomains = this.initialDomains.filter((item) => {
          return this.currentDomains.indexOf(item) < 0;
        });
        this.domainErrorMessage = '';
        this.domainSuccessMessage = '';

        this.$refs.confirm
          .open(
            'Update Domains',
            'Please confirm the addition and/or removal of domains on this account.'
          )
          .then((confirm) => {
            if (confirm) {
              // Add each of the requested domains.
              if (addedDomains && addedDomains.length > 0) {
                addedDomains.forEach((domain) => {
                  this.addDomain(domain);
                  // Not guaranteed that every addition will succeed on the backend, so we only care about the ones that do.
                  if (this.success) {
                    this.initialDomains.push(domain);
                  }
                });
              }

              // Remove each of the requested domains.
              if (removedDomains && removedDomains.length > 0) {
                removedDomains.forEach((domain) => {
                  this.removeDomain(domain);
                  // Not guaranteed that every removal will succeed on the backend, so we only care about the ones that do.
                  if (this.success) {
                    this.$delete(
                      this.initialDomains,
                      this.initialDomains.indexOf(domain)
                    );
                  }
                });
              }

              // If the initial state and current state match, then all succeeded. If they don't, there was some error in
              // the updates, and we want to let the user know.
              let failedToAdd = this.currentDomains.filter((item) => {
                return this.initialDomains.indexOf(item) < 0;
              });
              let failedToRemove = this.initialDomains.filter((item) => {
                return this.currentDomains.indexOf(item) < 0;
              });

              // Display the error alert if we have failed to add or remove domains.
              if (
                (failedToAdd && failedToAdd.length > 0) ||
                (failedToRemove && failedToRemove.length > 0)
              ) {
                this.domainSuccessMessage = '';
                this.domainErrorMessage = 'One or more errors occurred. ';
                failedToAdd.forEach((domain) => {
                  this.domainErrorMessage.concat('Failed to add ' + domain);
                });
                failedToRemove.forEach((domain) => {
                  this.domainErrorMessage.concat('Failed to remove ' + domain);
                });
              } else {
                this.domainErrorMessage = '';
                this.domainSuccessMessage =
                  'Successfully added and/or removed the requested domain(s) from the account.';
              }
            } else {
              // no-op
            }
          });
      },
    },
    computed: {
      created() {
        return this.$store.getters.account.createdAt;
      },
      accountID() {
        return this.$store.getters.account.accountID;
      },
      redemptions() {
        return this.$store.getters.redemptions;
      },
      redemptionCount() {
        return this.redemptions ? this.redemptions.length : 0;
      },
      personaID() {
        return this.$store.getters.personaIDs &&
          this.$store.getters.personaIDs.length > 0
          ? this.$store.getters.personaIDs[0]
          : '';
      },
      domainID() {
        return this.$store.getters.account.domainID;
      },
      allAllowedDomains() {
        return this.$store.getters.getAllowedUpdateDomains;
      },
      showSuccess() {
        return (
          this.domainSuccessMessage && this.domainSuccessMessage.length > 0
        );
      },
      showError() {
        return this.domainErrorMessage && this.domainErrorMessage.length > 0;
      },
    },
    components: {
      DisplayName,
      Email,
      FirstName,
      LastName,
      Country,
      Language,
      OptIns,
      GameRoles,
      Roles,
      Persona,
      Password,
      Delete,
      Bans,
      PersonaHistory,
      Entitlements,
      Redemptions,
      Audit,
      RequireReset,
      ClientsAndScopes,
      ConsentStatus,
    },
  };
</script>

<style>
  h2 {
    color: #999;
    margin-left: 5px;
  }
  div.v-window {
    margin-top: 20px;
  }
  .v-tabs__wrapper {
    overflow: auto !important;
  }
  .has-error {
    color: #ff5252 !important;
  }
</style>
