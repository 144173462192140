<template>
  <div>
    <v-container>
      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <v-flex xs4 sm4 md4 lg4 box pa4>
          <v-form @submit="authenticate">
            <v-text-field
              label="email"
              :error="error"
              type="text"
              placeholder="jace@wizards.com"
              v-model="username"
            ></v-text-field>
            <v-text-field
              label="password"
              type="password"
              placeholds="pass1234"
              v-model="password"
            ></v-text-field>
            <v-flex xs12 text-sm-center>
              <v-btn type="submit" @click.prevent="authenticate">Login</v-btn>
            </v-flex>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';

  function urlString(data) {
    return Object.entries(data)
      .map((pair) => pair.join('='))
      .join('&');
  }

  export default {
    name: 'login',
    data() {
      return {
        username: '',
        password: '',
        error: false,
      };
    },
    methods: {
      authenticate() {
        this.$store
          .dispatch('login', {
            email: this.username,
            password: this.password,
          })
          .then((authInfo) => {
            if (!_.isEmpty(this.getRedirect)) {
              this.$router.push(decodeURIComponent(this.getRedirect));
            } else {
              this.$router.push('/landing');
            }
          })
          .catch((err) => {
            this.error = true;
          });
      },
      tokenURL() {
        return this.$store.getters.authURL + '/auth/oauth/token';
      },
      getUrlVars() {
        var vars = {};

        var parts = window.location.href.replace(
          /[?&]+([^=&]+)=([^&]*)/gi,
          function (m, key, value) {
            vars[key] = value;
          }
        );

        return vars;
      },
      getUrlParam(parameter, defaultvalue) {
        var urlparameter = defaultvalue;

        if (window.location.href.indexOf(parameter) > -1) {
          urlparameter = this.getUrlVars()[parameter];
        }

        return urlparameter;
      },
    },
    computed: {
      getRedirect() {
        return this.getUrlParam('redirectTo', '');
      },
    },
  };
</script>
