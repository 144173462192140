<template>
  <v-container>
    <h3>Apple ID Credentials</h3>
    <v-spacer />
    <v-text-field
      label="Apple Client ID"
      v-model="appleidClientID"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Client Secret"
      v-model="appleidClientSecret"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Team ID"
      v-model="appleidTeamID"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Key ID"
      v-model="appleidKeyID"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Token Issuer"
      v-model="appleidTokenIssuer"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Encoded Key"
      v-model="appleidEncodedKey"
      :disabled="formDisabled()"
    />
  </v-container>
</template>

<script>
  export default {
    name: 'AppleClientForm',
    props: [
      'gameIdentityProviderID',
      'formData',
      'savedFormData',
      'updateInProgress',
    ],
    data() {
      return {
        appleidClientID: '',
        appleidClientSecret: '',
        appleidTeamID: '',
        appleidKeyID: '',
        appleidTokenIssuer: '',
        appleidEncodedKey: '',
      };
    },
    mounted: function () {
      if (this.formData != '') {
        this.appleidClientID = this.formData.appleidClientID;
        this.appleidClientSecret = this.formData.appleidClientSecret;
        this.appleidTeamID = this.formData.appleidTeamID;
        this.appleidKeyID = this.formData.appleidKeyID;
        this.appleidTokenIssuer = this.formData.appleidTokenIssuer;
        this.appleidEncodedKey = this.formData.appleidEncodedKey;
      }
    },
    watch: {
      appleidClientID: {
        handler() {
          this.formUpdated();
        },
      },
      appleidClientSecret: {
        handler() {
          this.formUpdated();
        },
      },
      appleidTeamID: {
        handler() {
          this.formUpdated();
        },
      },
      appleidKeyID: {
        handler() {
          this.formUpdated();
        },
      },
      appleidTokenIssuer: {
        handler() {
          this.formUpdated();
        },
      },
      appleidEncodedKey: {
        handler() {
          this.formUpdated();
        },
      },
    },
    methods: {
      formDisabled() {
        //Ensures that when an update is canceled, the form reverts to the saved data, but also that we don't save blank credentials
        if (this.gameIdentityProviderID !== '' && !this.updateInProgress) {
          this.appleidClientID = this.savedFormData.appleidClientID;
          this.appleidClientSecret = this.savedFormData.appleidClientSecret;
          this.appleidTeamID = this.savedFormData.appleidTeamID;
          this.appleidKeyID = this.savedFormData.appleidKeyID;
          this.appleidTokenIssuer = this.savedFormData.appleidTokenIssuer;
          this.appleidEncodedKey = this.savedFormData.appleidEncodedKey;
        }
        return this.gameIdentityProviderID !== '' && !this.updateInProgress;
      },
      formUpdated() {
        const credentials = {
          appleidClientID: this.appleidClientID,
          appleidClientSecret: this.appleidClientSecret,
          appleidTeamID: this.appleidTeamID,
          appleidKeyID: this.appleidKeyID,
          appleidTokenIssuer: this.appleidTokenIssuer,
          appleidEncodedKey: this.appleidEncodedKey,
        };
        this.$emit('formUpdated', credentials);
      },
    },
  };
</script>
