<template>
  <v-container pa-0>
    <v-alert v-model="hasError">
      <v-layout row align-center>
        {{ error }}
        <v-spacer />
        <v-btn icon @click="error = ''"><v-icon small>close</v-icon></v-btn>
      </v-layout>
    </v-alert>
    <v-layout row justify-left align-center fill-height>
      <v-flex xs12 sm12 md12 lg12 pa12 box>
        <h2>Audit</h2>
        <v-form v-model="validSearch">
          <v-layout row>
            <v-flex md4 class="px-3">
              <v-text-field
                v-model="searchOpts.targetID"
                label="ID of target (client, account, or persona)"
                :rules="requiredRules"
                required
                :readonly="targetIDProp && targetIDProp.length > 0"
              ></v-text-field>
              <v-text-field
                v-model="searchOpts.actorID"
                label="ID of actor (client, account, or persona)"
              ></v-text-field>
            </v-flex>
            <v-flex md4 class="px-3">
              <div v-if="actionDropdown == true">
                <v-select
                  label="action"
                  v-model="searchOpts.action"
                  :items="actionItems"
                  item-text="display"
                  item-value="value"
                >
                  <template slot="append-outer">
                    <v-btn @click="actionDropdown = false" :small="true">
                      Search for action
                    </v-btn>
                  </template>
                </v-select>
              </div>
              <div v-else>
                <v-text-field v-model="searchOpts.action" label="action">
                  <template slot="append-outer">
                    <v-btn @click="actionDropdown = true" :small="true">
                      See common actions
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
              <v-text-field
                v-model="searchOpts.comment"
                label="comment"
              ></v-text-field>
            </v-flex>
            <v-flex md2 class="px-3">
              <v-select
                label="order"
                v-model="searchOpts.sortOrder"
                :items="sortItems"
                item-text="display"
                item-value="value"
              ></v-select>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <v-text-field
                  label="date range"
                  slot="activator"
                  :value="formattedRange"
                  readonly
                  append-icon="event"
                ></v-text-field>
                <date-range-picker v-model="searchOpts.dateRange">
                  <v-container class="pa-0">
                    <v-layout>
                      <v-btn @click="searchOpts.dateRange = []" flat>
                        Clear
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="menu = false" flat>OK</v-btn>
                    </v-layout>
                  </v-container>
                </date-range-picker>
              </v-menu>
            </v-flex>
            <v-flex md2 class="px-3">
              <v-container fill-height>
                <v-tooltip v-model="hasChanges" bottom max-width="200px">
                  <template v-slot:activator="{ on }">
                    <v-btn @click="getResults()" :disabled="!validSearch">
                      Search
                    </v-btn>
                  </template>
                  <span width="10">
                    Warning: You have made changes to your search fields. Click
                    SEARCH to apply them.
                  </span>
                </v-tooltip>
              </v-container>
            </v-flex>
          </v-layout>
        </v-form>
        <v-data-table :items="results" :headers="headers" hide-actions>
          <template v-slot:items="props">
            <tr @click.stop="showMore(props.item)" :key="props.item.AuditID">
              <td>{{ props.item.Timestamp.toString().substr(0, 10) }}</td>
              <td>{{ props.item.Timestamp.toString().substr(11, 8) }}</td>
              <td>
                <span v-if="props.item.ActorClient">
                  {{ 'Client: ' + props.item.ActorClient }}
                  <br />
                </span>
                <span v-if="props.item.ActorAccount">
                  {{ 'Account: ' + props.item.ActorAccount }}
                  <br />
                </span>
                <span v-if="props.item.ActorPersona">
                  {{ 'Persona: ' + props.item.ActorPersona }}
                  <br />
                </span>
              </td>
              <td>{{ props.item ? props.item.Action.Context : null }}</td>
              <td align="right"><v-icon>search</v-icon></td>
            </tr>
          </template>
          <template slot="footer">
            <tr class="paginator">
              <td colspan="5" align="center">
                <div class="justify-center">
                  <v-pagination
                    class="justify-center"
                    v-model="page"
                    :length="pageCount"
                    :total-visible="5"
                  ></v-pagination>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <AuditDetails
          v-model="showDetails"
          :details="details"
          scoped
        ></AuditDetails>
        <v-sheet v-if="loading" class="overlay">
          <v-container fill-height justify-center>
            <v-progress-circular
              indeterminate
              color="blue"
            ></v-progress-circular>
          </v-container>
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import moment from 'moment';
  import AuditDetails from './AuditDetails.vue';
  import DateRangePicker from './DateRangePicker.vue';

  export default {
    name: 'audit',
    props: {
      targetIDProp: '',
    },
    components: {
      AuditDetails,
      DateRangePicker,
    },
    data() {
      return {
        actionDropdown: true,
        redisKey: null,
        count: 1,
        page: 1,
        menu: false,
        headers: [
          { text: 'Date', value: 'Timestamp', sortable: false },
          { text: 'Time', value: 'Timestamp', sortable: false },
          { text: 'Actor', value: 'Actor', sortable: false },
          { text: 'Action', value: 'Action', sortable: false },
          { text: 'More', sortable: false, align: 'right' },
        ],
        results: [],
        searchOpts: {
          targetID: '',
          actorID: '',
          comment: '',
          action: '',
          sortOrder: 'desc',
          dateRange: [],
        },
        actionItems: [
          {
            display: 'Admin Password Reset Initiated',
            value: 'Admin Password Reset Initiated',
          },
          { display: 'Add Domain to Account', value: 'Add Domain to Account' },
          { display: 'Ban Created', value: 'Ban Created' },
          { display: 'Ban Deleted', value: 'Ban Deleted' },
          {
            display: 'Consumable Entitlement State Updated',
            value: 'Consumable Entitlement State Updated',
          },
          { display: 'Delete Braze Data', value: 'Delete Braze Data' },
          {
            display: 'Delete PlayStation Identity',
            value: 'Delete PlayStation Identity',
          },
          { display: 'Delete Steam Identity', value: 'Delete Steam Identity' },
          { display: 'Delete XBox Identity', value: 'Delete XBox Identity' },
          {
            display: 'Entitlement Consumable Created',
            value: 'Entitlement Consumable Created',
          },
          {
            display: 'Link PlayStation Identity',
            value: 'Link PlayStation Identity',
          },
          { display: 'Link Steam Identity', value: 'Link Steam Identity' },
          { display: 'Link XBox Identity', value: 'Link XBox Identity' },
          {
            display: 'Multiple Entitlements - Consumable Created',
            value: 'Multiple Entitlements - Consumable Created',
          },
          { display: 'Note Added', values: 'Note Added' },
          { display: 'Persona Creation', value: 'Persona Creation' },
          { display: 'Profile Updated', value: 'Profile Updated' },
          { display: 'Register Account', value: 'Register Account' },
          {
            display: 'Restore Persona for Account',
            value: 'Restore Persona for Account',
          },
          {
            display: 'Update Account Display Name',
            value: 'Update Account Display Name',
          },
          { display: 'Update Account Roles', value: 'Update Account Roles' },
          { display: 'Update Email Verified', value: 'Update Email Verified' },
          { display: 'Update Language', value: 'Update Language' },
          {
            display: 'Update Wizards Password',
            value: 'Update Wizards Password',
          },
        ],
        sortItems: [
          { display: 'newest first', value: 'desc' },
          { display: 'oldest first', value: 'asc' },
        ],
        hasError: false,
        error: '',
        isResetting: false,
        details: null,
        showDetails: false,
        validSearch: false,
        requiredRules: [(v) => !!v || 'required'],
        loading: false,
        hasChanges: false,
      };
    },
    mounted: function () {
      if (this.targetIDProp) {
        this.searchOpts.targetID = this.targetIDProp;
        this.getResults();
      }
    },
    watch: {
      error: function () {
        if (this.error) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      },
      page: function () {
        if (!this.isResetting) {
          this.getPage();
        }
      },
      results: function () {
        this.details = null;
      },
      searchOpts: {
        handler: function () {
          if (this.results && this.results.length > 0) {
            this.hasChanges = true;
          }
        },
        deep: true,
      },
    },
    methods: {
      humanReadableAccounts(results) {
        return new Promise((resolve, reject) => {
          if (!results) {
            resolve();
          } else {
            results.forEach((value, index, array) => {
              this.getAccountDisplay(value.ActorAccount).then((response) => {
                value.ActorAccount = response;
                if (index == array.length - 1) {
                  resolve();
                }
              });
            });
          }
        });
      },
      humanReadablePersonas(results) {
        return new Promise((resolve, reject) => {
          if (!results) {
            resolve();
          } else {
            results.forEach((value, index, array) => {
              this.getPersonaGame(value.ActorPersona).then((response) => {
                value.ActorPersona = response;
                if (index == array.length - 1) {
                  resolve();
                }
              });
            });
          }
        });
      },
      getResults() {
        this.loading = true;
        this.hasChanges = false;
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let start = null;
          let end = null;
          let dateLength = this.searchOpts.dateRange
            ? this.searchOpts.dateRange.length
            : 0;
          if (dateLength == 1) {
            let date = this.searchOpts.dateRange[0];
            start = date + 'T00:00:00Z';
            end = date + 'T23:59:59.99Z';
          } else if (dateLength > 1) {
            start = this.searchOpts.dateRange[0] + 'T00:00:00Z';
            end = this.searchOpts.dateRange[1] + 'T23:59:59.99Z';
          }
          let req = {
            method: 'post',
            url: `${this.$store.getters.serviceURL}/elasticsearch-proxy/audit/search`,
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.token,
            },
            data: {
              targetID: this.searchOpts.targetID,
              actorID: this.searchOpts.actorID,
              comment: this.searchOpts.comment,
              action: this.searchOpts.action,
              startTime: start,
              endTime: end,
              sortOrder: this.searchOpts.sortOrder,
            },
            error: '',
          };
          axios(req)
            .then((response) => {
              Promise.all([
                this.humanReadableAccounts(response.data.results),
                this.humanReadablePersonas(response.data.results),
              ]).then(() => {
                this.isResetting = true;
                this.redisKey = response.data.redisKey;
                this.count = response.data.count;
                this.results = response.data.results;
                this.page = 1;
                this.setError();
                this.$nextTick(() => {
                  this.isResetting = false;
                });
              });
            })
            .catch((error) => {
              this.setError('Unable to get audit page: ' + error);
            });
        });
      },
      getPage() {
        if (!this.redisKey) {
          getResults();
        } else {
          this.loading = true;
          this.hasChanges = false;
          this.$store.dispatch('refreshIfNeeded').then(() => {
            let req = {
              method: 'post',
              url: `${this.$store.getters.serviceURL}/elasticsearch-proxy/audit/paginated_search`,
              headers: {
                Authorization: 'Bearer ' + this.$store.getters.token,
              },
              data: {
                redisKey: this.redisKey,
                page: this.page,
              },
            };
            axios(req)
              .then((response) => {
                Promise.all([
                  this.humanReadableAccounts(response.data.results),
                  this.humanReadablePersonas(response.data.results),
                ]).then(() => {
                  this.redisKey = response.data.redisKey;
                  this.count = response.data.count;
                  this.results = response.data.results;
                  this.setError();
                });
              })
              .catch((error) => {
                this.setError('Unable to get audit page: ' + error);
              });
          });
        }
      },
      setError(error) {
        this.error = error;
        this.loading = false;
      },
      getAccountDisplay(accountID) {
        return new Promise((resolve, reject) => {
          if (!accountID) {
            resolve();
          } else {
            let req = {
              method: 'get',
              url: `${this.$store.getters.serviceURL}/admin/accounts/account/${accountID}`,
              headers: {
                Authorization: 'Bearer ' + this.$store.getters.token,
              },
            };
            axios(req)
              .then((response) => {
                resolve(`${accountID} (${response.data.email})`);
              })
              .catch(() => {
                resolve(accountID);
              });
          }
        });
      },
      getPersonaGame(personaID) {
        return new Promise((resolve, reject) => {
          if (!personaID) {
            resolve();
          } else {
            let req = {
              method: 'get',
              url: `${this.$store.getters.serviceURL}/admin/accounts/user/persona/${personaID}`,
              headers: {
                Authorization: 'Bearer ' + this.$store.getters.token,
              },
            };
            axios(req)
              .then((response) => {
                // this returns a variety of stuctures, so have to just get the first elements a lot of times.
                let level1 = response.data.User;
                let level2 = level1[Object.keys(level1)[0]];
                let level3 = level2[Object.keys(level2)[0]];
                let gameID = level3.persona.gameID;
                resolve(`${personaID} (game ${gameID})`);
              })
              .catch((error) => {
                resolve(personaID);
              });
          }
        });
      },
      showMore(item) {
        if (this.loading) {
          return;
        }
        this.details = item;
        var formattedComment = this.details.Comment.replaceAll('\\n', '\n');
        this.details.Comment = formattedComment;
        this.showDetails = true;
      },
    },
    computed: {
      pageCount: function () {
        return !this.count || this.count < 1 ? 1 : Math.ceil(this.count / 20);
      },
      watchForChanges: function () {
        return this.searchOpts;
      },
      formattedRange() {
        if (this.searchOpts.dateRange.length) {
          let range = moment(this.searchOpts.dateRange[0]).format('D MMM');
          if (this.searchOpts.dateRange[1]) {
            range +=
              ' - ' + moment(this.searchOpts.dateRange[1]).format('D MMM');
          }
          return range;
        }
      },
    },
  };
</script>

<style scoped>
  tr {
    cursor: pointer;
  }
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
</style>
