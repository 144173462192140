<template>
  <v-flex xs4 sm4 md4 lg4 box>
    <h5 class="grey--text">Domain</h5>
    <!-- a treeselect is used here so that if we decide to extend the functionality and allow the selection of
    more than one domain, the base setup already exists. -->
    <treeselect
      :clearable="false"
      :multiple="false"
      :options="domains"
      :normalizer="domainNormalizer"
      :sort-value-by="sortBySelected"
      placeholder="Select domain."
      v-model="domainsSelected"
      :max-height="600"
    />
  </v-flex>
</template>

<script>
  import Treeselect from '@riophae/vue-treeselect';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import axios from 'axios';
  export default {
    name: 'DomainsSelector',
    components: {
      Treeselect,
    },
    data() {
      return {
        sortBySelected: 'ORDER_SELECTED',
        domains: [],
        domainsSelected: '',
        error: '',
        domainNormalizer(node) {
          return {
            id: node,
            label: node,
          };
        },
      };
    },
    mounted() {
      this.fetchDomains();
    },
    methods: {
      fetchDomains() {
        axios
          .get(
            this.$store.getters.serviceURL + '/admin/accounts/domain',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.domains = response.data.domains.map((domain) => {
              return domain.domainID;
            });
            this.setError('');
          })
          .catch((err) => {
            this.error = 'Failed to Pull Domain List: ' + err;
          });
      },
    },
    watch: {
      domainsSelected(newValue) {
        this.$emit('domainsSelected', newValue);
      },
    },
  };
</script>

<style scoped></style>
