<template>
  <div>
    <v-container>
      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <v-flex xs6 sm6 md6 lg6 box pa6>
          <form @submit.prevent="promptBrazeDelete">
            <h2>Delete Braze Account</h2>
            <v-text-field
              label="External ID"
              type="text"
              placeholder="External ID"
              v-model="externalID"
              @keyup.enter.native="brazeDelete"
            ></v-text-field>
            <v-btn @click="promptBrazeDelete" :disabled="!externalID">
              Delete
            </v-btn>
          </form>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click="snackbar.visible = false">Close</v-btn>
    </v-snackbar>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import Vue from 'vue';
  import axios, { AxiosResponse } from 'axios';
  import _ from 'lodash';

  export default {
    name: 'braze-delete',
    data() {
      return {
        externalID: '',
        snackbar: {
          text: 'Undefined',
          color: 'error',
          visible: false,
        },
      };
    },
    computed: {
      serviceURL() {
        return this.$store.getters.serviceURL;
      },
    },
    methods: {
      promptBrazeDelete() {
        this.$refs.confirm
          .open(
            'Request Braze Delete',
            'Are you sure you want to delete this Braze account?  This will happen IMMEDIATELY and is permanent.',
            {
              verification: [
                {
                  id: 'requested',
                  kind: 'checkbox',
                  label: 'The user has requested this directly.',
                  valid: true,
                },
                {
                  id: 'verified',
                  kind: 'checkbox',
                  label: "I have verified this user's External ID.",
                  valid: true,
                },
              ],
              confirm: 'Delete',
              color: 'red darken-2',
              width: 350,
            }
          )
          .then((confirm) => {
            if (confirm) {
              this.brazeDelete();
            } else {
              //no-op
            }
          });
      },
      brazeDelete() {
        axios
          .delete(
            this.$store.getters.serviceURL + `/admin/braze`,
            _.merge(
              {
                data: {
                  externalID: this.externalID,
                },
              },
              this.$store.getters.bearerAuthHeaders
            )
          )
          .then((resp) => {
            this.snackbar = {
              text: 'Requested Delete for External ID: ' + this.externalID,
              color: 'success',
              visible: true,
            };

            this.externalID = '';
          })
          .catch((error) => {
            this.snackbar = {
              text: 'Failed to Request Delete',
              color: 'error',
              visible: true,
            };
          });
      },
    },
  };
</script>
