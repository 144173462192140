<template lang="html">
  <v-container px-0 pt-2>
    <v-data-table :headers="headers" :items="tags" pagination.sync="pagination">
      <template v-slot:no-data>
        <v-alert icon="info" color="info" :value="true">No Tags Found</v-alert>
      </template>
      <template slot="items" slot-scope="props">
        <tr class="text-xs-center">
          <td>{{ props.item.tag }}</td>
          <td>{{ props.item.availableCodes }}</td>
          <td>{{ props.item.totalCodes }}</td>
          <td align="right">
            <ExportDialog
              :exportRequest="{ tag: props.item.tag }"
              exportType="Tag"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="js">
    import _ from 'lodash';
    import ExportDialog from './ExportDialog.vue'

    export default  {
      name: 'tag-list',
      props: [],
      components: {
        ExportDialog,
      },
      data() {
        return {
          headers: [
          { text: 'Tag', align: 'center', value: 'tag' },
          { text: 'Available Codes', align: 'center', value: 'availableCodes'},
          { text: 'Total Codes', align: 'center', value: 'totalCodes'},
          { text: '', align: 'center', value:'id'},
          ],
        }
      },
      methods: {

      },
      computed: {
        tags() {
          let grouped = _.groupBy(this.$store.getters.redemptionCurrentBatches, (batch) => {
            if (_.isNil(batch.tag)) {
              return "nil";
            } else {
              return batch.tag;
            }
          })

          // remove the nil group since you can't export by it
          delete grouped.nil

          //tag group is an array of batches
          let newTagInfo = _.map(grouped, (tagGroup) => {
            let tag = tagGroup[0].tag
            let totalCodes = _.reduce(tagGroup, (sum, g) => sum + g.totalCodes, 0)
            let availableCodes = _.reduce(tagGroup, (sum, g) => sum + g.availableCodes, 0)
            return {
              tag: tag,
              totalCodes: totalCodes,
              availableCodes: availableCodes,
            }
          })

          return newTagInfo
        }
      }
  }
</script>

<style scoped lang="scss">
  .tag-list {
  }
</style>
