<template>
  <v-container>
    <v-alert v-model="hasError">
      <v-layout row align-center>
        {{ error }}
        <v-spacer />
        <v-btn icon @click="error = ''"><v-icon small>close</v-icon></v-btn>
      </v-layout>
    </v-alert>
    <genericDetails
      :id="roleID"
      itemName="Domain Role"
      :redirectBack="`/developer/domain/${domainID}`"
      :isNew="newRole"
      :createNew="createRole"
      :deleteOld="deleteRole"
    >
      <template slot="form">
        <v-layout row>
          <v-text-field
            label="Role ID"
            v-model="roleID"
            disabled
            placeholder="[generated by the server]"
          />
          <v-spacer />
          <v-text-field label="Domain" v-model="domainID" disabled />
        </v-layout>
        <v-text-field label="Tag" v-model="tag" :disabled="!newRole" />
        <v-textarea
          label="Description"
          v-model="description"
          :disabled="!newRole"
          rows="1"
        />
      </template>
    </genericDetails>
    <h2>Permissions</h2>
    <v-data-table :headers="headers" :items="permissions">
      <template slot="items" slot-scope="props">
        <td>
          {{
            props.item.resource +
            (props.item.resourceID ? '/' + props.item.resourceID : '')
          }}
        </td>
        <td>
          <v-chip
            :key="item"
            v-for="item in accessIntToArray(props.item.access)"
          >
            {{ item }}
          </v-chip>
        </td>

        <td>
          <v-btn icon @click="removePerm(props.item)">
            <v-icon>delete</v-icon>
          </v-btn>
        </td>
      </template>
      <template slot="footer" v-if="!newRole">
        <td>
          <v-text-field label="New Resource String" v-model="newPermResource" />
        </td>
        <td>
          <v-select
            label="Access"
            v-model="newPermAccess"
            :items="accessTypes"
            multiple
            chips
          />
        </td>
        <td>
          <v-btn @click="addPerm(newPermResource, newPermAccess)">Add</v-btn>
        </td>
      </template>
    </v-data-table>
    <confirm-dialogue ref="confirm" />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import genericDetails from './GenericDetails.vue';

  export default {
    name: 'RoleDetails',
    props: ['domainID', 'roleID'],
    components: { genericDetails },
    data() {
      return {
        tag: '',
        category: '',
        description: '',
        permissions: [],
        headers: [
          { text: 'Resource', value: 'resource', type: String },
          { text: 'Access', value: 'access', width: '40%', type: Number },
          { sortable: false, width: '1%' },
        ],
        accessTypes: [
          { text: 'Create', value: 1 },
          { text: 'Read', value: 2 },
          { text: 'Update', value: 4 },
          { text: 'Delete', value: 8 },
        ],
        newPermResource: '',
        newPermAccess: [],
        newRole: false,
        error: null,
        hasError: false,
      };
    },
    mounted: function () {
      if (this.roleID == undefined || !this.roleID) {
        this.newRole = true;
      } else {
        this.newRole = false;
        this.getDetails();
      }
    },
    watch: {
      error: function (val) {
        if (val) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      },
    },
    methods: {
      accessIntToArray(access) {
        access = Number(access);
        let result = [];
        for (let i = this.accessTypes.length - 1; i >= 0; i--) {
          let type = this.accessTypes[i];
          if (access >= Number(type.value)) {
            access = access - Number(type.value);
            result.push(type.text);
          }
        }
        return result;
      },
      getDetails() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/accounts/domain/${this.domainID}/roles`;
          axios
            .get(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              for (let i = 0; i < response.data.roles.length; i++) {
                let role = response.data.roles[i];
                if (role.roleID == this.roleID) {
                  this.tag = role.tag;
                  this.description = role.description;
                  this.permissions = role.permissions || [];
                  break;
                }
              }
            })
            .catch((error) => {
              this.description = '';
              this.tag = '';
              this.permissions = [];
            });
        });
      },
      createRole() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/accounts/domain/${this.domainID}/role`;
          let newRole = {
            tag: this.tag,
            category: this.category,
            description: this.description,
            permissions: this.permissions,
          };
          axios
            .put(req, newRole, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.newRole = false;
              resolve(
                `/developer/domain/${response.data.domainID}/role/${response.data.roleID}`
              );
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      deleteRole() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/accounts/domainrole/${this.roleID}`;
          axios
            .delete(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              resolve(`/developer/domain/${this.domainID}`);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      updatePerm(perms) {
        //permissions are updated as a whole array instead of adding or deleting single perms
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/accounts/domainrole/${this.roleID}`;
          let stringPerms = perms.map((item) => {
            if (item.resourceID) {
              return `d:${item.resource}/${item.resourceID}:${item.access}`;
            } else {
              return `d:${item.resource}:${item.access}`;
            }
          });
          axios
            .post(
              req,
              { permissions: stringPerms },
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.permissions = response.data.permissions;
              this.error = '';
              this.newPermResource = '';
              this.newPermAccess = [];
            })
            .catch((error) => {
              this.error = error;
            });
        });
      },
      addPerm() {
        let copy = (this.newPermAccess || []).slice();
        let totalPerms = copy.reduce((a, b) => a + b, 0);
        if (!this.newRole) {
          let copyAll = (this.permissions || []).slice();
          copyAll.push({ resource: this.newPermResource, access: totalPerms });
          this.updatePerm(copyAll);
        } else {
          this.permissions.push({
            resource: this.newPermResource,
            access: totalPerms,
          });
          this.newPermResource = '';
          this.newPermAccess = [];
        }
      },
      removePerm(item) {
        if (!this.newRole) {
          this.$refs.confirm
            .open(
              `Delete ${item.resource}`,
              `Are you sure you want to delete the perm for ${item.resource}?`
            )
            .then((confirm) => {
              if (confirm) {
                let copy = [];
                for (let i = 0; i < this.permissions.length; i++) {
                  if (i != this.permissions.indexOf(item)) {
                    copy.push(this.permissions[i]);
                  }
                }
                this.updatePerm(copy);
              }
            });
        } else {
          this.permissions.splice(this.permissions.indexOf(item));
        }
      },
    },
  };
</script>
