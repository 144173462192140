<style scoped>
  .fade {
    position: relative;
    max-height: 10em;
    overflow: hidden;
  }
  tr {
    transition-duration: 0s !important;
  }
  tr:hover .fade:after {
    background: linear-gradient(
      to bottom,
      rgba(66, 66, 66, 0),
      rgb(97, 97, 97) 40%
    );
  }
  .formatted-text {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
  }
  .fade:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    top: 8.5rem;
    width: 100%;
    height: 1.5rem;
    background: linear-gradient(
      to bottom,
      rgba(66, 66, 66, 0),
      rgba(66, 66, 66, 1) 40%
    );
  }
</style>

<template>
  <v-layout row justify-left align-center fill-height class="wotcContainer">
    <v-flex xs12 sm12 md12 lg12 box pa12>
      <h2>Bans</h2>
      <v-data-table
        style="table-layout: fixed"
        :items="filteredBans"
        hide-actions
      >
        <template slot="headers">
          <tr align="left">
            <th>Ban ID</th>
            <th>PersonaID</th>
            <th>GameID</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>
              <v-layout align-center>
                Reason
                <v-flex>
                  <v-switch
                    style="float: right"
                    class="mt-4"
                    v-model="showInactive"
                    label="Show Inactive"
                  />
                </v-flex>
              </v-layout>
            </th>
            <th align="right">
              <v-btn
                @click="fetchBans()"
                color="grey darken-3"
                title="Reload Bans"
              >
                <v-icon>cached</v-icon>
              </v-btn>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr style="vertical-align: top">
            <td width="15%" class="pt-2 pr-1" style="word-break: break-word">
              {{ props.item.banID }}
            </td>
            <td width="15%" class="pt-2 pr-1" style="word-break: break-word">
              {{ props.item.personaID }}
            </td>
            <td width="10%" class="pt-2">{{ props.item.gameID }}</td>
            <td width="10%" class="pt-2">
              {{ formatDate(props.item.startDate) }}
            </td>
            <td width="10%" class="pt-2">
              {{ formatDate(props.item.endDate) }}
            </td>
            <td width="30%" class="pt-2 pr-0 pb-2" style="cursor: pointer">
              <v-dialog width="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="fade formatted-text">
                    {{ formatComment(props.item.comment) }}
                  </div>
                </template>
                <v-card>
                  <v-card-title>Comment</v-card-title>
                  <v-card-text class="formatted-text">
                    <span>{{ formatComment(props.item.comment) }} </span>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </td>
            <td width="10%" class="pt-1 pb-2" align="right">
              <v-btn @click="confirmRemove(props.item)">Remove</v-btn>
            </td>
          </tr>
        </template>
        <template slot="footer">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="pt-1 pb-2" align="right">
            <v-dialog v-model="newBanModal" width="500">
              <template v-slot:activator="{ on }">
                <v-btn align="right" v-on="on">Add</v-btn>
              </template>
              <createban
                v-on:close-ban-dialog="newBanModal = false"
                v-bind:hasChanges.sync="hasChanges"
              />
            </v-dialog>
          </td>
        </template>
      </v-data-table>
      <v-alert v-model="hasError">{{ getError }}</v-alert>
    </v-flex>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </v-layout>
</template>

<script>
  import _ from 'lodash';
  import moment from 'moment';
  import CreateBan from './CreateBan.vue';

  export default {
    name: 'bans',
    data() {
      return {
        showInactive: true,
        newBanModal: false,
        hasChanges: false,
        error: '',
      };
    },
    watch: {
      accountID: function () {
        this.fetchBans();
      },
    },
    created() {
      window.addEventListener('beforeunload', (event) => {
        if (this.hasChanges) {
          event.preventDefault();
          event.returnValue =
            'Leaving this page will lose any unsaved changes. Do you want to continue?';
        }
      });
      this.fetchBans();
    },
    methods: {
      formatDate(isoDateString) {
        if (isoDateString) {
          return moment(isoDateString).format('MMM Do, YYYY');
        }
        return 'N/A';
      },
      formatComment(comment) {
        return comment.replace(/\\n/g, `\n`);
      },
      fetchBans() {
        if (!_.isNil(this.accountID)) {
          this.$store.dispatch('fetchBans');
        } else {
          this.$store.state.bans = [];
        }
      },
      confirmRemove(ban) {
        this.$refs.confirm
          .open(
            'Confirm Remove Ban',
            'This will permanently remove ban ' + ban.banID
          )
          .then((confirm) => {
            this.setError('');
            if (confirm) {
              return this.$store.dispatch({
                type: 'deleteBan',
                ban: ban,
              });
            }
          })
          .catch((err) => {
            this.setError('Unable to remove ban: ' + err);
          });
      },
      setError(err) {
        this.error = '' + err;
      },
    },
    computed: {
      hasError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      accountID() {
        return this.$store.getters.accountID;
      },
      currentBans() {
        return this.$store.getters.bans;
      },
      isExternalPersona() {
        return this.$store.getters.isExternalPersona;
      },
      filteredBans() {
        // if exclusively showing active bans, only include indefinite bans
        // or bans with end date later than today and start date before today
        let today = moment().utc().format();
        if (!this.showInactive) {
          return this.currentBans.filter((ban) => {
            return (
              (!ban.endDate || ban.endDate >= today) && ban.startDate <= today
            );
          });
        }
        return this.currentBans;
      },
    },
    components: { createban: CreateBan },
  };
</script>
