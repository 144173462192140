<template>
  <v-flex v-if="results.length > 0">
    <br />
    <!-- Table Filter Search -->
    <v-layout row v-if="filterList && filterList.length > 0">
      <v-flex md4 class="px-3">
        <v-select
          :options="filterList"
          :items="filterList"
          label="Filter Type"
          v-model="selectedFilter"
          @change="filterResults(filterText)"
          required
        >
        </v-select>
      </v-flex>
      <v-flex md8 class="px-3">
        <v-text-field label="Filter Text" v-model="filterText"></v-text-field>
      </v-flex>
    </v-layout>
    <!-- Results Table -->
    <v-data-table :items="filteredResults" :headers="headers">
      <template v-slot:items="props">
        <tr @click="goToAccount(props.item.accountID)">
          <td>{{ props.item.email }}</td>
          <td>
            <ul v-for="(dRole, index) in props.item.domainRoles" :key="index">
              <li>{{ dRole }}</li>
            </ul>
          </td>
          <td>
            <ul
              v-for="(gRole, index) in props.item.persona.gameRoles"
              :key="index"
            >
              <li>{{ gRole }}</li>
            </ul>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
  export default {
    name: 'searchDomainsTable',
    props: {
      results: {
        type: Array,
        default: [],
      },
      searchedDomain: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        headers: [
          { text: 'Email', value: 'email', sortable: true },
          { text: 'Domain Role(s)', value: 'domainRoles', sortable: true },
          { text: 'Game Role(s)', value: 'gameRoles', sortable: true },
        ],
        filterList: ['Email', 'Domain Roles', 'Game Roles'],
        filterText: '',
        filteredResults: this.results,
        loading: false,
        selectedFilter: 'Email',
        sortItems: [
          { display: 'newest first', value: 'desc' },
          { display: 'oldest first', value: 'asc' },
        ],
      };
    },

    methods: {
      goToAccount(accountID) {
        let routeData = this.$router.resolve(
          '/edit/' + this.searchedDomain + '/' + accountID
        );
        window.open(routeData.href, '_blank');
      },
      filterResults(val) {
        if (val == '') {
          return;
        }
        let doesContain = false;
        switch (this.selectedFilter) {
          case 'Email':
            this.filteredResults = this.results.filter((res) =>
              res?.email.includes(val)
            );
            break;
          case 'Domain Roles':
            this.filteredResults = this.results.filter((res) => {
              if (res.domainRoles) {
                res?.domainRoles.map((role) => {
                  if (role.includes(val)) {
                    doesContain = true;
                    return true;
                  }
                });
              }

              if (doesContain) {
                doesContain = false;
                return true;
              }
              doesContain = false;
            });
            break;
          case 'Game Roles':
            this.filteredResults = this.results.filter((res) => {
              if (res.persona && res.persona.gameRoles) {
                res?.persona?.gameRoles.map((role) => {
                  if (role.includes(val)) {
                    doesContain = true;
                    return true;
                  }
                });
              }
              if (doesContain) {
                doesContain = false;
                return true;
              }
              doesContain = false;
            });
            break;
          default:
            break;
        }
      },
    },

    watch: {
      filterText(val) {
        if (this.filterText == '') {
          this.filteredResults = this.results;
          return;
        }
        this.filterResults(val);
      },
      results() {
        this.filteredResults = this.results;
      },
    },
  };
</script>
