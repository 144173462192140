var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Personas")]),_c('v-layout',{attrs:{"row":"","justify-left":"","align-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","box":"","pa12":""}},[_c('v-data-table',{attrs:{"item-key":"personaID","items":_vm.personas,"hide-actions":"","expand":"","headers-length":2},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_c('th',{staticStyle:{"text-align":"left"}},[_vm._v("Game ID")]),_c('th',{staticStyle:{"text-align":"left"}},[_vm._v("Created")]),_c('th',[_c('v-layout',{attrs:{"row":"","align-center":""}},[_c('v-flex',{staticStyle:{"text-align":"right"},attrs:{"xs2":"","sm2":"","md2":"","lg2":"","box":""}},[_vm._v(" Persona ID ")]),_c('v-spacer'),_c('v-flex',{attrs:{"xs3":"","sm3":"","md3":"","lg3":"","box":""}},[_c('v-btn',{attrs:{"color":"grey darken-3","title":"Reload Personas"},on:{"click":function($event){return _vm.loadPersonas()}}},[_c('v-icon',[_vm._v("cached")])],1)],1)],1)],1)])]}},{key:"items",fn:function(props){return [_c('tr',{on:{"click":function($event){return _vm.setSelectedPersona(props)}}},[_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(props.item.gameID))]),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(props.item.createdAt))]),_c('td',[_c('v-layout',{attrs:{"row":"","justify-left":"","align-center":"","fill-height":""}},[_c('v-flex',[_vm._v(_vm._s(props.item.personaID))]),(!props.expanded)?_c('v-icon',[_vm._v("arrow_drop_down")]):_c('v-icon',[_vm._v("arrow_drop_up")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.conflicts[props.item.personaID])?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange darken-3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-octagon ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" This persona is in a conflicted state. Expand the drop down for details. ")])])],1)],1)])]}},{key:"expand",fn:function(props){return [_c('v-container',{attrs:{"pl-5":"","xs6":"","sm6":"","md6":"","lg6":"","box":"","pa6":""}},[_c('v-layout',{staticClass:"pb-4",attrs:{"xs6":"","sm6":"","md6":"","lg6":"","box":"","pa6":""}},[(_vm.conflicts[props.item.personaID])?_c('div',[_c('h3',[_vm._v("THIS PERSONA IS IN CONFLICT")]),_c('p',[_vm._v(" Persona with ID "+_vm._s(_vm.conflictInfo.selectedPersona.personaID)+" is in conflict with a "+_vm._s(_vm.conflictInfo.conflictingPersona.externalPlatform)+" persona with ID "),_c('a',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.viewExternalPersona(
                        _vm.conflictInfo.conflictingPersona.personaID
                      )}}},[_vm._v(_vm._s(_vm.conflictInfo.conflictingPersona.personaID)+" ")]),_vm._v(". Click the button below to choose which persona to keep. ")]),[_c('v-btn',{on:{"click":function($event){$event.stopPropagation();_vm.showDialog = true}}},[_vm._v(" Resolve Conflict ")]),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Resolve Conflicting Personas ")]),_c('v-card-text',[_vm._v(" Choose which persona to keep. The chosen persona will be preserved and attached to the account. The other will be deleted. ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',_vm._l((_vm.conflicts[props.item.personaID]),function(info){return _c('v-flex',{key:info.personaID,attrs:{"xs6":"","sm6":"","md6":"","lg6":"","box":""}},[_c('v-card',{staticClass:"pa-3 ma-1 fill-height conflict-card"},[_c('h4',[_vm._v("Persona ID")]),_c('p',[_vm._v(_vm._s(info.personaID))]),_c('h4',[_vm._v("Created At")]),_c('p',[_vm._v(_vm._s(info.createdAt))]),_c('h4',[_vm._v("Number of Entitlements")]),_c('p',[_vm._v(_vm._s(info.entitlementCount))]),(info.externalPlatform)?_c('h4',[_vm._v(" External Platform ")]):_vm._e(),(info.externalPlatform)?_c('p',[_vm._v(" "+_vm._s(info.externalPlatform)+" ")]):_vm._e(),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.confirmResolveConflict(
                                      info.personaID,
                                      info.externalPlatform,
                                      props.item.gameID
                                    )}}},[_vm._v(" Select ")])],1)],1)],1)}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancel")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure? ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.getConfirmationMessage))]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resolveConflict}},[_vm._v("Confirm")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancelResolveConflict}},[_vm._v(" Cancel ")])],1)],1)],1)]],2):_vm._e()])],1),_c('hr',{staticStyle:{"border-bottom":"1px rgb(187, 187, 187)"}})]}}])},[_c('template',{slot:"footer"},[_c('tr',[_c('td',[_c('v-select',{attrs:{"items":_vm.gameIDs,"label":_vm.disabled ? 'User has a persona in all games' : 'Game ID',"error":_vm.error,"disabled":_vm.disabled},model:{value:(_vm.toAddGame),callback:function ($$v) {_vm.toAddGame=$$v},expression:"toAddGame"}})],1),_c('td',[_c('v-btn',{on:{"click":_vm.confirmCreate}},[_vm._v("Add")])],1)])])],2),_c('v-alert',{model:{value:(_vm.hasError),callback:function ($$v) {_vm.hasError=$$v},expression:"hasError"}},[_vm._v(_vm._s(_vm.getError))])],1)],1),_c('confirm-dialogue',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }