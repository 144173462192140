var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[(_vm.isSuccess)?_c('v-alert',{attrs:{"value":_vm.isSuccess,"type":"success","dismissible":""},on:{"input":function($event){_vm.success = ''}}},[_vm._v(" "+_vm._s(_vm.success)+" ")]):_vm._e(),(_vm.isError)?_c('v-alert',{attrs:{"value":_vm.isError,"type":"error","dismissible":""},on:{"input":function($event){_vm.error = ''}}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1)],1),_c('v-layout',{attrs:{"row":"","pb-5":""}},[_c('v-flex',[_c('h2',[_c('b',[_vm._v(_vm._s(_vm.roleAction)+" Roles In Bulk via .CSV")])])])],1),_c('v-layout',{attrs:{"row":"","pb-4":""}},[_c('DomainsSelector',{on:{"domainsSelected":function (newDomain) {
          this$1.domainsSelected = newDomain;
        }}}),_c('v-flex',{attrs:{"pl-5":""}},[_c('v-switch',{attrs:{"label":(_vm.roleAction + " Roles")},model:{value:(_vm.isAdd),callback:function ($$v) {_vm.isAdd=$$v},expression:"isAdd"}})],1)],1),_c('v-layout',{attrs:{"row":"","pb-5":""}},[_c('DomainRolesSelector',{attrs:{"domain":_vm.domainsSelected},on:{"newRoles":function (newRoles) {
          this$1.domainRolesSelected = newRoles;
        }}})],1),_c('v-layout',{attrs:{"row":"","pb-5":""}},[_c('GameRolesSelector',{attrs:{"domains-selected":_vm.domainsSelected},on:{"gameRoleUpdate":function (newRoles) {
          this$1.gamesSelected = newRoles;
        }}})],1),_c('v-layout',{attrs:{"row":"","pb-3":""}},[_c('CsvSelector',{on:{"filePicked":function (newFile) {
          this$1.accountsFile = newFile;
        }}},[_c('div',[_vm._v(" \"persona_id\",\"email_address\",\"domain_id\" "),_c('br'),_vm._v(" \"PersonaID1\",\"email1@wizards.com\",\"wizards\" "),_c('br'),_vm._v(" \"PersonaID2\",\"email2@wizards.com\",\"wizards\" "),_c('br')])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-btn',{attrs:{"disabled":!_vm.isPageValid},on:{"click":function($event){$event.stopPropagation();return _vm.updateRoles($event)}}},[_vm._v("Update")]),_c('Poller',{attrs:{"showing":_vm.dialog,"initialBulkChangeDetails":_vm.bulkJobDetails,"ready":_vm.processing,"isCreate":false},on:{"create":_vm.updateRoles,"hide":function($event){_vm.dialog = false},"done":function($event){_vm.processing = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }