const _ = require('lodash');

const permissionFlags = {
  create: 1,
  read: 2,
  update: 4,
  delete: 8,
};

const permissionRegex = /([dg]):((?:(\w+|\*)(?:\.(\w+))?\/)?([^\/:]+)(?:\/([^:]*))?)(?::(\d+))?/;

//permissionGroupNames are the names of the capture groups IN ORDER of the permissionRegex. It is important that this list stays as is unless the permissionRegex changes
const permissionGroupNames = [
  'fullString',
  'type',
  'fullResource',
  'domainID',
  'gameID',
  'resource',
  'resourceID',
  'access',
];

//parsePermission takes a string and uses the permissionRegex to get the different fields of a permission.
// Returns the permission fields if the string matches the regex, otherwise returns null.
const parsePermission = (permStr) => {
  let matchResult = permStr.match(permissionRegex);
  //if the permission string doesn't match our regex, abandon ship
  if (_.isNil(matchResult)) {
    return null;
  }
  return _.zipObject(permissionGroupNames, matchResult);
};

const checkPermission = (requiredPerm, actualPerm) => {
  //if the requested domain is empty or *, then any domain will pass
  // additionally, if the permitted domain is * then any domain will pass
  if (
    !_.isEmpty(requiredPerm.domainID) &&
    requiredPerm.domainID !== '*' &&
    actualPerm.domainID !== '*'
  ) {
    //if we have a non-wildcard value in either requiredPerm.domainID or the permitted domain, they must match
    if (requiredPerm.domainID !== actualPerm.domainID) {
      return false; //domains did not match
    }
  }

  //if the requested game is empty or *, then any game will pass
  // additionally, if the permitted game is empty or * then any game will pass
  if (
    !_.isEmpty(requiredPerm.gameID) &&
    requiredPerm.gameID !== '*' &&
    !_.isEmpty(actualPerm.gameID) &&
    actualPerm.gameID !== '*'
  ) {
    //if we have a non-wildcard value in either requiredPerm.gameID or the permitted game, they must match
    if (requiredPerm.gameID !== actualPerm.gameID) {
      return false; //games did not match
    }
  }

  //if the requested resource is empty or *, then any resource will pass
  // additionally, if the permitted resource is * then any resource will pass
  if (
    !_.isEmpty(requiredPerm.resource) &&
    requiredPerm.resource !== '*' &&
    actualPerm.resource !== '*'
  ) {
    //if we have a non-wildcard value in either requiredPerm.resource or the permitted game, they must match
    if (requiredPerm.resource !== actualPerm.resource) {
      return false; //resource did not match
    }
  }

  //if the requested resourceID is empty or *, then any resourceID will pass
  // additionally, if the permitted resourceID is empty or * then any resourceID will pass
  if (
    !_.isEmpty(requiredPerm.resourceID) &&
    requiredPerm.resourceID !== '*' &&
    !_.isEmpty(actualPerm.resourceID) &&
    actualPerm.resourceID !== '*'
  ) {
    //if we have a non-wildcard value in either requiredPerm.resourceID or the permitted game, they must match
    if (requiredPerm.resourceID !== actualPerm.resourceID) {
      return false; //resourceID did not match
    }
  }

  //at this point, we've check that we're already on a matching domain, game, and resource
  //make sure the access flags are ints and not strings
  let permittedAccess = actualPerm.access;
  if (_.isString(permittedAccess)) {
    permittedAccess = parseInt(permittedAccess);
  }

  let requiredAccess = requiredPerm.access;
  if (_.isString(requiredAccess)) {
    requiredAccess = parseInt(requiredAccess);
  }

  //check that they have the proper access
  //return the result of the bitwise check for access
  return (permittedAccess & requiredAccess) === requiredAccess;
};

export default {
  flags: permissionFlags,
  parse: parsePermission,
  check: checkPermission,
  permissionRegex: permissionRegex,
};
