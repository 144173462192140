<template lang="html">
  <v-container px-0 pt-0>
    <h2 v-if="enableSearch">
      <span>Redemptions</span>
      <v-text-field
        style="
          padding-top: 0px;
          margin-top: 0px;
          display: inline-block;
          float: right;
          margin-bottom: 5px;
          top-bottom: 0px;
        "
        v-model="search"
        append-icon="search"
        label="Search Redemptions"
        single-line
        hide-details
      ></v-text-field>
    </h2>
    <v-data-table
      :headers="headers"
      :items="redemptions"
      :loading="loading"
      :search="search"
      :pagination.sync="pagination"
      hide-actions
    >
      <template v-slot:no-data>
        <v-alert icon="info" color="info" :value="true">
          No Redemptions Found
        </v-alert>
      </template>
      <template slot="items" slot-scope="props">
        <tr
          class="text-xs-center"
          @click.stop="clickRow(props.item)"
          :key="props.item.id"
        >
          <td v-if="hasCodeID(props.item)">{{ props.item.codeID }}</td>
          <td v-if="hasOldCodeID(props.item)">{{ props.item.oldCodeID }}</td>
          <td>{{ props.item.domainID }}</td>
          <td v-if="accountView">{{ props.item.offerName }}</td>
          <td v-if="accountView" class="truncate">
            {{ props.item.offerDescription }}
          </td>
          <td v-if="!accountView">{{ props.item.accountID }}</td>
          <td>{{ props.item.created | moment('LLL z') }}</td>
        </tr>
      </template>
      <template slot="footer">
        <tr class="paginator justify-center text-xs-center pt-2">
          <td colspan="5" align="center">
            <div v-if="!loadResultsByPage">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                :total-visible="7"
              ></v-pagination>
            </div>
            <div v-else="loadResultsByPage">
              <v-btn @click="paginate(-1)" :disabled="page == 1">
                <v-icon>$vuetify.icons.prev</v-icon>
              </v-btn>
              <v-btn @click="paginate(1)" :disabled="!morePages">
                <v-icon>$vuetify.icons.next</v-icon>
              </v-btn>
              <span>{{ this.displayRange }}</span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showOfferDetails" width="500">
      <v-card>
        <v-card-title>Details for offer {{ offerDetails.id }}</v-card-title>
        <v-card-text>
          <table>
            <tr>
              <td>name:</td>
              <td>{{ offerDetails.name }}</td>
            </tr>
            <tr>
              <td>description:</td>
              <td>{{ offerDetails.description }}</td>
            </tr>
            <tr>
              <td>start date:</td>
              <td>{{ offerDetails.startDate | moment('LLL z') }}</td>
            </tr>
            <tr>
              <td>end date:</td>
              <td>{{ offerDetails.endDate | moment('LLL z') }}</td>
            </tr>
            <tr>
              <td>grant (game):</td>
              <td>
                <tr v-for="grant in offerDetails.grants">
                  <td>{{ `${grant.value} (${grant.gameID})` }}</td>
                </tr>
              </td>
            </tr>
            <tr>
              <td>code max uses:</td>
              <td>
                {{
                  offerDetails.codeMaxUses == -1
                    ? 'unlimited'
                    : offerDetails.codeMaxUses
                }}
              </td>
            </tr>
            <tr>
              <td>account max uses:</td>
              <td>
                {{
                  offerDetails.accountMaxUses == -1
                    ? 'unlimited'
                    : offerDetails.accountMaxUses
                }}
              </td>
            </tr>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeDetails()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="js">
    import _ from 'lodash'
    import axios from 'axios'

    export default  {
      name: 'redemption-list',
      props: ['redemptions', 'count', 'accountView', 'enableSearch', 'loadResultsByPage'],
      mounted() {
        this.updateDisplayRange()
      },
      data() {
        return {
          search: '',
          pagination: {
              rowsPerPage: 10,
          },
          page: 1,
          displayRange: "",
          headersWithAccount: [
            { text: 'Code', align: 'center', value: 'codeID', sortable: false},
            { text: 'Domain', align: 'center', value: 'domainID', sortable: false},
            { text: 'Account', align: 'center', value: 'accountID', sortable: false},
            { text: 'Redeemed At', align: 'center', value: 'created', sortable: false},
          ],
          headersNoAccount: [
            { text: 'Code', align: 'center', value: 'codeID', filterable: false},
            { text: 'Domain', align: 'center', value: 'domainID', filterable: false},
            { text: 'Offer', align: 'center', value: 'offerName', filterable: false},
            { text: 'Description', align: 'center', value: 'offerDescription', filterable: false},
            { text: 'Redeemed At', align: 'center', value: 'created', filterable: false},
          ],
          offerDetails: {},
          showOfferDetails: false,
        }
      },
      watch: {
        redemptions() {
          this.updateDisplayRange()
          this.setCountItems()
          this.closeDetails()
        },
      },
      methods: {
        updateDisplayRange() {
          let min = 1 + ((this.page - 1) * this.pagination.rowsPerPage)
          let max = min + this.pagination.rowsPerPage - 1
          if (max > this.count) max = this.count;
          this.displayRange = `${min}-${max} of ${this.count}`
        },
        hasCodeID(redemption) {
          return !_.isNil(redemption.codeID)
        },
        hasOldCodeID(redemption) {
          return !_.isNil(redemption.oldCodeID)
        },
        navigateAccount(accountID, domainID) {
          let routeData = this.$router.resolve(`/edit/${domainID}/${accountID}`);
          window.open(routeData.href, '_blank');
        },
        paginate(mod) {
            let newPage = this.page + mod;
            if (newPage <= 0) {
              newPage = 1;
            }
            this.page = newPage;
            console.log(this.page);
            this.$emit('paginate', this.page);
        },
        setCountItems() {
          this.pagination.totalItems = this.count
        },
        fetchOfferDetails(offerID) {
          this.$store.dispatch('refreshIfNeeded')
          .then(() => {
            axios.get(`${this.$store.getters.serviceURL}/admin/redemption/offers/${offerID}`, this.$store.getters.bearerAuthHeaders)
            .then(resp => {
              this.offerDetails = resp.data;
            });
          });
        },
        showDetails(offerID) {
          if (this.offerDetails.offerID != offerID) {
            this.fetchOfferDetails(offerID);
          }
          this.showOfferDetails = true;
        },
        closeDetails() {
          this.showOfferDetails = false;
        },
        clickRow(item) {
          if (this.accountView) {
            this.showDetails(item.offerID);
          } else {
            this.navigateAccount(item.accountID, item.domainID);
          }
        }
      },
      computed: {
        headers() {
          if (!this.accountView) {
            return this.headersWithAccount;
          } else {
            return this.headersNoAccount;
          }
        },
        loading() {
          return _.isNil(this.redemptions) || this.redemptions.length < 1
        },
        morePages() {
          return ((this.page * this.pagination.rowsPerPage) < this.count);
        },
        pages () {
          if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
            return 0
          }

          return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
      },
  }
</script>

<style scoped>
  .redemption-list {
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tr {
    cursor: pointer;
  }
</style>
