<template>
  <v-container flex>
    <!-- Generic Loading Screen -->
    <GenericLoadingScreen :isLoading="loading"></GenericLoadingScreen>

    <!-- Error Alert -->
    <v-alert v-model="hasError">
      <v-layout row align-center>
        {{ error }}
        <v-spacer />
        <v-btn icon @click="error = ''"><v-icon small>close</v-icon></v-btn>
      </v-layout>
    </v-alert>

    <!-- Table Search Form -->
    <v-layout row>
      <v-flex md4 class="px-3">
        <v-select
          :items="domainsList"
          @change="onDomainChange($event)"
          label="Domain"
          v-model="searchDomain"
          required
        >
        </v-select>
      </v-flex>

      <v-flex md4 class="px-3">
        <v-select
          :options="gamesList"
          :items="gamesList"
          label="Game"
          v-model="searchGame"
          required
        >
        </v-select>
      </v-flex>

      <v-flex>
        <v-container d-flex>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="getSearchResults()"
                :disabled="!validSearch || loading"
              >
                Search
              </v-btn>
            </template>
            <span width="10">
              Warning: You have made changes to your search fields. Click SEARCH
              to apply them.
            </span>
          </v-tooltip>
        </v-container>
      </v-flex>
    </v-layout>

    <!-- search warning text -->
    <v-layout row v-if="results.length <= 0">
      <p class="text--secondary">
        This search will only return the first 1000 records.
      </p>
    </v-layout>
    <v-layout>
      <RoleSearchTable
        :results="results"
        :searchedDomain="searchDomain"
      ></RoleSearchTable>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import RoleSearchTable from './RoleSearchTable.vue';
  import GenericLoadingScreen from '../utility/GenericLoadingScreen.vue';

  export default {
    name: 'searchDomainsForm',

    components: { RoleSearchTable, GenericLoadingScreen },

    data() {
      return {
        domainsList: [],
        error: '',
        gamesList: [],
        hasError: false,
        loading: false,
        results: [],
        searchDomain: '',
        searchGame: '',
        validSearch: false,
      };
    },

    mounted() {
      this.getDomainsList();
    },

    methods: {
      getDomainsList() {
        axios
          .get(
            this.$store.getters.serviceURL + '/admin/accounts/domain',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            if (!this.isProduction()) {
              console.log('GET DOMAINS LIST RESPONSE:');
              console.log(response.data);
            }
            this.domainsList = response.data.domains.map((domain) => {
              return domain.domainID;
            });

            this.setError('');
          })
          .catch((err) => {
            this.setError('Failed to Pull Domain List: ' + err);
          });
      },
      getGamesList(domain) {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/domain/' +
              domain +
              '/game',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            if (!this.isProduction()) {
              console.log('GET GAMES LIST RESPONSE:');
              console.log(response.data);
            }
            if ('games' in response.data) {
              this.gamesList = response.data.games.map((game) => {
                return game.gameID;
              });
            } else {
              this.gamesList = [];
            }

            this.setError('');
          })
          .catch((err) => {
            this.setError('Failed to Pull Games List: ' + err);
          });
      },
      getSearchResults() {
        this.loading = true;
        const URL =
          this.$store.getters.serviceURL +
          '/admin/accounts/domain/' +
          this.searchDomain +
          '/game/' +
          this.searchGame +
          '/all';

        axios
          .get(URL, this.$store.getters.bearerAuthHeaders)
          .then((response) => {
            if (!this.isProduction()) {
              console.log('GET SEARCH RESPONSE:');
              console.log(response.data.accounts);
            }
            if (response.data) {
              this.results = response.data.accounts;
            } else {
              this.results = [];
            }
            this.loading = false;
            this.setError('');
          })
          .catch((err) => {
            this.loading = false;
            this.setError('Failed to do that search: ' + err);
          });
      },
      onDomainChange(domain) {
        this.results = [];
        this.getGamesList(domain);
      },
      isProduction() {
        return this.$store.getters.isProduction;
      },
      setError(error) {
        this.error = error;
        this.loading = false;
      },
    },
    watch: {
      domainSelected: function (newDomain) {
        this.error = '';

        if (newDomain) {
          this.getGamesList(newDomain);
        }
      },
      error: function () {
        if (this.error) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      },
      searchGame: function () {
        if (this.searchGame != '' && this.searchDomain != '') {
          this.validSearch = true;
        }
      },
    },
  };
</script>
