<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex xs8 sm8 md8 lg8 box pa8>
        <v-layout row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-flex xs8 sm8 md8 lg8 box pa6 mb-0 v-on="on">
                <v-checkbox
                  label="Opt Out of Targeted Data Analytics"
                  v-model="newTargetedAnalyticsOptOut"
                />
              </v-flex>
            </template>
            <span>Checked = Opted out of targeted data analytics</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-flex xs6 sm6 md6 lg6 box pa6 mb-0 v-on="on">
                <v-checkbox label="Email Opt-In" v-model="newEmailOptIn" />
              </v-flex>
            </template>
            <span>Checked = Opted into marketing emails</span>
          </v-tooltip>
        </v-layout>
        <v-layout row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-flex xs8 sm8 md8 lg8 box pa6 mb-0 v-on="on">
                <v-checkbox
                  label="Matched Ads Opt-In"
                  v-model="newDataOptIn"
                  :disabled="newCCPAOptIn || newTargetedAnalyticsOptOut"
                />
              </v-flex>
            </template>
            <span>Checked = Opted into Matched Ads</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-flex xs6 sm6 md6 lg6 box pa6 mb-0 v-on="on">
                <v-checkbox label="Do Not Sell" v-model="newCCPAOptIn" />
              </v-flex>
            </template>
            <span>Checked = Opted into CCPA protection</span>
          </v-tooltip>
        </v-layout>
      </v-flex>
      <v-flex xs2 sm2 md2 lg2>
        <v-btn :disabled="disableUpdate" @click="confirm">Update</v-btn>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="success">check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click="snackbar.visible = false">Close</v-btn>
    </v-snackbar>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';

  export default {
    name: 'EmailOptInForm',
    data() {
      return {
        newTargetedAnalyticsOptOut: false,
        newEmailOptIn: false,
        newDataOptIn: false,
        newCCPAOptIn: false,
        error: false,
        success: false,
        loading: false,
        snackbar: {
          text: 'Undefined',
          color: 'error',
          visible: false,
        },
      };
    },
    created() {
      this.newTargetedAnalyticsOptOut = this.currentTargetedAnalyticsOptOut;
      this.newEmailOptIn = this.currentEmailOptIn;
      this.newDataOptIn = this.currentDataOptIn;
      this.newCCPAOptIn = this.currentCCPA;
    },
    watch: {
      currentTargetedAnalyticsOptOut: function (newValue) {
        this.newTargetedAnalyticsOptOut = newValue;
      },
      currentEmailOptIn: function (newValue) {
        this.newEmailOptIn = newValue;
      },
      currentDataOptIn: function (newValue) {
        this.newDataOptIn = newValue;
      },
      currentCCPA: function (newValue) {
        this.newCCPAOptIn = newValue;
      },
      newTargetedAnalyticsOptOut: function (newValue) {
        if (newValue === true) {
          this.newDataOptIn = false;
        }
      },
      newCCPAOptIn: function (newValue) {
        if (newValue === true) {
          this.newDataOptIn = false;
        }
      },
    },
    methods: {
      confirm() {
        this.$refs.confirm
          .open(
            'Update Opt-Ins',
            "Are you sure you want to update this account's opt-ins?"
          )
          .then((confirm) => {
            if (confirm) {
              this.submit();
            } else {
              this.newTargetedAnalyticsOptOut = this.currentTargetedAnalyticsOptOut;
              this.newEmailOptIn = this.currentEmailOptIn;
              this.newDataOptIn = this.currentDataOptIn;
              this.newCCPAOptIn = this.currentCCPA;
            }
          });
      },
      submit() {
        this.loading = true;
        axios
          .put(
            this.$store.getters.serviceURL + '/profile/admin/' + this.accountID,
            {
              targetedAnalyticsOptOut: this.newTargetedAnalyticsOptOut,
              emailOptIn: this.newEmailOptIn,
              dataOptIn: this.newDataOptIn,
              ccpaProtectData: this.newCCPAOptIn,
            },
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setOptIns', {
              targetedAnalytics: response.data.targetedAnalyticsOptOut,
              email: response.data.emailOptIn,
              data: response.data.dataOptIn,
              ccpa: response.data.ccpaProtectData,
            });
            this.error = false;
            this.snackbar = {
              text: 'Successfully updated account opt ins',
              color: 'success',
              visible: true,
            };
            this.loading = false;
          })
          .catch((err) => {
            this.error = true;
            this.loading = false;
            this.snackbar = {
              text: 'Failed to update opt ins. Please try again.',
              color: 'error',
              visible: true,
            };
          });
      },
    },
    computed: {
      getError() {
        return this.error;
      },
      accountID() {
        return this.$store.getters.account.accountID;
      },
      currentTargetedAnalyticsOptOut() {
        return this.$store.getters.userProfile.targetedAnalyticsOptOut;
      },
      currentEmailOptIn() {
        return this.$store.getters.userProfile.emailOptIn;
      },
      currentDataOptIn() {
        return this.$store.getters.userProfile.dataOptIn;
      },
      currentCCPA() {
        return this.$store.getters.userProfile.ccpaProtectData;
      },
      disableUpdate() {
        return (
          this.currentTargetedAnalyticsOptOut ===
            this.newTargetedAnalyticsOptOut &&
          this.currentEmailOptIn === this.newEmailOptIn &&
          this.currentDataOptIn === this.newDataOptIn &&
          this.currentCCPA === this.newCCPAOptIn &&
          !this.loading
        );
      },
    },
  };
</script>
