<template lang="html">
  <v-dialog
    v-model="showing"
    scrollable
    fullscreen
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-btn @click="startExport" slot="activator" color="gray">
      Download CSV
    </v-btn>
    <v-container>
      <v-sheet>
        <v-layout row>
          <v-toolbar>
            <v-toolbar-title>Export {{ exportType }}</v-toolbar-title>
            <v-toolbar-title v-if:="hasError" class="error-message">{{
              errorMessage
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon v-on:click="hide">
              <v-icon color="red">close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-layout>
        <v-layout row>
          <v-container>
            <v-layout row justify-center>
              <v-flex xs6>
                <v-card>
                  <v-card-title primary-title>
                    <div>
                      <h2>
                        {{ cardTitle }}
                      </h2>
                    </div>
                  </v-card-title>
                  <v-container>
                    <v-layout row justify-center>
                      <v-progress-circular
                        :value="progress"
                        size="200"
                        :indeterminate="spinIndefinitely"
                      >
                        <span v-if="!spinIndefinitely && !complete">
                          {{ progress }} %
                        </span>
                        <v-btn
                          v-if="complete"
                          :href="downloadURL"
                          target="_blank"
                        >
                          Download CSV
                        </v-btn>
                      </v-progress-circular>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-layout>
      </v-sheet>
    </v-container>
  </v-dialog>
</template>

<script lang="js">
    import _ from 'lodash';
    export default  {
      name: 'export-dialog',
      props: ['exportType', 'exportRequest'],
      data() {
        return {
          showing: false,
          pollInterval: 500,
          pollID: 0,
          jobInfo: {
            complete: false,
            progress: 0,
            jobID:'',
            expiresIn:0 ,
          },
          pollWaiting: false,
          errorMessage: null,
        }
      },
      methods: {
        startExport() {
          this.$store.dispatch('createRedemptionExport', this.exportRequest)
          .then((resp) => {
            this.jobInfo = resp.data;
            this.pollID = setTimeout(this.pollJob, this.pollInterval);
          })
          .catch((err) => {
            // The only current restriction is on export size
            if (err.toString().includes('400')) {
              this.errorMessage = "Export Too Large";
            } else {
              this.errorMessage = err;
            }
          })
        },
        pollJob() {
          if (!this.pollWaiting) {
            this.pollWaiting = true;
            this.$store.dispatch('fetchRedemptionExportJob', this.jobInfo.jobID)
            .then(resp => {
              this.jobInfo = resp.data;
              this.pollWaiting = false;
              this.errorMessage = null;
              if (this.showing && !this.jobInfo.complete) {
                this.pollID = setTimeout(this.pollJob, this.pollInterval);
              }
            })
            .catch(_ => {
              this.errorMessage = "Error Polling Export Status";
            })
          }
        },
        hide() {
          clearTimeout(this.pollID);
          this.showing = false;
          this.jobInfo.complete = false;
          this.jobInfo.progress = 0;
          this.errorMessage = null;
        }
      },
      computed: {
        complete() {
          return this.jobInfo.complete
        },
        downloadURL() {
          if (this.hasURL) {
            return this.jobInfo.downloadURL
          } else {
            return '#'
          }
        },
        cardTitle() {
          if (this.progress < 100) {
            return "Retrieving Codes"
          } else if (!this.hasURL) {
            return "Creating CSV"
          } else {
            return "Ready for Download"
          }
        },
        hasURL() {
          return !_.isNil(this.jobInfo.downloadURL)
        },
        progress() {
          return Math.floor(this.jobInfo.progress * 100)
        },
        spinIndefinitely() {
          return this.progress === 100 && !this.hasURL
        },
        hasError() {
          return this.errorMessage != null;
        }
      }
  }
</script>

<style scoped lang="scss">
  .export-dialog {
  }

  .error-message {
    color: palevioletred;
  }
</style>
