<template>
  <v-container>
    <v-layout row justify-center>
      <OfferList :showButton="true" />
    </v-layout>
    <v-layout row justify-center class="separator">
      <v-divider></v-divider>
    </v-layout>
    <v-layout row justify-center>
      <v-flex xs12 sm12 md12 lg12 box>
        <CodeStatus></CodeStatus>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import OfferList from './OfferList.vue';
  import CodeStatus from './CodeStatus.vue';

  export default {
    name: 'offers',
    props: [],
    components: {
      OfferList,
      CodeStatus,
    },

    mounted() {},
    data() {
      return {};
    },
    methods: {},
    computed: {},
  };
</script>

<style scoped>
  .separator {
    margin-top: 20px;
    min-height: 25px;
  }
</style>
