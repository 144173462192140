<template>
  <div>
    <v-container fluid grid-list-xl>
      <v-alert v-model="this.error">{{ this.error }}</v-alert>
      <v-layout row>
        <v-btn to="/codeRedemption">Back</v-btn>
        <v-spacer />
        <div>
          <v-btn
            color="primary"
            v-if="this.readWrite"
            @click="saveOffer()"
            :loading="saving"
            :disabled="saving"
          >
            Save
          </v-btn>
        </div>
        <div>
          <v-switch
            label="Read/Write"
            v-model="readWrite"
            v-if="permissionAllowed('coderedemption_offers', false, 'update')"
          />
        </div>
      </v-layout>
      <v-layout row justify-center>
        <v-flex sm6 md4 lg4>
          <v-text-field
            label="Name"
            v-model="offer.name"
            :readonly="!this.readWrite"
            :outline="this.readWrite"
          />
        </v-flex>
        <v-flex sm6 md4 lg4>
          <v-text-field
            label="ID"
            v-model="offer.id"
            readonly
            placeholder="New Offer"
          />
        </v-flex>
      </v-layout>
      <v-layout row justify-center fill-height>
        <v-flex md8>
          <v-text-field
            label="Description"
            v-model="offer.description"
            :readonly="!this.readWrite"
            :outline="this.readWrite"
          />
        </v-flex>
      </v-layout>
      <v-layout row justify-center fill-height>
        <v-flex sm6 md4 lg4>
          <v-text-field
            label="Max uses per code"
            v-model="offer.maximumUses"
            type="number"
            :readonly="!this.readWrite"
            :outline="this.readWrite"
          />
        </v-flex>
        <v-flex sm6 md4 lg4>
          <v-text-field
            label="Max codes per user"
            v-model="offer.accountMaxRedemptionCount"
            type="number"
            :readonly="!this.readWrite"
            :outline="this.readWrite"
          />
        </v-flex>
      </v-layout>
      <v-layout row justify-center fill-height>
        <v-flex sm6 md4 lg4>
          <date-time-picker
            title="Start Date"
            v-model="offer.startDate"
            :readonly="!this.readWrite"
            :outline="this.readWrite"
          />
        </v-flex>
        <v-flex sm6 md4 lg4>
          <date-time-picker
            title="End Date"
            v-model="offer.endDate"
            :readonly="!this.readWrite"
            :outline="this.readWrite"
          />
        </v-flex>
      </v-layout>
      <v-layout row justify-center fill-height margin>
        <v-flex md8>
          <grant-list
            grantTypeName="Product"
            :grants="offer.productGrants"
            :edit="editMode"
            :updateGrants="updateProductGrants"
            :grantPrototype="{ gameId: '<value>', sku: '<value>' }"
            :readonly="!this.readWrite"
          />
        </v-flex>
        <!-- , $type:"Wizards.CodeRedemption.Models.ProductGrant, Wizards.CodeRedemption.Models" -->
      </v-layout>
      <v-layout row justify-center fill-height>
        <v-flex md8>
          <grant-list
            grantTypeName="Game Role"
            :grants="offer.gameRoleGrants"
            :edit="editMode"
            :updateGrants="updateGameRoleGrants"
            :grantPrototype="{ gameId: '<value>', gameRoleTag: '<value>' }"
            :readonly="!this.readWrite"
          />
        </v-flex>
        <!-- , $type:"Wizards.CodeRedemption.Models.GameRoleGrant, Wizards.CodeRedemption.Models" -->
      </v-layout>
      <v-layout row justify-center fill-height>
        <v-flex md8>
          <grant-list
            grantTypeName="Domain Role"
            :grants="offer.domainRoleGrants"
            :edit="editMode"
            :updateGrants="updateDomainRoleGrants"
            :grantPrototype="{ gameId: '<value>', domainRoleTag: '<value>' }"
            :readonly="!this.readWrite"
          />
        </v-flex>
        <!-- , $type:"Wizards.CodeRedemption.Models.DomainRoleGrant, Wizards.CodeRedemption.Models" -->
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import GrantList from './GrantList.vue';
  import DateTimePicker from './DateTimePicker.vue';

  import axios from 'axios';

  export default {
    name: 'CodeRedemptionOfferRead',

    components: {
      GrantList,
      DateTimePicker,
    },

    props: {
      offerId: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        readWrite: false,
        saving: false,
        offer: {
          domainRoleGrants: [],
          gameRoleGrants: [],
          productGrants: [],
        },
        error: '',
        datePlaceholder: new Date().toISOString(),
      };
    },

    created: function () {
      if (!_.isNil(this.offerId)) {
        this.getOffer(this.offerId);
      } else {
        this.readWrite = true;
      }
    },

    methods: {
      updateProductGrants(values) {
        this.offer.productGrants = values;
        // this.offer.grants.$values = [...values, ...this.offer.gameRoleGrants, ...this.offer.domainRoleGrants];
      },
      updateGameRoleGrants(values) {
        this.offer.gameRoleGrants = values;
        // this.offer.grants.$values = [...values, ...this.offer.productGrants, ...this.offer.domainRoleGrants];
      },
      updateDomainRoleGrants(values) {
        this.offer.domainRoleGrants = values;
        // this.offer.grants.$values = [...values, ...this.offer.gameRoleGrants, ...this.offer.productGrants];
      },
      getOffer(offerId) {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/coderedemption/offers/' +
              offerId,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.offer = response.data;
            this.offer.domainRoleGrants = [];
            this.offer.gameRoleGrants = [];
            this.offer.productGrants = [];

            if (
              'grants' in response.data &&
              '$values' in response.data.grants
            ) {
              for (let grant of response.data.grants.$values) {
                let keys = Object.keys(grant);

                if (keys.indexOf('domainRoleTag') != -1) {
                  this.offer.domainRoleGrants.push(grant);
                } else if (keys.indexOf('gameRoleTag') != -1) {
                  this.offer.gameRoleGrants.push(grant);
                } else if (keys.indexOf('sku') != -1) {
                  this.offer.productGrants.push(grant);
                }
              }
            }

            this.setError('');
          })
          .catch((err) => {
            this.setError(err);
          });
      },
      saveOffer() {
        this.$data.saving = true;

        if (_.isNil(this.$data.offer.id)) {
          // POST new offer
          axios
            .post(
              this.$store.getters.serviceURL + '/coderedemption/offers',
              this.$data.offer,
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.$data.offer = response.data;
              this.setError('');
              this.$data.saving = false;

              this.$router.push('/codeRedemption');
            })
            .catch((err) => {
              this.setError(err);
              this.$data.saving = false;
            });
        } else {
          // PUT existing offer
          axios
            .put(
              this.$store.getters.serviceURL +
                '/coderedemption/offers/' +
                this.$data.offer.id,
              this.$data.offer,
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.$data.offer = response.data;
              this.setError('');
              this.$data.saving = false;

              this.$router.push('/codeRedemption');
            })
            .catch((err) => {
              this.setError(err);
              this.$data.saving = false;
            });
        }
      },
      setError(err) {
        this.$data.error = '' + err;
      },
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
    },

    computed: {
      editMode() {
        return !_.isNil(this.$data.offer.id);
      },
      loggedIn() {
        return this.$store.getters.authenticated;
      },
    },
  };
</script>
