<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex row wrap="false" xs8 sm8 md8 lg8 box pa8>
        <v-text-field
          label="Email Address"
          type="text"
          :error="hasError"
          :suffix="verified"
          v-model="newEmail"
        ></v-text-field>
      </v-flex>
      <v-flex row wrap="false" xs3 sm3 md3 lg3 box pa3>
        <v-btn :disabled="disableUpdate" @click="confirm">Update</v-btn>
        <a class="copy" @click="copy()">
          <v-icon title="Copy">content_copy</v-icon>
        </a>
        <input type="text" id="EmailAddressCopyValue" :value="initialEmail" />
      </v-flex>
      <v-flex xs1 sm1 md1 lg1 v-if="error">
        <v-icon :title="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1 v-else-if="success">
        <v-icon>check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'emailForm',
    data() {
      return {
        newEmail: '',
        initialEmail: '',
        error: '',
        success: false,
      };
    },
    mounted() {
      this.initialEmail = this.getEmailAddress;
      this.newEmail = this.getEmailAddress;
    },
    watch: {
      getEmailAddress: function () {
        this.newEmail = this.getEmailAddress;
      },
    },
    methods: {
      confirm() {
        this.$refs.confirm
          .open(
            'Update Email Address',
            "Are you sure you want to update this account's email address?"
          )
          .then((confirm) => {
            if (confirm) {
              this.$store
                .dispatch({ type: 'updateEmail', newEmail: this.newEmail })
                .then(() => {
                  this.success = true;
                  this.error = '';
                  this.initialEmail = this.newEmail;
                })
                .catch((err) => {
                  this.error = err;
                  this.success = false;
                  this.newEmail = this.initialEmail;
                });
            } else {
              this.newEmail = this.initialEmail;
            }
          });
      },
      copy() {
        try {
          let target = document.getElementById('EmailAddressCopyValue');
          target.focus();
          target.select();
          document.execCommand('copy');
        } catch (err) {
          alert('Your browser does not support automatic copy-paste.', err);
        }
      },
    },
    computed: {
      getEmailAddress() {
        return this.$store.getters.account.email;
      },
      verified() {
        if (this.$store.getters.account.emailVerified) {
          return 'verified';
        }
        return 'unverified';
      },
      disableUpdate() {
        // Protect against setting the new email to an empty string.
        return this.initialEmail == this.newEmail || _.isEmpty(this.newEmail);
      },
      hasError() {
        return this.error.length > 0;
      },
    },
  };
</script>

<style scoped>
  #EmailAddressCopyValue {
    position: absolute;
    left: -9999px;
  }
  .copy {
    position: relative;
    top: 4px;
  }
</style>
