<template>
  <div>
    <v-container>
      <!-- Generic Loading Screen -->
      <GenericLoadingScreen :isLoading="processing"></GenericLoadingScreen>

      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <v-flex xs12 sm12 md12 lg12 box pa12>
          <div :if="isSuccess">
            <v-alert :value="isSuccess" type="success" dismissible>
              {{ this.success }}
            </v-alert>
          </div>
          <div :if="isError">
            <v-alert :value="isError" type="error" dismissible>
              {{ this.error }}
            </v-alert>
          </div>
          <v-container>
            <v-layout align-center pb-4>
              <v-flex>
                <h2>
                  <b v-if="!bulkMode">Create Accounts</b>
                  <b v-else>Create Accounts In Bulk via .CSV</b>
                </h2>
              </v-flex>
              <v-flex>
                <v-switch
                  style="float: right"
                  label="Bulk Mode"
                  v-model="bulkMode"
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-layout row justify-left pb-5>
              <DomainsSelector
                @domainsSelected="
                  (newDomain) => {
                    formValues.domainsSelected = newDomain;
                  }
                "
              />
            </v-layout>
            <v-layout row justify-left pb-5>
              <DomainRolesSelector
                :domain="formValues.domainsSelected"
                @newRoles="
                  (newRoles) => {
                    formValues.domainRolesSelected = newRoles;
                  }
                "
              />
            </v-layout>
            <v-layout row justify-left>
              <GameRolesSelector
                :domains-selected="formValues.domainsSelected"
                @gameRoleUpdate="
                  (newRoles) => {
                    formValues.gamesSelected = newRoles;
                  }
                "
              />
            </v-layout>
          </v-container>
          <v-layout row justify-left>
            <v-flex v-if="!bulkMode" xs12 sm12 md12 lg12 box pa12>
              <v-container>
                <v-form ref="manual" v-model="formValid">
                  <v-progress-linear
                    v-if="processing"
                    height="3"
                    :indeterminate="true"
                  ></v-progress-linear>
                  <br />
                  <v-layout row justify-left>
                    <v-flex xs6 sm6 md6 lg6 box pa6>
                      <v-text-field
                        label="Display Name"
                        :rules="[
                          rules.required,
                          rules.nameShort,
                          rules.nameLong,
                        ]"
                        v-model="formValues.displayName"
                        :disabled="bulkMode"
                        class="spaced"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3 sm3 md3 lg3 box pa3>
                      <v-text-field
                        label="First Name"
                        v-model="formValues.firstName"
                        :disabled="bulkMode"
                        class="spaced"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3 sm3 md3 lg3 box pa3>
                      <v-text-field
                        label="Last Name"
                        v-model="formValues.lastName"
                        :disabled="bulkMode"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row justify-left>
                    <v-flex xs9 sm9 md9 lg9 box pa9>
                      <v-text-field
                        label="Email Address"
                        :rules="[rules.required, rules.emailValid]"
                        v-model="formValues.emailAddress"
                        :disabled="bulkMode"
                        class="spaced"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3 sm3 md3 lg3 box pa3>
                      <v-text-field
                        label="Country"
                        :rules="[rules.required]"
                        v-model="formValues.country"
                        :disabled="bulkMode"
                        mask="AA"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row justify-left>
                    <v-flex xs6 sm6 md6 lg6 box pa6>
                      <v-text-field
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        :rules="[
                          rules.required,
                          rules.passwordComplexity,
                          rules.passwordShort,
                          rules.passwordLong,
                        ]"
                        label="Password"
                        v-model="formValues.password1"
                        :disabled="bulkMode"
                        class="spaced"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6 box pa6>
                      <v-text-field
                        :append-icon="show2 ? 'visibility' : 'visibility_off'"
                        :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                        :rules="[rules.required, rules.match]"
                        label="Confirm Password"
                        v-model="formValues.password2"
                        :disabled="bulkMode"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-flex>
            <v-flex v-if="bulkMode" xs12 sm12 md12 lg12 box pa12>
              <v-container>
                <csv-selector
                  @filePicked="
                    (newFile) => {
                      this.formValues.accountsFile = newFile;
                    }
                  "
                >
                  <div>
                    "display_name","first_name","last_name","email_address","password","country"
                    <br />
                    "UserName0","User","Name0","email0@wizards.com","qwerty12340","US"
                    <br />
                    "UserName1","User","Name1","email1@wizards.com","qwerty12341","US"
                    <br />
                  </div>
                </csv-selector>
              </v-container>
            </v-flex>
          </v-layout>
          <p class="pl-3">
            <v-btn v-if="!bulkMode" :disabled="!canSubmitForm" @click="create">
              Create
            </v-btn>
            <v-btn
              v-else
              :disabled="!canSubmitForm || processing"
              @click.stop="create"
            >
              Create
            </v-btn>
            <Poller
              :showing="dialog"
              :initialBulkChangeDetails="bulkRegisterDetails"
              :ready="processing"
              :isCreate="true"
              @create="create"
              @hide="resetForm()"
              @done="resetForm()"
            ></Poller>
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import moment from 'moment';
  import Poller from './Poller.vue';
  import _ from 'lodash';
  import GameRolesSelector from './GameRolesSelector';
  import DomainRolesSelector from './DomainRolesSelector';
  import DomainsSelector from './DomainsSelector';
  import CsvSelector from '../CsvSelector';
  import GenericLoadingScreen from '../utility/GenericLoadingScreen.vue';

  export default {
    name: 'create',
    components: {
      CsvSelector,
      DomainsSelector,
      DomainRolesSelector,
      GameRolesSelector,
      GenericLoadingScreen,
      Poller,
    },
    data() {
      return {
        canSubmitForm: false,
        formValid: false,
        bulkMode: false,
        dialog: false,
        show1: false,
        show2: false,
        domains: [],
        bulkRegisterDetails: {
          progress: 0,
          jobID: '',
          invitationID: '',
          expiresIn: moment().add(1, 'days'),
          registrationSuccess: 0,
          registrationFail: 0,
        },
        formValues: {
          displayName: '',
          country: '',
          emailAddress: '',
          firstName: '',
          lastName: '',
          password1: '',
          password2: '',
          domainsSelected: '',
          domainRolesSelected: [],
          gamesSelected: [],
          accountsFile: null,
        },
        rules: {
          required: (value) => !!value || 'Required.',
          passwordShort: (value) =>
            value.length >= 8 ||
            'Not long enough (must be 8 or more characters).',
          passwordComplexity: (value) =>
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/g.test(value) ||
            'Password must contain: 1 letter, 1 number, and be at least 8 characters long',
          passwordLong: (value) =>
            value.length <= 64 || 'Too long (must be 64 or less characters).',
          match: () =>
            this.formValues.password1 === this.formValues.password2 ||
            'Passwords do not match.',
          nameShort: (value) =>
            value.length >= 3 ||
            'Not long enough (must be 3 or more characters).',
          nameLong: (value) =>
            value.length <= 23 || 'Too long (must be 23 or less characters).',
          emailValid: (value) =>
            (value.length >= 5 && /^.+\@.+\..+$/.test(value)) ||
            'Email does not appear to be valid.', // super basic check
        },
        success: '',
        error: '',
        processing: false,
      };
    },
    watch: {
      formValues: {
        handler() {
          this.canSubmitForm = this.isPageValid();
        },
        deep: true,
      },
      formValid: function () {
        this.canSubmitForm = this.isPageValid();
      },
      bulkMode: function (newValue) {
        if (newValue) {
          this.resetForm();
        }
        this.canSubmitForm = this.isPageValid();
      },
    },
    methods: {
      basicOpts() {
        return {
          headers: {
            'Content-type': 'application/json',
            Authorization: this.$store.getters.basicAuthHeader,
          },
        };
      },
      resetForm() {
        this.dialog = false;
        this.processing = false;
        this.formValues.accountsFile = null;

        this.formValues.displayName = '';
        this.formValues.country = '';
        this.formValues.emailAddress = '';
        this.formValues.firstName = '';
        this.formValues.lastName = '';
        this.formValues.password1 = '';
        this.formValues.password2 = '';

        if (!this.bulkMode) {
          this.$refs.manual.resetValidation();
        }
      },
      isPageValid() {
        let valid = false;

        if (
          this.formValues.domainsSelected &&
          this.formValues.domainsSelected.length > 0 &&
          ((this.bulkMode && this.formValues.accountsFile != null) ||
            (this.formValid &&
              this.formValues.password1 === this.formValues.password2))
        ) {
          valid = true;
        }

        return valid;
      },
      async create() {
        this.setError('');
        this.setSuccess('');

        // initialize processing to grey out screen and stop actions
        // through the generic loading screen
        // change to a loading variable for better control and seperation
        // between the dialog and processing
        this.processing = true;

        try {
          if (this.bulkMode) {
            this.bulkRegisterAccount(
              this.formValues.domainsSelected,
              this.formValues.domainRolesSelected,
              this.formValues.gamesSelected,
              this.formValues.accountsFile
            );
          } else {
            let payload = {
              domainID: this.formValues.domainsSelected,
              domainRoles: this.formValues.domainRolesSelected,
              games: this.formValues.gamesSelected,
              uses: 1,
            };
            let invitation = await this.$store.dispatch(
              'createInvitation',
              payload
            );
            this.registerAccount(
              this.formValues.displayName,
              this.formValues.firstName,
              this.formValues.lastName,
              this.formValues.emailAddress,
              this.formValues.password1,
              this.formValues.country,
              invitation.invitationID
            );
          }
        } catch (err) {
          this.setError(err);
          this.processing = false;
        }
      },
      registerAccount(
        displayName,
        firstName,
        lastName,
        emailAddress,
        password,
        country,
        invitationID
      ) {
        this.processing = true;

        const FORMBODY = {
          displayName: displayName,
          email: emailAddress,
          country: country,
          acceptedTC: true,
          dateOfBirth: '2000-01-01',
          password: password,
          firstName: firstName,
          lastName: lastName,
          emailOptIn: false,
          dataShareOptIn: true,
          dryRun: false,
          invitationID: invitationID,
        };

        if (!this.isProduction()) {
          console.log('REGISTER ACCOUNT REQUEST:');
          console.log(FORMBODY);
        }

        axios
          .post(
            this.$store.getters.serviceURL + '/accounts/register',
            FORMBODY,
            this.basicOpts()
          )
          .then((response) => {
            if (!this.isProduction()) {
              console.log('REGISTER ACCOUNT RESPONSE:');
              console.log(response.data);
            }
            this.setSuccess(
              this.success + " Created '" + response.data.displayName + "'."
            );
            this.resetForm();
            this.processing = false;
          })
          .catch((err) => {
            this.setError(
              this.error +
                " Failed to create account for '" +
                displayName +
                "': " +
                err.response.data.error.toLowerCase() +
                '.'
            );
            this.processing = false;
          });
      },
      bulkRegisterAccount(domainID, domainRoles, games, accountsFile) {
        let formBody = new FormData();

        formBody.append('domainID', domainID);
        formBody.append('domainRoles', JSON.stringify(domainRoles));
        formBody.append('games', JSON.stringify(games));
        formBody.append('accountsFile', accountsFile);

        if (!this.isProduction()) {
          console.log('BULK REGISTER REQUEST:');
          console.log('DomainID:' + formBody.get('domainID'));
          console.log('FomainRoles:' + formBody.get('domainRoles'));
          console.log('Games:' + formBody.get('games'));
          console.log(formBody.get('accountsFile'));
        }
        axios
          .post(
            this.$store.getters.serviceURL + '/admin/bulk/register',
            formBody,
            this.$store.getters.fileOpts
          )
          .then((response) => {
            if (!this.isProduction()) {
              console.log('BULK REGISTER RESPONSE:');
              console.log(response.data);
            }
            this.bulkRegisterDetails = response.data;
            if (!this.bulkRegisterDetails.jobID) {
              throw new Error();
            } else {
              this.processing = true;
              this.dialog = true;
            }
          })
          .catch((err) => {
            if (!this.isProduction()) {
              console.log('BULK REGISTER ERROR RESPONSE:');
              console.log(err);
            }
            this.setError('Failed to create accounts.');
            this.processing = false;
          });
      },
      setError(err) {
        this.error = '' + err;
      },
      setSuccess(msg) {
        this.success = '' + msg;
      },
      isProduction() {
        return this.$store.getters.isProduction;
      },
    },
    computed: {
      isError() {
        return this.error.length > 0;
      },
      isSuccess() {
        return this.success.length > 0;
      },
    },
  };
</script>

<style scoped>
  h2 {
    position: relative;
  }
  h2 div.v-input {
    position: absolute;
    right: 0;
    height: 44px;
    margin-left: 20px;
  }
  .spaced {
    margin-right: 10px;
  }
  .valid-entries span b {
    cursor: pointer;
  }
  .valid-entries span::after {
    content: ',';
  }
  .valid-entries span:last-of-type::after {
    content: none;
  }
  div.compressed {
    margin-bottom: -30px;
  }
  div.compressed >>> label {
    font-size: 12px;
  }
</style>
