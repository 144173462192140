<template>
  <v-container>
    <genericTable
      itemName="Domain"
      :headers="headers"
      :getItemsURL="`/admin/accounts/domain`"
      redirectTemplate="/developer/domain/${item.domainID}"
      createRedirect="/developer/createdomain/"
      :canCreate="true"
    />
  </v-container>
</template>

<script>
  import genericTable from './GenericTable.vue';

  export default {
    name: 'Domains',
    components: {
      genericTable,
    },
    data() {
      return {
        headers: [
          { text: 'Domain ID', value: 'domainID' },
          { text: 'Description', value: 'description' },
          { value: '', sortable: false, width: '1%' },
        ],
      };
    },
  };
</script>
