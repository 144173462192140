<template>
  <v-container>
    <v-layout>
      <h2>
        Third Party Client Providers
        <v-btn icon @click="getItems">
          <v-icon>refresh</v-icon>
        </v-btn>
      </h2>
      <v-spacer />
      <v-flex sm4 md4>
        <v-text-field
          label="Search"
          append-icon="search"
          v-model="search"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout justify-center d-flex>
      <v-data-table :headers="appendedHeaders" :items="rows" :search="search">
        <template slot="no-data">
          <v-alert :value="!err" color="info" icon="info">
            No third-party clients available under this game
          </v-alert>
          <v-alert :value="err" color="error" icon="error">{{ err }}</v-alert>
        </template>
        <template slot="items" slot-scope="props">
          <tr @click="navigateTo(props.item)" :key="props.index">
            <td v-for="header in headers">
              <span
                v-if="
                  header.value in props.item &&
                  Array.isArray(props.item[header.value])
                "
              >
                <v-chip
                  v-for="item in props.item[header.value]"
                  :key="displayProperty ? item[displayProperty] : item"
                >
                  {{ displayProperty ? item[displayProperty] : item }}
                </v-chip>
              </span>
              <span v-else>
                {{ props.item[header.value] }}
              </span>
            </td>
            <td>
              <v-icon>navigate_next</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
    <v-btn v-if="canCreate" :to="createRedirect">{{ 'New ' + itemName }}</v-btn>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { update } from 'lodash';
  export default {
    name: 'genericTable',
    props: {
      itemName: {
        type: String,
        required: true,
      },
      headers: {
        type: Array,
        default() {
          return [];
        },
      },
      getItemsURL: {
        type: String,
        required: true,
      },
      redirectTemplate: {
        type: String,
      },
      canCreate: {
        type: Boolean,
        default() {
          return false;
        },
      },
      createRedirect: {
        type: String,
      },
      displayProperty: {
        type: String,
        default() {
          return '';
        },
      },
    },
    data() {
      return {
        search: '',
        rows: [],
        err: '',
        thirdPartyProviders: {
          1: 'Google',
          2: 'Apple',
          3: 'Epic',
        },
      };
    },
    computed: {
      appendedHeaders() {
        return this.headers.concat({
          text: 'More',
          sortable: false,
          width: '1%',
        });
      },
    },
    mounted: function () {
      this.getItems();
    },
    methods: {
      navigateTo(item) {
        let t = eval('`' + this.redirectTemplate + '`');
        this.$router.push(t);
      },
      createNew() {
        let t = eval('`' + this.newTemplate + '`');
        this.$router.push(t);
      },
      getItems() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          axios
            .get(
              this.$store.getters.serviceURL + this.getItemsURL,
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              let items = response.data[Object.keys(response.data)[0]];
              if (
                !!items &&
                (Array.isArray(items) || items.constructor === Object)
              ) {
                this.rows = this.reformatData(items);
              } else {
                this.rows = [];
              }
              this.err = '';
            })
            .catch((error) => {
              this.rows = [];
              this.err = error;
            });
        });
      },
      reformatData(items) {
        // Map identity provider enums to display name and reformat dates
        for (let i in items) {
          const ip = items[i].identityProvider;
          const createdAt = items[i].createdAt;
          const updatedAt = items[i].updatedAt;

          // Map e.g. 1 -> Google, 2 -> Apple
          items[i].identityProvider = this.thirdPartyProviders[ip];

          // Reformat Unix timestamps to readable date
          items[i].updatedAt = new Date(updatedAt.seconds * 1000);
          items[i].createdAt = new Date(createdAt.seconds * 1000);
        }
        return items;
      },
    },
  };
</script>

<style scoped>
  tr {
    cursor: pointer;
  }
</style>
