<template>
  <div>
    <v-container>
      <v-layout wrap row justify-left align-top>
        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('account_admin', true)"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Search for Accounts</div>
            </v-card-title>
            <v-card-text>
              Find an account based on Email, Display&nbsp;Name,
              Account&nbsp;ID, or Persona&nbsp;ID.
            </v-card-text>
            <v-card-actions>
              <v-btn @click="accounts">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('entitlements', true)"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Search Entitle&shy;ments</div>
            </v-card-title>
            <v-card-text>
              Find an entitlement by Receipt&nbsp;ID and source.
            </v-card-text>

            <v-card-actions>
              <v-btn @click="entitlements">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('admin/account_admin')"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Create Accounts</div>
            </v-card-title>
            <v-card-text>
              Create accounts with specified domain and game roles.
            </v-card-text>
            <v-card-actions>
              <v-btn @click="create">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('coderedemption_offers')"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">
                Code Redemption (
                <span class="beta">Deprecated</span>
                )
              </div>
            </v-card-title>
            <v-card-text>Manage offers and codes.</v-card-text>
            <v-card-actions>
              <v-btn @click="codeRedemption">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('admin/redemption')"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Redemption</div>
            </v-card-title>
            <v-card-text>Manage offers and codes.</v-card-text>
            <v-card-actions>
              <v-btn to="/redemption/offers">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('admin/audit')"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Audit Trail</div>
            </v-card-title>
            <v-card-text>Search backend activities.</v-card-text>
            <v-card-actions>
              <v-btn to="/audit">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="
            permissionAllowed('domains', true) ||
            permissionAllowed('games', true) ||
            permissionAllowed('clients', true)
          "
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Developer Tools</div>
            </v-card-title>
            <v-card-text>Manage domains, games, and clients.</v-card-text>
            <v-card-actions>
              <v-btn @click="developer">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('braze', true)"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Braze</div>
            </v-card-title>
            <v-card-text>
              Process Right-to-be-Forgotten requests for the Braze email
              service.
            </v-card-text>
            <v-card-actions>
              <v-btn @click="braze">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="
            permissionAllowed('domains', true) ||
            permissionAllowed('games', true)
          "
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Game / Domain Roles</div>
            </v-card-title>
            <v-card-text>
              Bulk add or remove domain and/or game roles from a list of
              personas or emails.
            </v-card-text>
            <v-card-actions>
              <v-btn to="/bulkroles">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="permissionAllowed('social', true, 'read')"
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Social</div>
            </v-card-title>
            <v-card-text>
              Tools for viewing and manipulating social resources
            </v-card-text>
            <v-card-actions>
              <v-btn to="/social">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex
          xs3
          sm3
          md3
          lg3
          box
          class="pa-1"
          v-if="
            permissionAllowed('accounts', true) &&
            permissionAllowed('personas', true)
          "
        >
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline hyphenate">Search Domain / Game Roles</div>
            </v-card-title>
            <v-card-text> Audit Accounts by Domain and Game. </v-card-text>
            <v-card-actions>
              <v-btn to="/search-roles">Go</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'landing',
    methods: {
      roles() {
        this.$router.push('/roles').catch((e) => {});
      },
      entitlements() {
        this.$router.push('/entitlements').catch((e) => {});
      },
      accounts() {
        this.$router.push('/find').catch((e) => {});
      },
      create() {
        this.$router.push('/create').catch((e) => {});
      },
      codeRedemption() {
        this.$router.push('/codeRedemption').catch((e) => {});
      },
      developer() {
        this.$router.push('/developer').catch((e) => {});
      },
      braze() {
        this.$router.push('/braze').catch((e) => {});
      },
      socials() {
        this.$router.push('/social').catch((e) => {});
      },
      searchRoles() {
        this.$router.push('/search-roles').catch((e) => {});
      },
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
    },
    computed: {
      loggedIn() {
        return this.$store.getters.authenticated;
      },
    },
  };
</script>

<style scoped>
  .beta {
    color: red;
  }
  .v-card {
    position: relative;
    margin-right: 3px;
  }
  .v-card .v-card__text {
    padding-bottom: 75px;
  }
  .v-card .v-card__actions {
    position: absolute;
    bottom: 0;
    left: 6px;
  }
  .hyphenate {
    hyphens: auto;
  }
</style>
