import config from './config.js';
import router from './router.js';
import Vue from 'vue';
import Vuetify from 'vuetify';

import App from './App.vue';
import 'vuetify/dist/vuetify.min.css';
import mainStore from './store';
import authStore from './authStore';
import bulkChangeStore from './components/bulkchanges/bulkChangeStore';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';
import moment from 'moment';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuex);
Vue.use(Vuetify);

const store = new Vuex.Store({
  mutations: {
    clear(state: any) {
      window.localStorage.clear();
      state.mainStore.account = {};
      state.mainStore.userProfile = {
        accountID: '',
        personaID: '',
        gameID: '',
        email: '',
        externalID: '',
        emailOptIn: false,
        dataOptIn: false,
        ccpaProtectData: false,
        firstName: '',
        lastName: '',
        countryCode: '',
        language: '',
        emailVerified: false,
        createdAt: '',
        bans: [], //{gameID: "", startDate: "", endDate: ""}
        presenceSettings: {
          socialMode: '',
        },
      };
      state.authStore.auth.access_token = '';
      state.authStore.auth.refresh_token = '';
      state.authStore.auth.nextRefreshDate = moment();
    },
  },
  modules: {
    mainStore,
    authStore,
    bulkChangeStore,
  },
  plugins: [
    createPersistedState({
      paths: ['authStore.auth.refresh_token', 'mainStore.lastDomain'],
      getState: (key) => Cookies.getJSON(key),
      setState: (key, value) =>
        Cookies.set(key, value, { expires: 4, secure: config.secureCookies }),
      storage: {
        getItem(key) {
          return Cookies.getJSON(key);
        },
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 4, secure: config.secureCookies }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});

export default store;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
