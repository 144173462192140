<template>
  <div>
    <v-layout row justify-left align-center>
      ConsentCheq Status:
      <v-chip
        class="ma-2"
        :color="statusColorMap[this.$props.status]"
        text-color="white"
        >{{ this.statusTextMap[this.$props.status] }}</v-chip
      >
    </v-layout>
  </div>
</template>

<script>
  export default {
    name: 'consentStatus',
    props: {
      status: '',
    },
    data() {
      return {
        statusColorMap: {
          0: 'red', // Revoked
          1: 'green', // Granted
          2: 'blue', // Exempt
          3: 'default', // Undefined
        },
        statusTextMap: {
          0: 'Revoked',
          1: 'Granted',
          2: 'Exempt',
          3: 'None',
        },
      };
    },
  };
</script>
