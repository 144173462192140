<template>
  <v-container>
    <thirdPartyGenericDetails
      :id="gameIdentityProviderID"
      itemName="Third Party Client"
      :redirectBack="`/developer/domain/${domainID}/game/${gameID}`"
      :isNew="newThirdPartyClient"
      :updateInProgress="updateInProgress"
      :createNew="createThirdPartyClient"
      :startUpdate="startUpdate"
      :cancelUpdate="cancelUpdate"
      :updateOld="updateThirdPartyClient"
      :deleteOld="deleteThirdPartyClient"
    >
      <template slot="form">
        <v-layout row>
          <v-text-field label="Domain" v-model="domainID" disabled />
          <v-spacer />
          <v-text-field label="Game" v-model="gameID" disabled />
        </v-layout>
        <v-text-field
          label="Game Identity Provider ID"
          v-model="gameIdentityProviderID"
          disabled
          placeholder="[generated by the server]"
        ></v-text-field>
        <h2>Identity Provider Details</h2>
        <v-select
          v-if="newThirdPartyClient"
          label="Identity Provider"
          v-model="currProvider"
          :items="providersList"
        >
        </v-select>
        <!-- Add new 3rd party client forms here -->
        <google-client-form
          v-if="currProvider === 'Google' || currProvider === 1"
          @formUpdated="updateCredentials($event)"
          :gameIdentityProviderID="gameIdentityProviderID"
          :formData="credentials"
          :savedFormData="savedCredentials"
          :updateInProgress="updateInProgress"
        />
        <apple-client-form
          v-if="currProvider === 'Apple' || currProvider === 2"
          @formUpdated="updateCredentials($event)"
          :gameIdentityProviderID="gameIdentityProviderID"
          :formData="credentials"
          :savedFormData="savedCredentials"
          :updateInProgress="updateInProgress"
        />
        <epic-client-form
          v-if="currProvider === 'Epic' || currProvider === 3"
          @formUpdated="updateCredentials($event)"
          :gameIdentityProviderID="gameIdentityProviderID"
          :formData="credentials"
          :savedFormData="savedCredentials"
          :updateInProgress="updateInProgress"
        />
      </template>
    </thirdPartyGenericDetails>
  </v-container>
</template>
<script>
  import thirdPartyGenericDetails from './ThirdPartyGenericDetails.vue';
  import googleClientForm from './GoogleClientForm.vue';
  import appleClientForm from './AppleClientForm.vue';
  import epicClientForm from './EpicClientForm.vue';
  import axios from 'axios';

  export default {
    name: 'ThirdPartyDetails',
    components: {
      thirdPartyGenericDetails,
      googleClientForm,
      appleClientForm,
      epicClientForm,
    },
    props: ['identityProvider', 'gameID', 'domainID'],
    data() {
      return {
        thirdPartyClient: '',
        newThirdPartyClient: false,
        currProvider: '',
        providersList: ['Apple', 'Epic', 'Google'], // Add new client providers here. You will need to make a new component for the respective form as well e.g. GoogleClientForm
        credentials: '',
        savedCredentials: '',
        gameIdentityProviderID: '',
        error: '',
        updateInProgress: false,
      };
    },
    mounted: function () {
      if (
        typeof this.identityProvider === 'undefined' ||
        !this.identityProvider
      ) {
        this.newThirdPartyClient = true;
      } else {
        this.newThirdPartyClient = false;
        this.getDetails();
      }
    },
    watch: {
      error: function (val) {
        this.hasError = val ? true : false;
      },
    },
    methods: {
      updateGameIdentityProviderID(id) {
        this.gameIdentityProviderID = id;
      },
      getDetails() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let provider;
          switch (this.identityProvider) {
            case 'Google':
              provider = 1;
              break;
            case 'Apple':
              provider = 2;
              break;
            case 'Epic':
              provider = 3;
              break;
          }

          let req = `${this.$store.getters.serviceURL}/admin/developer/game/${this.gameID}/thirdPartyClient/${provider}`;
          axios
            .get(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.updateGameIdentityProviderID(
                response.data.gameIdentityProviderID
              );
              this.gameIdentityProviderID =
                response.data.gameIdentityProviderID;
              this.currProvider = this.identityProvider;

              switch (this.currProvider) {
                case 'Google':
                  this.credentials =
                    response.data.Credentials.GoogleidentityCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                case 'Apple':
                  this.credentials =
                    response.data.Credentials.AppleidCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                case 'Epic':
                  this.credentials =
                    response.data.Credentials.EpiconlineservicesCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              this.error = error;
            });
        });
      },
      createThirdPartyClient() {
        return new Promise((_resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/thirdPartyClient`;

          let newThirdPartyClient = {};
          switch (this.currProvider) {
            case 'Google':
              newThirdPartyClient = {
                gameID: this.gameID,
                identityProvider: 1,
                credentials: {
                  clientID: this.credentials.googleidentityClientID,
                  clientSecret: this.credentials.googleidentityClientSecret,
                },
              };
              break;
            case 'Apple':
              newThirdPartyClient = {
                gameID: this.gameID,
                identityProvider: 2,
                credentials: {
                  clientID: this.credentials.appleidClientID,
                  clientSecret: this.credentials.appleidClientSecret,
                  teamID: this.credentials.appleidTeamID,
                  keyID: this.credentials.appleidKeyID,
                  tokenIssuer: this.credentials.appleidTokenIssuer,
                  encodedKey: this.credentials.appleidEncodedKey,
                },
              };
              break;
            case 'Epic':
              newThirdPartyClient = {
                gameID: this.gameID,
                identityProvider: 3,
                credentials: {
                  clientID: this.credentials.epiconlineservicesClientID,
                  clientSecret: this.credentials.epiconlineservicesClientSecret,
                  deploymentID: this.credentials.epiconlineservicesDeploymentID,
                },
              };
              break;
          }

          axios
            .post(
              req,
              newThirdPartyClient,
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.credentials = response.data.credentials;
              this.currProvider = response.data.identityProvider;
              this.gameIdentityProviderID =
                response.data.gameIdentityProviderID;

              switch (this.currProvider) {
                case 1:
                  this.currProvider = 'Google';
                  this.credentials =
                    response.data.Credentials.GoogleidentityCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                case 2:
                  this.currProvider = 'Apple';
                  this.credentials =
                    response.data.Credentials.AppleidCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                case 3:
                  this.currProvider = 'Epic';
                  this.credentials =
                    response.data.Credentials.EpiconlineservicesCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                default:
                  break;
              }

              this.$router.push(
                `/developer/domain/${this.domainID}/game/${this.gameID}/thirdPartyClient/${this.currProvider}`
              );
              this.newThirdPartyClient = false;
            })
            .catch((error) => {
              this.error = error;
              reject(error);
            });
        });
      },
      deleteThirdPartyClient() {
        let provider;
        switch (this.identityProvider) {
          case 'Google':
            provider = 1;
            break;
          case 'Apple':
            provider = 2;
            break;
          case 'Epic':
            provider = 3;
            break;
        }
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/game/${this.gameID}/thirdPartyClient/${provider}`;
          axios
            .delete(req, this.$store.getters.bearerAuthHeaders)
            .then(() => {
              resolve(`/developer/domain/${this.domainID}/game/${this.gameID}`);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      startUpdate() {
        this.updateInProgress = true;
      },
      cancelUpdate() {
        this.updateInProgress = false;
      },
      updateThirdPartyClient() {
        let provider;
        switch (this.identityProvider) {
          case 'Google':
            provider = 1;
            break;
          case 'Apple':
            provider = 2;
            break;
          case 'Epic':
            provider = 3;
            break;
        }
        return new Promise((_resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/game/${this.gameID}/thirdPartyClient/${provider}`;

          let newThirdPartyClient = {};
          switch (this.currProvider) {
            case 'Google':
              newThirdPartyClient = {
                credentials: {
                  clientID: this.credentials.googleidentityClientID,
                  clientSecret: this.credentials.googleidentityClientSecret,
                },
              };
              break;
            case 'Apple':
              newThirdPartyClient = {
                credentials: {
                  clientID: this.credentials.appleidClientID,
                  clientSecret: this.credentials.appleidClientSecret,
                  teamID: this.credentials.appleidTeamID,
                  keyID: this.credentials.appleidKeyID,
                  tokenIssuer: this.credentials.appleidTokenIssuer,
                  encodedKey: this.credentials.appleidEncodedKey,
                },
              };
              break;
            case 'Epic':
              newThirdPartyClient = {
                credentials: {
                  clientID: this.credentials.epiconlineservicesClientID,
                  clientSecret: this.credentials.epiconlineservicesClientSecret,
                  deploymentID: this.credentials.epiconlineservicesDeploymentID,
                },
              };
              break;
          }

          axios
            .put(
              req,
              newThirdPartyClient,
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.credentials = response.data.credentials;
              this.currProvider = response.data.identityProvider;
              this.gameIdentityProviderID =
                response.data.gameIdentityProviderID;

              switch (this.currProvider) {
                case 1:
                  this.currProvider = 'Google';
                  this.credentials =
                    response.data.Credentials.GoogleidentityCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                case 2:
                  this.currProvider = 'Apple';
                  this.credentials =
                    response.data.Credentials.AppleidCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                case 3:
                  this.currProvider = 'Epic';
                  this.credentials =
                    response.data.Credentials.EpiconlineservicesCredentials;
                  this.savedCredentials = this.credentials;
                default:
                  break;
              }
              this.newThirdPartyClient = false;
              this.updateInProgress = false;
            })
            .catch((error) => {
              this.error = error;
              reject(error);
            });
        });
      },
      updateCredentials(newData) {
        this.credentials = newData;
      },
    },
  };
</script>
