<template>
  <v-container>
    <h3>Google Identity Credentials</h3>
    <v-spacer />
    <v-text-field
      label="Google Client ID"
      v-model="googleidentityClientID"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Client Secret"
      v-model="googleidentityClientSecret"
      :disabled="formDisabled()"
    />
  </v-container>
</template>

<script>
  export default {
    name: 'GoogleClientForm',
    props: [
      'gameIdentityProviderID',
      'formData',
      'savedFormData',
      'updateInProgress',
    ],
    data() {
      return {
        googleidentityClientID: '',
        googleidentityClientSecret: '',
      };
    },
    mounted: function () {
      if (this.formData != '') {
        this.googleidentityClientID = this.formData.googleidentityClientID;
        this.googleidentityClientSecret = this.formData.googleidentityClientSecret;
      }
    },
    watch: {
      googleidentityClientID: {
        handler() {
          this.formUpdated();
        },
      },
      googleidentityClientSecret: {
        handler() {
          this.formUpdated();
        },
      },
    },
    methods: {
      formDisabled() {
        //Ensures that when an update is canceled, the form reverts to the saved data, but also that we don't save blank credentials
        if (this.gameIdentityProviderID !== '' && !this.updateInProgress) {
          this.googleidentityClientID = this.savedFormData.googleidentityClientID;
          this.googleidentityClientSecret = this.savedFormData.googleidentityClientSecret;
        }
        return this.gameIdentityProviderID !== '' && !this.updateInProgress;
      },
      formUpdated() {
        const credentials = {
          googleidentityClientID: this.googleidentityClientID,
          googleidentityClientSecret: this.googleidentityClientSecret,
        };
        this.$emit('formUpdated', credentials);
      },
    },
  };
</script>
