<template>
  <v-container>
    <v-layout justify-end>
      <v-btn
        color="primary"
        to="/codeRedemption/offers"
        :disabled="!permissionAllowed('coderedemption_offers', false, 'create')"
      >
        New Offer
      </v-btn>
    </v-layout>
    <OfferList
      title="Live/pending offers"
      :offers="liveOffers"
      :showButton="true"
    />
    <OfferList
      title="Expired offers"
      :offers="expiredOffers"
      :showButton="false"
    />
  </v-container>
</template>

<script>
  import axios from 'axios';

  import OfferList from './OfferList.vue';

  export default {
    name: 'CodeRedemptionViewOffers',

    components: {
      OfferList,
    },

    data() {
      return {
        offers: [],
      };
    },

    created: function () {
      this.getOffers();
    },

    methods: {
      getOffers() {
        axios
          .get(
            this.$store.getters.serviceURL + '/coderedemption/offers',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.offers = response.data;
            this.setError('');
          })
          .catch((err) => {
            this.setError(err);
          });
      },
      setError(err) {
        this.error = '' + err;
      },
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
    },

    computed: {
      liveOffers() {
        if (_.isNull(this.offers)) {
          return [];
        }

        let now = Date.now();

        let filteredOffers = [];

        for (let o of this.offers) {
          let offerEnd = Date.parse(o.endDate);
          if (offerEnd > now) {
            filteredOffers.push(o);
          }
        }

        return filteredOffers;
      },
      expiredOffers() {
        if (_.isNull(this.offers)) {
          return [];
        }

        let now = Date.now();

        let filteredOffers = [];

        for (let o of this.offers) {
          let offerEnd = Date.parse(o.endDate);
          if (offerEnd <= now) {
            filteredOffers.push(o);
          }
        }

        return filteredOffers;
      },
      loggedIn() {
        return this.$store.getters.authenticated;
      },
    },
  };
</script>
