<template>
  <v-dialog
    v-model="showing"
    overlay
    persistent
    transition="dialog-transition"
    width="500"
  >
    <v-card dark class="pl-3 pr-3 pb-3">
      <v-card-title class="pb-0">
        <h2>{{ pollerAction }} Accounts</h2>
        <v-spacer />
        <v-btn class="mr-0" icon @click="hide" dark>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-progress-linear
          v-if="!complete && !isError"
          v-model="bulkChangeDetails.progress"
          height="20"
        >
          <span class="pl-2">{{ bulkChangeDetails.progress }}%</span>
        </v-progress-linear>
        <div v-else-if="complete && !isError">
          <span v-if="bulkChangeDetails.fail > 0">
            Some attempts failed when {{ pollerAction.toLowerCase() }} accounts.
            Please download the provided CSV to view and correct the found
            errors.
            <br />
            <v-btn class="ml-0" :href="downloadURL" target="_blank" block dark>
              Download CSV
            </v-btn>
          </span>
          <span v-else>
            {{ pollerAction }} accounts was a success.
            <br />
            <v-btn class="ml-0" @click="hide" block dark>Done</v-btn>
          </span>
        </div>
        <span v-else>{{ this.error }}</span>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
  import moment from "moment";

  export default  {
    name: 'poller',
    props: ['initialBulkChangeDetails', 'ready', 'showing', 'isCreate'],
    mounted() {
      this.bulkChangeDetails = this.initialBulkChangeDetails
    },
    unmounted() {
      this.canPoll = false;
      clearTimeout(this.pollID)
    },
    data() {
      return {
        canPoll: false,
        pollID: 0,
        pollInterval: 1000,
        isError: false,
        error: "",
        bulkChangeDetails: {
          progress: 0,
          jobID: '',
          invitationID: '',
          expiresIn: moment().add(1, 'days'),
          success: 0,
          fail: 0,
          downloadURL: "",
        },
      }
    },
    watch: {
      showing(newValue) {
        if (newValue && this.initialBulkChangeDetails.jobID != '') {
          this.bulkChangeDetails = this.initialBulkChangeDetails;

             this.startPolling()

        }
      }
    },
    methods: {
      hide() {
        this.$emit("hide");
        this.$emit("done");
        this.resetDetails();
      },
      startPolling() {
        this.canPoll = true;

        this.fetchBulkChangeDetails();
      },
      fetchBulkChangeDetails() {
        return this.$store.dispatch('fetchBulkChangeStatus', {jobID: this.bulkChangeDetails.jobID}).then(resp => {
          if (!this.isProduction()) {
              console.log('FETCH BULK CHANGE DETAILS RESPONSE:');
              console.log(resp.data);
            }
          this.bulkChangeDetails = resp.data;
          if (this.canPoll && this.bulkChangeDetails.progress < 100) {
           setTimeout(()=>{this.fetchBulkChangeDetails()},this.pollInterval);
          }
        }).catch(err => {
          if (!this.isProduction()) {
              console.log('FETCH BULK CHANGE DETAILS ERROR:');
              console.log(err);
            }
          this.bulkChangeDetails.progress = 100;
          this.canPoll = false;
          this.pollID = 0;
          this.isError = true;
          this.error = err.toString()
        });
      },
      resetDetails() {
        this.bulkChangeDetails.progress = 0;
        this.bulkChangeDetails.jobID = '';
        this.bulkChangeDetails.invitationID = '';
        this.bulkChangeDetails.expiresIn = moment().add(1, 'days');
        this.bulkChangeDetails.success = 0;
        this.bulkChangeDetails.fail = 0;
      },
       isProduction() {
        return this.$store.getters.isProduction;
      },
    },
    computed: {
      downloadURL() {
        if (this.hasURL) {
          return this.bulkChangeDetails.downloadURL
        } else {
          return '#'
        }
      },
      hasURL() {
        return !_.isNil(this.bulkChangeDetails.downloadURL)
      },
      complete() {
        return this.bulkChangeDetails.progress >= 100 && this.hasURL
      },
      pollerAction() {
        return this.isCreate ? "Creating" : "Updating";
      }
    }
  }
</script>
