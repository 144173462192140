<template>
  <div>
    <v-container>
      <h2>Social Resources</h2>
      <v-layout justify-center align-center>
        <FriendshipSearchForm type="standalone"></FriendshipSearchForm>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import FriendshipSearchForm from './FriendshipSearchForm.vue';

  export default {
    name: 'social',

    components: { FriendshipSearchForm },
  };
</script>

<style scoped>
  h2 {
    font-size: x-large;
  }
</style>
