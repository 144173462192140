<template>
  <v-card>
    <v-card-title class="pb-0 pt-0 pr-2">
      Add Ban
      <v-spacer />
      <v-btn class="mr-0" icon v-on:click="$emit('close-ban-dialog')" dark>
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="send">
        <v-select
          :disabled="this.isExternalPersona"
          :items="currentGameIDs"
          label="GameID"
          v-model="newBan.gameID"
          type="text"
          :rules="[rules.required]"
        />

        <v-menu
          ref="startDateMenu"
          :close-on-content-click="false"
          v-model="startDateMenu"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="newBan.start"
            label="Start Date"
            persistent-hint
            maxlength="10"
            :rules="[rules.required, rules.counter]"
          />
          <v-date-picker
            v-model="newBan.start"
            no-title
            @input="startDateMenu = false"
          />
        </v-menu>

        <v-layout>
          <v-flex>
            <v-menu
              :disabled="!temporaryBan"
              ref="endDateMenu"
              :close-on-content-click="false"
              v-model="endDateMenu"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <v-text-field
                :disabled="!temporaryBan"
                slot="activator"
                v-model="newBan.end"
                label="End Date"
                persistent-hint
                maxlength="10"
                :rules="[rules.counter]"
              />
              <v-date-picker
                v-model="newBan.end"
                no-title
                @input="endDateMenu = false"
              />
            </v-menu>
          </v-flex>

          <v-flex>
            <v-switch
              v-model="temporaryBan"
              label="Temporary Suspension"
              @change="clearEndDate"
            />
          </v-flex>
        </v-layout>

        <v-textarea
          outline
          rows="6"
          label="Comments"
          v-model="newBan.comment"
          type="textbox"
          :rules="[rules.required]"
        />

        <v-btn block @click="confirmAdd()">Add</v-btn>
      </v-form>
      <v-alert v-model="hasError">{{ getError }}</v-alert>
    </v-card-text>

    <confirm-dialogue ref="confirm" />
  </v-card>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'createban',
    data() {
      return {
        temporaryBan: false,
        newBan: {
          start: moment().format('YYYY-MM-DD'),
          end: '',
          gameID: '',
          comment: '',
        },
        startDateMenu: false,
        endDateMenu: false,
        error: '',
        rules: {
          required: (value) => (!!value && value.length > 0) || 'Required',
          counter: (value) =>
            !value || value.length <= 10 || 'Max 10 characters',
        },
      };
    },
    watch: {
      newBan: {
        deep: true,
        handler: function (newVal, oldVal) {
          if (
            this.newBan.start == moment().format('YYYY-MM-DD') &&
            this.newBan.end == '' &&
            this.newBan.gameID == '' &&
            this.newBan.comment == ''
          ) {
            this.$emit('update:hasChanges', false);
          } else {
            this.$emit('update:hasChanges', true);
          }
        },
      },
    },
    mounted() {
      if (this.isExternalPersona) {
        this.newBan.gameID = this.$store.getters.account.gameID;
      }
    },
    methods: {
      clearEndDate() {
        if (!this.temporaryBan) {
          this.newBan.end = '';
        }
      },
      confirmAdd() {
        // if front end validation fails, bail to have user fix request
        if (!this.$refs.form.validate()) {
          return;
        }

        let startDate = moment(this.newBan.start).utc().format();
        let endDate = this.newBan.end
          ? moment(this.newBan.end).utc().format()
          : null;
        let comment = this.newBan.comment.replace(/\r?\n/g, '\\n');
        let newBan = {
          StartDate: startDate,
          EndDate: endDate,
          DomainID: this.$store.getters.account.domainID,
          PersonaID: this.personaForGame(this.newBan.gameID),
          GameID: this.newBan.gameID,
          Comment: comment,
        };
        if (!this.isExternalPersona) {
          newBan['AccountID'] = this.$store.getters.accountID;
        }

        let confirmMessage =
          'This will prevent this account from logging into ' +
          this.newBan.gameID;
        confirmMessage += this.newBan.end
          ? ' from ' + this.newBan.start + ' until ' + this.newBan.end + '.'
          : ' indefinitely starting ' + this.newBan.start;

        this.$refs.confirm
          .open('Confirm Ban', confirmMessage)
          .then((confirm) => {
            this.setError('');
            if (confirm) {
              return this.$store
                .dispatch({
                  type: 'createBan',
                  ban: newBan,
                })
                .then((_) => {
                  this.$emit('update:hasChanges', false);
                  this.$emit('close-ban-dialog');

                  // clear out stale data and reset form validation to prevent
                  // errors showing before the user inputs info
                  this.newBan.end = '';
                  this.newBan.gameID = '';
                  this.newBan.comment = '';
                  this.temporaryBan = false;
                  this.newBan.start = moment().format('YYYY-MM-DD');
                  this.$refs.form.resetValidation();
                });
            }
          })
          .catch((err) => {
            this.setError(
              'Unable to create ban, please make sure all fields are valid: ' +
                err
            );
          });
      },
      setError(err) {
        this.error = '' + err;
      },
    },
    computed: {
      hasError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      accountID() {
        return this.$store.getters.accountID;
      },
      currentGameIDs() {
        return this.$store.getters.personaGameIDs;
      },
      personaForGame() {
        return function (gameID) {
          let personaID = '';
          this.$store.getters.personas.forEach((persona) => {
            if (persona.gameID == gameID) {
              personaID = persona.personaID;
            }
          });
          return personaID;
        };
      },
      isExternalPersona() {
        return this.$store.getters.isExternalPersona;
      },
    },
  };
</script>
