<template>
  <div>
    <div v-if="valid">
      <v-container>
        <v-layout row justify-left pa-3 mb-2>
          <v-flex xs6 sm6 md6 lg6 box pa6>
            <v-layout row justify-left>
              <v-flex xs8 sm8 md8 lg8 box pa8>
                <v-text-field
                  label="External Persona"
                  v-model="accountID"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Domain ID"
                  v-model="domainID"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Game ID"
                  v-model="gameID"
                  readonly
                ></v-text-field>
                <v-layout v-if="conflicts[accountID]">
                  <v-icon class="mr-3" color="orange darken-3">
                    mdi-alert-octagon
                  </v-icon>
                  <p>
                    This {{ getPersona.externalPlatform }} persona is in
                    conflict with persona ID:
                    <a @click.prevent.stop="viewPersona"
                      >{{ getConflictingPersona.personaID }}.</a
                    >
                    Perform a search for the conflicting persona to resolve this
                    conflict or find more information.
                  </p>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 sm6 md6 lg6 box pa6>
            <v-layout row justify-left>
              <v-flex xs8 sm8 md8 lg8 box pa8>
                <v-text-field
                  label="Social Identity"
                  v-model="socialIdentity"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <display-name></display-name>
            <delete
              v-if="
                permissionAllowed(domainID + '/account_admin', false, 'delete')
              "
            ></delete>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container>
        <v-tabs centered grow color="gray lighten-1" dark icons-and-text>
          <v-tabs-slider color="blue-grey lighten-3"></v-tabs-slider>
          <v-tab href="#tab-bans">
            Bans
            <v-icon>highlight_off</v-icon>
          </v-tab>
          <v-tab
            href="#tab-game-roles"
            v-if="permissionAllowed('game_roles', true)"
          >
            Game Roles
            <v-icon>dvr</v-icon>
          </v-tab>
          <v-tab
            href="#tab-entitlements"
            v-if="permissionAllowed('entitlements', true)"
          >
            Entitlements
            <v-icon>shopping_basket</v-icon>
          </v-tab>
          <v-tab href="#tab-audit" v-if="permissionAllowed('audit', true)">
            Audit
            <v-icon>search</v-icon>
          </v-tab>
          <v-tab-item value="tab-bans">
            <bans></bans>
          </v-tab-item>
          <v-tab-item
            value="tab-game-roles"
            v-if="permissionAllowed('game_roles', true)"
          >
            <game-roles></game-roles>
          </v-tab-item>
          <v-tab-item
            value="tab-entitlements"
            v-if="permissionAllowed('entitlements', true)"
          >
            <entitlements type="profile"></entitlements>
          </v-tab-item>
          <v-tab-item value="tab-audit">
            <audit :targetIDProp="persona" />
          </v-tab-item>
        </v-tabs>
      </v-container>
    </div>
    <div v-else>
      <v-container v-if="checked">
        <v-layout row justify-center>
          <v-flex xs6 sm6 md6 lg6 box pa6>
            <h2>The External Persona you are looking for was not found</h2>
            <p>
              <br />
              <b>External Persona ID:</b>
              {{ accountID || $props.persona }}
            </p>
            <p>
              The external persona does not exist or you do not have the
              permissions to view it here.
            </p>
            <p>
              <v-btn @click="$router.go(-1)" color="grey darken-2">
                Go Back
              </v-btn>
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import DisplayName from './DisplayName.vue';
  import GameRoles from './GameRoles.vue';
  import Delete from './Delete.vue';
  import Bans from './Bans.vue';
  import Entitlements from './Entitlements.vue';
  import Audit from './Audit.vue';

  export default {
    name: 'editexternal',
    data() {
      return {
        valid: false,
        checked: false,
        conflicts: {},
      };
    },
    props: {
      persona: {
        type: String,
        default: null,
      },
      domain: {
        type: String,
        default: null,
      },
    },
    watch: {
      accountID: function () {
        this.getConflictInfoForPersona();
      },
      personaID: function () {
        this.getProfile();
      },
    },
    beforeCreated: function () {
      this.$store.commit('setIsExternalPersona', true);
    },
    created: function () {
      if (typeof this.accountID == 'undefined') {
        this.getAccount(this.$props.persona).then((response) => {
          this.$store.commit('setExternalAccount', response.data);
        });
      } else {
        this.valid = true;
      }
      this.getConflictInfoForPersona();
    },
    methods: {
      getAccount(personaID) {
        return axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/user/persona/' +
              personaID,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.setError('');
            this.valid = true;
            return response;
          })
          .catch((err) => {
            this.checked = true;
            this.setError('Failed to Get Account: ' + err);
          });
      },
      getProfile() {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/profile/admin/persona/' +
              this.personaID,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setProfile', response.data);
            this.setError('');
          })
          .catch((err) => {
            this.setError('Failed to Get Profile: ' + err);
          });
      },
      setError(err) {
        this.$data.error = '' + err;
      },
      permissionAllowed(permission, general, action) {
        return this.$store.dispatch('permissionAllowed', {
          permission: permission,
          general: general,
          action: action,
        });
      },
      getConflictInfoForPersona() {
        if (typeof this.accountID !== 'undefined') {
          let conflictID = this.accountID;
          this.$store
            .dispatch('getConflictsForPlayer', {
              personaIDs: [conflictID],
            })
            .then((result) => {
              this.conflicts = result;
            });
        }
      },
      viewPersona() {
        this.getAccount(this.getConflictingPersona.personaID).then((result) => {
          let acctID = result.data.User.Account.account.accountID;
          let routeData = this.$router.resolve(
            `/edit/${this.$props.domain}/${acctID}`
          );
          window.open(routeData.href, '_blank');
        });
      },
    },
    computed: {
      accountID: function () {
        return this.$store.getters.accountID;
      },
      domainID: function () {
        return this.$store.getters.account.domainID;
      },
      socialIdentity: function () {
        return this.$store.getters.account.socialIdentity.socialIdentityID;
      },
      gameID: function () {
        return this.$store.getters.account.gameID;
      },
      getConflictingPersona: function () {
        let conflictInfo = this.conflicts[this.accountID];
        return conflictInfo[0].personaID === this.accountID
          ? conflictInfo[1]
          : conflictInfo[0];
      },
      getPersona: function () {
        let conflictInfo = this.conflicts[this.accountID];
        return conflictInfo[0].personaID === this.accountID
          ? conflictInfo[0]
          : conflictInfo[1];
      },
    },
    components: {
      DisplayName,
      GameRoles,
      Delete,
      Bans,
      Entitlements,
      Audit,
    },
  };
</script>

<style>
  h2 {
    color: #999;
    margin-left: 5px;
  }
  div.v-window {
    margin-top: 20px;
  }
  .v-tabs__wrapper {
    overflow: auto !important;
  }
</style>
