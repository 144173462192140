<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex xs6 sm6 md6 lg6 box pa6>
        <v-checkbox
          label="Require Reset"
          v-model="newRequireReset"
        ></v-checkbox>
      </v-flex>
      <v-flex xs2 sm2 md2 lg2>
        <v-btn :disabled="disableUpdate" @click="confirm">Update</v-btn>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="success">check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'requireReset',
    data() {
      return {
        initialRequireReset: false, // Record this value so we can disable the "Confirm" button if there is no update to be made.
        newRequireReset: false,
        error: false,
        success: false,
      };
    },
    mounted() {
      this.initialRequireReset = this.getRequireReset;
      this.newRequireReset = this.getRequireReset;
    },
    watch: {
      getRequireReset: function () {
        this.newRequireReset = this.getRequireReset;
      },
    },
    methods: {
      // Open a dialog for confirmation when update is pressed that describes the effect of setting the state to on/off.
      confirm() {
        if (this.newRequireReset) {
          this.$refs.confirm
            .open(
              'Require Password Reset',
              'Are you sure you want to force a reset on this account? This ' +
                'will require the user to reset their password in order to gain access to their account.'
            )
            .then((confirm) => {
              if (confirm) {
                this.submit();
              } else {
                //no-op
              }
            });
        } else {
          this.$refs.confirm
            .open(
              'Remove Required Reset',
              'Are you sure you want to remove this requirement? You ' +
                'will allow access to the account without a required password reset.'
            )
            .then((confirm) => {
              if (confirm) {
                this.submit();
              } else {
                //no-op
              }
            });
        }
      },
      submit() {
        axios
          .put(
            this.requireResetURL(),
            { requireReset: this.newRequireReset },
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setRequireReset', {
              requireReset: response.data.requireReset,
            });
            this.initialRequireReset = this.newRequireReset;
            this.error = false;
          })
          .catch((err) => {
            this.newRequireReset = this.getRequireReset;
            console.error(err);
            this.error = true;
          });
      },
      requireResetURL() {
        return (
          this.$store.getters.serviceURL +
          '/admin/accounts/account/' +
          this.accountID +
          '/requireReset'
        );
      },
    },
    computed: {
      getRequireReset() {
        if (this.$store.getters.account.requireReset) {
          // This can evaluate to undefined.
          return true;
        } else {
          return false;
        }
      },
      accountID() {
        return this.$store.getters.account.accountID;
      },
      disableUpdate() {
        return this.initialRequireReset == this.newRequireReset;
      },
    },
  };
</script>
