<template lang="html">
  <v-container>
    <v-form ref="form">
      <v-layout row justify-center>
        <v-flex xs5>
          <v-select
            :items="domains"
            v-model="batchDetails.domainID"
            label="Domain ID"
            :rules="[validationRules.required]"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-flex xs5>
          <v-text-field
            name="totalCodes"
            label="Number of Codes"
            id="totalCodes"
            type="number"
            v-model="batchDetails.totalCodes"
            :rules="[
              validationRules.required,
              validationRules.greaterThanZero,
              validationRules.lessThan100k,
            ]"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-flex xs5>
          <v-text-field
            name="tag"
            label="Tag"
            id="tag"
            v-model="batchDetails.tag"
            hint="Optional. Used to export multiple batches at the same time."
            persistent-hint
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-flex xs5>
          <v-btn class="ml-0 mt-3" @click="submit" :disabled="disableButton">
            Create
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click="snackbar.visible = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script lang="js">
  import permissions from '../../permissions';
  import _ from 'lodash';

  export default  {
    name: 'batch-creation-form',
    props: ['offerID'],
    created() {
      this.$store.dispatch('getDomains');
    },
    data() {
      return {
        batchDetails: {
          tag: '',
          totalCodes: 1,
          domainID: ''
        },
        disableButton: false,
        domainErr: false,
        validationRules: {
          required: value => !!value || 'Required',
          greaterThanZero: value => value > 0 || 'Must be greater than zero',
          lessThan100k: value => value <= 100000 || 'Maximum 100,000 codes per batch',
        },
        snackbar: {
          text: "Error creating batch. Please try again.",
          color: "error",
          visible: false
        }
      }
    },
    methods: {
      submit() {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.disableButton = true;

        let request = {
          tag: this.batchDetails.tag,
          domainID: this.batchDetails.domainID,
          totalCodes: parseInt(this.batchDetails.totalCodes),
        }

        this.$store.dispatch('createRedemptionBatch', {
          offerID:this.offerID,
          batchDetails: request})
        .then(resp => {
            this.disableButton = false;
            this.$emit('created', resp.data);
        })
        .catch(err => {
            let errData = Object.assign({}, err.response.data);
            if (errData.error.toUpperCase() == "TAG IS NOT UNIQUE") {
              this.snackbar.text = "This tag is already in use, please choose a different one.";
            } else {
              this.snackbar.text = "Error creating batch. Please try again." // default generic otherwise
            }

            this.disableButton = false;
            this.snackbar.visible = true;
        });
      }
    },
    computed: {
      domains() {
        let allDomains = this.$store.getters.domains

        return _.filter(allDomains, (domain) => {
          let requiredPerm = {
            domainID: domain,
            resource: 'redemption',
            access: permissions.flags.create
          }
          return this.$store.getters.testPermission(requiredPerm);
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .batch-creation-form {
  }
</style>
