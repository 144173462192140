<template>
  <v-container>
    <genericDetails
      :id="gameID"
      itemName="Game"
      :redirectBack="`/developer/domain/${domainID}`"
      :isNew="newGame"
      :createNew="createGame"
      :deleteOld="deleteGame"
    >
      <template slot="form">
        <v-text-field label="Domain" v-model="domainID" disabled></v-text-field>
        <v-text-field
          label="Game ID"
          v-model="gameID"
          :disabled="!newGame"
          :rules="[rules.gameID]"
        ></v-text-field>
        <v-textarea
          label="Description"
          v-model="description"
          :disabled="!newGame"
          rows="1"
        />
      </template>
    </genericDetails>
    <genericTable
      v-if="!newGame"
      itemName="Client"
      :headers="clientHeaders"
      :getItemsURL="`/admin/developer/game/${gameID}/clients`"
      :redirectTemplate="
        '/developer/domain/' +
        domainID +
        '/game/' +
        gameID +
        '/client/${item.clientID}'
      "
      :createRedirect="`/developer/domain/${domainID}/game/${gameID}/createclient`"
      :canCreate="!newGame"
      displayProperty="tag"
    />
    <thirdPartyClientTable
      v-if="!newGame"
      itemName="Third-Party Identity Provider"
      :headers="thirdPartyHeaders"
      :getItemsURL="`/admin/developer/game/${gameID}/thirdPartyClients`"
      :createRedirect="`/developer/domain/${domainID}/game/${gameID}/createThirdParty`"
      :redirectTemplate="
        '/developer/domain/' +
        domainID +
        '/game/' +
        gameID +
        '/thirdPartyClient/${item.identityProvider}'
      "
      :canCreate="!newGame"
      displayProperty="tag"
    />
    <genericTable
      v-if="!newGame"
      itemName="Role"
      :headers="roleHeaders"
      :getItemsURL="`/admin/accounts/game/${gameID}/roles`"
      redirectTemplate="/developer/domain/${item.domainID}/game/${item.gameID}/role/${item.roleID}"
      :createRedirect="`/developer/domain/${domainID}/game/${gameID}/createrole`"
      :canCreate="!newGame"
      :key="componentKey"
    />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import genericTable from './GenericTable.vue';
  import ThirdPartyClientTable from './ThirdPartyClientTable.vue';
  import genericDetails from './GenericDetails.vue';

  export default {
    name: 'GameDetails',
    props: ['gameID', 'domainID'],
    components: { genericTable, genericDetails, ThirdPartyClientTable },
    data() {
      return {
        clientHeaders: [
          { text: 'Client ID', value: 'clientID' },
          { text: 'Description', value: 'description' },
          { text: 'Roles', value: 'roles' },
          { sortable: false, width: '1%' },
        ],
        thirdPartyHeaders: [
          { text: 'Identity Provider', value: 'identityProvider' },
          { text: 'Identity Provider ID', value: 'gameIdentityProviderID' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Updated At', value: 'updatedAt' },
        ],
        roleHeaders: [
          { text: 'Tag', value: 'tag' },
          { text: 'Description', value: 'description' },
          { sortable: false, width: '1%' },
        ],
        description: '',
        newGame: false,
        componentKey: 0, //forces component to re-mount
        rules: {
          gameID: (value) => {
            const pattern = /^[a-z0-9_]+$/;
            return (
              pattern.test(value) ||
              'Invalid GameID. Valid characters are a-z 0-9 and _'
            );
          },
        },
      };
    },
    mounted: function () {
      if (typeof this.gameID === 'undefined' || !this.gameID) {
        this.newGame = true;
      } else {
        this.newGame = false;
        this.getDetails();
      }
    },
    methods: {
      getDetails() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/accounts/domain/${this.domainID}/game`;
          axios
            .get(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              for (let i = 0; i < response.data.games.length; i++) {
                if (response.data.games[i].gameID == this.gameID) {
                  this.description = response.data.games[i].description;
                  break;
                }
              }
            })
            .catch((error) => {
              this.description = '';
            });
        });
      },
      createGame() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/game`;
          let newGame = {
            domainID: this.domainID,
            gameID: this.gameID,
            description: this.description,
            defaultRole: this.defaultRole,
            anonymousRole: this.anonymousRole,
          };
          axios
            .put(req, newGame, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              resolve(
                `/developer/domain/${response.data.domainID}/game/${response.data.gameID}`
              );
              this.newGame = false;
              this.componentKey++;
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      deleteGame() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/game/${this.gameID}`;
          axios
            .delete(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              resolve(`/developer/domain/${this.domainID}`);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
  };
</script>
