var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"row":"","justify-left":"","align-center":""}},[_c('v-flex',{attrs:{"xs8":"","sm8":"","md8":"","lg8":"","box":"","pa8":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',_vm._g({attrs:{"xs8":"","sm8":"","md8":"","lg8":"","box":"","pa6":"","mb-0":""}},on),[_c('v-checkbox',{attrs:{"label":"Opt Out of Targeted Data Analytics"},model:{value:(_vm.newTargetedAnalyticsOptOut),callback:function ($$v) {_vm.newTargetedAnalyticsOptOut=$$v},expression:"newTargetedAnalyticsOptOut"}})],1)]}}])},[_c('span',[_vm._v("Checked = Opted out of targeted data analytics")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',_vm._g({attrs:{"xs6":"","sm6":"","md6":"","lg6":"","box":"","pa6":"","mb-0":""}},on),[_c('v-checkbox',{attrs:{"label":"Email Opt-In"},model:{value:(_vm.newEmailOptIn),callback:function ($$v) {_vm.newEmailOptIn=$$v},expression:"newEmailOptIn"}})],1)]}}])},[_c('span',[_vm._v("Checked = Opted into marketing emails")])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',_vm._g({attrs:{"xs8":"","sm8":"","md8":"","lg8":"","box":"","pa6":"","mb-0":""}},on),[_c('v-checkbox',{attrs:{"label":"Matched Ads Opt-In","disabled":_vm.newCCPAOptIn || _vm.newTargetedAnalyticsOptOut},model:{value:(_vm.newDataOptIn),callback:function ($$v) {_vm.newDataOptIn=$$v},expression:"newDataOptIn"}})],1)]}}])},[_c('span',[_vm._v("Checked = Opted into Matched Ads")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',_vm._g({attrs:{"xs6":"","sm6":"","md6":"","lg6":"","box":"","pa6":"","mb-0":""}},on),[_c('v-checkbox',{attrs:{"label":"Do Not Sell"},model:{value:(_vm.newCCPAOptIn),callback:function ($$v) {_vm.newCCPAOptIn=$$v},expression:"newCCPAOptIn"}})],1)]}}])},[_c('span',[_vm._v("Checked = Opted into CCPA protection")])])],1)],1),_c('v-flex',{attrs:{"xs2":"","sm2":"","md2":"","lg2":""}},[_c('v-btn',{attrs:{"disabled":_vm.disableUpdate},on:{"click":_vm.confirm}},[_vm._v("Update")])],1),_c('v-flex',{attrs:{"xs1":"","sm1":"","md1":"","lg1":""}},[(_vm.error)?_c('v-icon',{staticClass:"has-error"},[_vm._v("report_problem")]):_vm._e()],1),_c('v-flex',{attrs:{"xs1":"","sm1":"","md1":"","lg1":""}},[(_vm.success)?_c('v-icon',[_vm._v("check_circle_outline")]):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":3000},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"dark":"","flat":""},on:{"click":function($event){_vm.snackbar.visible = false}}},[_vm._v("Close")])],1),_c('confirm-dialogue',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }