<template>
  <v-container>
    <genericDetails
      :id="domainID"
      itemName="Domain"
      redirectBack="/developer"
      :isNew="newDomain"
      :createNew="createDomain"
      :deleteOld="deleteDomain"
    >
      <template slot="form">
        <v-text-field
          label="Domain ID"
          v-model="domainID"
          :disabled="!newDomain"
        ></v-text-field>
        <v-textarea
          label="Description"
          v-model="description"
          rows="1"
          :disabled="!newDomain"
        />
      </template>
    </genericDetails>
    <genericTable
      itemName="Game"
      :headers="gameHeaders"
      :getItemsURL="`/admin/accounts/domain/${domainID}/game`"
      redirectTemplate="/developer/domain/${item.domainID}/game/${item.gameID}"
      :createRedirect="`/developer/domain/${domainID}/creategame`"
      :canCreate="!newDomain"
    />
    <genericTable
      itemName="Domain Role"
      :headers="roleHeaders"
      :getItemsURL="`/admin/accounts/domain/${domainID}/roles`"
      redirectTemplate="/developer/domain/${item.domainID}/role/${item.roleID}"
      :createRedirect="`/developer/domain/${domainID}/createRole`"
      :canCreate="!newDomain"
    />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import genericTable from './GenericTable.vue';
  import genericDetails from './GenericDetails.vue';

  export default {
    name: 'DomainDetails',
    props: ['domainID'],
    components: {
      genericTable,
      genericDetails,
    },
    data() {
      return {
        gameHeaders: [
          { text: 'Game ID', value: 'gameID' },
          { text: 'Description', value: 'description' },
        ],
        roleHeaders: [
          { text: 'Tag', value: 'tag' },
          { text: 'Description', value: 'description' },
        ],
        description: '',
        newDomain: false,
        error: '',
      };
    },
    created: function () {
      if (typeof this.domainID === 'undefined' || !this.domainID) {
        this.newDomain = true;
      } else {
        this.newDomain = false;
        this.getDetails();
      }
    },
    methods: {
      getDetails() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/accounts/domain`;
          axios
            .get(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              for (let i = 0; i < response.data.domains.length; i++) {
                if (response.data.domains[i].domainID == this.domainID) {
                  this.description = response.data.domains[i].description;
                  break;
                }
              }
            })
            .catch((error) => {
              this.description = '';
            });
        });
      },
      createDomain() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/domain`;
          let newDomain = {
            domainID: this.domainID,
            description: this.description,
          };
          axios
            .put(req, newDomain, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.newDomain = false;
              resolve(`/developer/domain/${response.data.domainID}`);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      deleteDomain() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/domain/${this.domainID}`;
          axios
            .delete(req, this.$store.getters.bearerAuthHeaders)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
  };
</script>
