<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex row wrap="false" xs8 sm8 md8 lg8 box pa8>
        <v-select
          label="Language"
          :items="supportedLanguages"
          item-text="display"
          item-value="value"
          v-model="newLanguage"
        />
      </v-flex>
      <v-flex row wrap="false" xs3 sm3 md3 lg3 box pa3>
        <v-btn :disabled="disableUpdate" @click="confirm">Update</v-btn>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1 v-if="error">
        <v-icon :title="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1 v-else-if="success">
        <v-icon>check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  export default {
    name: 'LanguageForm',
    data() {
      return {
        language: '',
        newLanguage: '',
        error: '',
        success: false,
        supportedLanguages: [
          { display: 'English', value: 'en-US' },
          { display: 'Spanish', value: 'es-ES' },
          { display: 'French', value: 'fr-FR' },
          { display: 'Italian', value: 'it-IT' },
          { display: 'Portuguese', value: 'pt-BR' },
          { display: 'German', value: 'de-DE' },
          { display: 'Russian', value: 'ru-RU' },
          { display: 'Japanese', value: 'ja-JP' },
          { display: 'Korean', value: 'ko-KR' },
          { display: 'Traditional Chinese', value: 'zh-TW' },
          { display: 'Simplified Chinese', value: 'zh-CN' },
        ],
      };
    },
    mounted() {
      this.language = this.getLanguage;
      this.newLanguage = this.getLanguage;
      if (this.language != undefined) {
        var result = this.supportedLanguages.filter((obj) => {
          return obj.value == this.language;
        });
        if (result.length == 0) {
          this.supportedLanguages.unshift({
            display: this.language,
            value: this.language,
          });
        }
      }
    },
    watch: {
      getLanguage: function () {
        this.newLanguage = this.getLanguage;
      },
    },
    methods: {
      confirm() {
        this.$refs.confirm
          .open(
            'Update Language',
            "Are you sure you want to update this account's langage?"
          )
          .then((confirm) => {
            if (confirm) {
              this.$store
                .dispatch({
                  type: 'updateLanguage',
                  newLanguage: this.newLanguage,
                })
                .then(() => {
                  this.error = false;
                  this.success = true;
                  this.language = this.newLanguage;
                })
                .catch((err) => {
                  this.error = err;
                  this.success = false;
                  this.newLanguage = this.language;
                });
            } else {
              this.newLanguage = this.language;
            }
          });
      },
    },
    computed: {
      getLanguage() {
        return this.$store.getters.account.language;
      },
      hasError() {
        return this.error.length > 0;
      },
      disableUpdate() {
        return this.language == this.newLanguage;
      },
    },
  };
</script>
