<template>
  <v-container pa-0>
    <v-layout row sm-12 md-12 lg-12>
      <a @click="showExample()" class="font-italic">View Example .CSV</a>
    </v-layout>
    <v-layout
      row
      sm-12
      md-12
      lg-12
      :class="example ? 'example show' : 'example'"
    >
      <slot />
    </v-layout>
    <v-layout row>
      <v-text-field
        label="Select .CSV (25MB limit)"
        @click="pickFile"
        v-model="fileName"
        prepend-icon="attach_file"
      ></v-text-field>
      <input
        type="file"
        style="display: none"
        ref="csv"
        accept=".csv"
        @change="onFilePicked"
      />
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name: 'CsvSelector',
    data() {
      return {
        fileName: '',
        example: false,
      };
    },
    methods: {
      showExample() {
        this.example = !this.example;
      },
      pickFile() {
        this.$refs.csv.click();
      },
      onFilePicked(evt) {
        let files = evt.target.files;

        if (files[0] !== undefined) {
          this.fileName = files[0].name;
          this.$emit('filePicked', files[0]);
        }
      },
    },
  };
</script>

<style scoped>
  .example {
    display: none;
  }
  .example.show {
    display: block;
  }
  .example div {
    border: 1px solid #ddd;
    background-color: #3f3f3f;
    border-radius: 5px;
    padding: 7px;
    font-family: Lucida Console, Lucida Sans Typewriter, monaco,
      Bitstream Vera Sans Mono, monospace;
    font-size: 11px;
  }
</style>
