<template>
  <v-container fluid class="grantListContainer">
    <v-layout column>
      <v-layout row>
        <v-toolbar
          flat
          dense
          height="35px"
          style="margin-top: 25px"
          color="transparent"
        >
          <v-toolbar-title>
            {{ this.$props.grantTypeName }} Grants
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              color="primary"
              outline
              v-if="!readonly && !edit"
              @click="addGrant"
            >
              New Grant
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-layout>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="localGrants"
          :must-sort="true"
          :hide-actions="true"
          :rows-per-page-items="[100]"
        >
          <template slot="no-data">
            <v-alert :value="true" color="info" icon="info" outline>
              No grants
            </v-alert>
          </template>
          <template slot="items" slot-scope="props">
            <template
              v-for="(value, key) in grantPrototype"
              v-if="key != '$type'"
            >
              <td :key="key" width="50%">
                <div v-if="readonly">{{ props.item[key] }}</div>
                <template v-else>
                  <v-edit-dialog
                    :return-value.sync="props.item[key]"
                    large
                    lazy
                    persistent
                  >
                    <div>{{ props.item[key] }}</div>
                    <v-text-field
                      slot="input"
                      v-model="props.item[key]"
                      :rules="[max25chars]"
                      label="Edit"
                      single-line
                      autofocus
                    ></v-text-field>
                  </v-edit-dialog>
                </template>
              </td>
            </template>
            <td style="max-width: 60px; width: 60px">
              <v-icon
                v-if="!readonly && !edit"
                @click="deleteGrant(props.item)"
              >
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name: 'v-grantlist',

    inheritAttrs: false,

    props: {
      grantTypeName: {
        type: String,
        required: true,
      },
      grants: {
        type: Array,
        required: true,
      },
      grantPrototype: {
        type: Object,
        required: true,
      },
      readonly: {
        default: true,
        type: Boolean,
      },
      updateGrants: {
        default: true,
        type: Function,
      },
      edit: {
        default: false,
        type: Boolean,
      },
    },

    data() {
      return {
        max25chars: (v) => v.length <= 25 || 'Input too long!',
        grantsStore: [],
      };
    },

    watch: {
      grants(newVal, oldVal) {
        this.grantsStore = newVal;
      },
      localGrants(newVal, oldVal) {
        this.updateGrants(newVal);
      },
    },

    methods: {
      addGrant() {
        var newGrant = Object.assign({}, this.grantPrototype);
        this.grants.push(newGrant);
      },

      deleteGrant(item) {
        const index = this.grants.indexOf(item);
        this.grants.splice(index, 1);
      },
    },

    computed: {
      localGrants() {
        return this.grantsStore;
      },

      headers() {
        let keys = Object.keys(this.grantPrototype);
        var hdrs = [];

        for (let key of keys) {
          if (key != '$type') {
            hdrs.push({
              text: key,
              align: 'left',
              sortable: false,
              value: key,
            });
          }
        }

        // Add a blank header for actions, just to keep the line looking right
        hdrs.push({ text: '', align: 'left', sortable: false, value: '' });

        return hdrs;
      },
    },
  };
</script>

<style scoped>
  .grantListContainer {
    padding: 0;
  }
</style>
