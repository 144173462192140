<template>
  <div>
    <v-layout row justify-left align-center fill-height class="wotcContainer">
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <h2>Persona History</h2>
        <v-data-table
          :items="personaHistoryItems"
          :headers="headers"
          hide-actions
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th>{{ headers[0].text }}</th>
              <th>{{ headers[1].text }}</th>
              <th>{{ headers[2].text }}</th>
              <th>{{ headers[3].text }}</th>
              <th>{{ headers[4].text }}</th>
              <th style="text-align: right">
                <v-btn
                  v-on:click="fetchPersonaHistoryItems()"
                  color="grey darken-3"
                  title="Reload Entitlements"
                >
                  <v-icon>cached</v-icon>
                </v-btn>
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.gameID }}</td>
              <td>{{ props.item.personaID }}</td>
              <td>{{ props.item.personaType }}</td>
              <td>{{ formatDate(props.item.removedOn) }}</td>
              <td>{{ props.item.reason }}</td>
              <td align="right">
                <v-btn
                  v-on:click="
                    confirmEdit(
                      props.item.accountID,
                      props.item.personaID,
                      props.item.gameID
                    )
                  "
                >
                  Restore
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-alert v-model="hasError">{{ getError }}</v-alert>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  import moment from 'moment';

  export default {
    name: 'personaHistory',

    data() {
      return {
        headers: [
          { text: 'Game ID', value: 'gameID' },
          { text: 'Persona ID', value: 'personaID' },
          { text: 'Type', value: 'personaType' },
          { text: 'Date', value: 'removedOn' },
          { text: 'Reason', value: 'reason' },
        ],
        error: '',
      };
    },
    watch: {
      accountID: function () {
        this.fetchPersonaHistoryItems();
      },
    },
    created: function () {
      this.fetchPersonaHistoryItems();
    },
    methods: {
      formatDate(isoDateString) {
        return moment.unix(isoDateString).format('MMM Do, YYYY @ h:mm a');
      },
      fetchPersonaHistoryItems() {
        if (typeof this.$store.getters.account.accountID != 'undefined') {
          this.$store.dispatch('fetchPersonaHistoryItems');
        }
      },
      confirmEdit(accountID, personaID, game) {
        let queryOpts = this.$store.getters.bearerAuthHeaders;
        this.$refs.confirm
          .open(
            'Confirm Restoring Persona',
            "Do you wish to restore this account's active persona to `" +
              personaID +
              '` for `' +
              game +
              '`?'
          )
          .then((confirm) => {
            this.setError('');
            if (confirm) {
              axios
                .post(
                  this.$store.getters.serviceURL +
                    '/admin/accounts/account/' +
                    accountID +
                    '/restore',
                  { personaID: personaID, gameID: game },
                  queryOpts
                )
                .then((response) => {
                  this.fetchPersonaHistoryItems();

                  axios
                    .get(
                      this.$store.getters.serviceURL +
                        '/admin/accounts/account/' +
                        accountID +
                        '/personas',
                      queryOpts
                    )
                    .then((response) => {
                      this.$store.commit('setPersonas', response.data.personas);
                      this.errorMsg = '';
                    })
                    .catch((err) => {
                      this.errorMsg = err;
                    });

                  axios
                    .get(
                      this.$store.getters.serviceURL +
                        '/profile/admin/persona/' +
                        personaID,
                      queryOpts
                    )
                    .then((response) => {
                      this.$store.commit('setProfile', response.data);
                      this.setError('');
                    })
                    .catch((err) => {
                      this.setError('Failed to Get Profile: ' + err);
                    });
                })
                .catch((err) => {
                  this.setError('Unable to update persona ID: ' + err);
                });
            }
          })
          .catch((err) => {
            this.setError('Unable to update persona ID: ' + err);
          });
      },
      setError(err) {
        this.error = '' + err;
      },
    },
    computed: {
      hasError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      accountID() {
        return this.$store.getters.account.accountID;
      },
      personaHistoryItems() {
        return this.$store.getters.personaHistory;
      },
    },
  };
</script>

<style scoped>
  table.v-table thead th {
    text-align: left;
  }
</style>
