<template>
  <div>
    <v-container v-if="!searchPopulated">
      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <v-flex xs6 sm6 md6 lg6 box pa6>
          <div>
            <form @submit.prevent="lookup">
              <h2>Search for Accounts</h2>
              <br />
              <p class="buttonList">
                <v-btn
                  small
                  v-for="method of findByMethods"
                  :color="isSelected(method)"
                  :key="method"
                  @click="setFindBy(method)"
                >
                  {{ method }}
                </v-btn>
              </p>
              <v-select :items="allowedDomains" v-model="domain" label="Domain">
              </v-select>
              <v-select
                :items="games"
                v-model="game"
                v-if="requireGame"
                label="Game"
              ></v-select>
              <v-text-field
                :label="findBy"
                type="text"
                :placeholder="getPlaceholder"
                v-model="identifier"
                @keyup.enter.native="lookup"
              ></v-text-field>
              <p v-if="isWildcardable" class="grey--text lighten-1">
                <em>
                  Enter
                  <b>*</b>
                  or partial search to search by wildcard, otherwise it will
                  expect a close match.
                </em>
              </p>
              <v-btn @click="lookup()">Lookup</v-btn>
              <p :if="isError">
                <br />
                <v-alert :value="isError" type="error">{{ getError }}</v-alert>
              </p>
            </form>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container v-else>
      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <v-flex class="search-area" xs12 sm12 md12 lg12 box pa12>
          <div class="closer">
            <v-btn @click="clearSearch()" flat title="Return to Search">
              &times;
            </v-btn>
          </div>
          <search-results
            :domain="domain"
            :exact="exactMatch"
            v-on:search-modify="lookup"
          ></search-results>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import SearchResults from './SearchResults.vue';
  import permissions from '../permissions';

  export default {
    name: 'find',
    data() {
      return {
        domainsList: [],
        allowedDomainList: [],
        games: [],
        findByMethods: ['Email', 'Display Name', 'Account ID', 'Persona ID'], // TODO: add search by reciept -> account/personal lookup bsed on result
        findByPlaceholder: {
          Email: '*wizards.com',
          'Display Name': '*tasslehoff*',
          'Account ID': '12345AZXH3SWFEVFQ7TPGK73D4',
          'Persona ID': '123453XJHCTHBH55MT2X2YTL7Y',
        },
        findBy: 'Email',
        game: '',
        identifier: '',
        error: '',
        exactMatch: false,
      };
    },
    props: {
      domainID: {
        type: String,
        default: null,
      },
    },
    mounted() {
      // fetch the data when the view is created and the data is already being observed
      this.$store.dispatch('getDomains');
      this.findBy = 'Email';

      if (this.$props.domainID) {
        this.domain = this.$props.domainID;
        this.$router.push(
          '/find' + '/' + encodeURIComponent(this.$props.domainID)
        );
      } else {
        this.$router.push('/find');
      }
      this.$store.commit('setAccount', {});
      this.$store.commit('setSearchResults', []);
      this.$store.commit('setSearchResultCount', 0);
      this.$store.commit('searchResultPage', 1);
    },
    watch: {
      domain: function (newDomain) {
        this.$router.push('/find' + '/' + encodeURIComponent(newDomain));
      },
    },
    methods: {
      fetchData() {
        axios
          .get(
            this.$store.getters.serviceURL + '/admin/accounts/domain',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.domainsList = response.data.domains.map((domain) => {
              return domain.domainID;
            });
            this.setError('');
          })
          .catch((err) => {
            this.setError('Failed to Pull Domain List: ' + err);
          });
      },
      getByEmailURL(es) {
        if (es === true || this.identifier.indexOf('*') != -1) {
          this.exactMatch = es === true;
          return (
            this.$store.getters.serviceURL +
            '/elasticsearch-proxy/email/search?domain_id=' +
            encodeURIComponent(this.domain) +
            '&term=' +
            encodeURIComponent(this.identifier)
          );
        } else {
          this.exactMatch = true;
          return (
            this.$store.getters.serviceURL +
            '/admin/accounts/domain/' +
            this.domain +
            '/email/' +
            encodeURIComponent(this.identifier)
          );
        }
      },
      getByDisplayNameURL(es) {
        if (es === true || this.identifier.indexOf('*') != -1) {
          this.exactMatch = es === true;
          return (
            this.$store.getters.serviceURL +
            '/elasticsearch-proxy/display_name/search?domain_id=' +
            encodeURIComponent(this.domain) +
            '&term=' +
            encodeURIComponent(this.identifier)
          );
        } else {
          this.exactMatch = true;
          return (
            this.$store.getters.serviceURL +
            '/admin/accounts/domain/' +
            this.domain +
            '/displayName/' +
            encodeURIComponent(this.identifier) +
            '/user'
          );
        }
      },
      getByAccountIDURL() {
        return (
          this.$store.getters.serviceURL +
          '/admin/accounts/account/' +
          this.identifier
        );
      },
      getByPersonaIDURL() {
        return (
          this.$store.getters.serviceURL +
          '/admin/accounts/user/persona/' +
          this.identifier
        );
      },
      lookup(es) {
        //trying to set last domain
        var url = '';
        if (this.findBy === 'Email') {
          url = this.getByEmailURL(es);
        } else if (this.findBy === 'Display Name') {
          url = this.getByDisplayNameURL(es);
        } else if (this.findBy === 'Account ID') {
          url = this.getByAccountIDURL();
        } else if (this.findBy === 'Persona ID') {
          url = this.getByPersonaIDURL();
        }
        let elasticSearch = false;
        if (es === true || this.identifier.indexOf('*') != -1) {
          elasticSearch = true;
        }

        axios
          .get(url, this.$store.getters.bearerAuthHeaders)
          .then((response) => {
            this.setError('');
            if (elasticSearch) {
              if ('hits' in response.data && 'hits' in response.data.hits) {
                let results = [];
                if (response.data.hits.hits.length == 0) {
                  this.setError('No results found...');
                } else {
                  results = response.data.hits.hits.map((obj, i) => {
                    return { score: i + 1, source: obj._source };
                  });
                }
                this.$store.commit('setSearchResults', results);
                this.$store.commit(
                  'setSearchResultCount',
                  response.data.hits.hits.length
                );
                this.$store.commit('searchResultPage', 1);
              }
            } else {
              this.$store.commit('setAccount', response.data);
              if ('accountID' in response.data) {
                // Legacy return structure
                if (response.data.accountID == null) {
                  this.lookup(true); // force another run thru with fuzzy search if bad return
                } else {
                  this.$router.push(
                    '/edit/' + this.domain + '/' + response.data.accountID
                  );
                }
              } else if ('User' in response.data) {
                // New return structure allowing external personas
                var id = null;
                var route = '/edit/';
                if ('Account' in response.data.User) {
                  id = response.data.User.Account.account.accountID;
                  route = '/edit/';
                } else if ('Persona' in response.data.User) {
                  id = response.data.User.Persona.personaID;
                  route = '/editexternal/';
                } else {
                  this.lookup(true); // force another run thru with fuzzy search if bad return
                }
                this.$router.push(route + this.domain + '/' + id);
              } else {
                this.setError('No results found...');
              }
            }
          })
          .catch((err) => {
            if (err.toString().indexOf('404') != -1) {
              if (!elasticSearch) {
                this.lookup(true); // force another run thru with fuzzy search if 404 on normal service
              } else {
                this.setError('No results found...');
              }
            } else {
              this.setError('Failed Lookup: ' + err);
            }
          });
      },
      setFindBy(option) {
        this.identifier = '';
        this.findBy = option;
      },
      isSelected(option) {
        if (option === this.findBy) {
          return 'blue-grey darken-3';
        }
      },
      clearSearch() {
        this.$store.commit('setSearchResults', []);
      },
      setError(err) {
        this.error = '' + err;
      },
    },
    computed: {
      domains() {
        let filteredDomains = [];

        for (let domain of this.domainsList) {
          let requiredPerm = {
            domainID: domain,
            resource: 'account_admin',
            access: permissions.flags.read,
          };

          if (this.$store.getters.testPermissions(requiredPerm)) {
            filteredDomains.push(domain);
          }
        }
        return filteredDomains;
      },
      isError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      getPlaceholder() {
        return this.findByPlaceholder[this.findBy];
      },
      isWildcardable() {
        return this.findBy === 'Email' || this.findBy === 'Display Name';
      },
      requireGame() {
        return false;
      },
      searchPopulated() {
        return this.$store.getters.searchResults.length > 0;
      },
      allowedDomains() {
        return this.$store.getters.allowedDomains;
      },
      domain: {
        get() {
          let allDomains = this.$store.getters.allowedDomains;
          if (this.$store.getters.lastDomain != '') {
            return this.$store.getters.lastDomain;
          } else if (allDomains.length > 0) {
            return allDomains[0];
          } else {
            return '';
          }
        },
        set(value) {
          this.$store.commit('setLastDomain', value);
        },
      },
    },
    components: { SearchResults },
  };
</script>

<style scoped>
  .buttonList button:first-child {
    margin-left: 0;
  }
  .search-area {
    position: relative;
  }
  .closer {
    position: absolute;
    top: 0;
    right: 0;
  }
  .closer button {
    min-width: 38px;
    padding: 0;
    margin: 0;
    font-size: 30px;
    font-weight: normal;
  }
</style>
