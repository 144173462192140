<template>
  <div>
    <v-container>
      <entitlements type="standalone"></entitlements>
    </v-container>
  </div>
</template>

<script>
  import Entitlements from './Entitlements.vue';

  export default {
    name: 'findEntitlement',

    components: { Entitlements },
  };
</script>
