<template>
  <v-container>
    <genericDetails
      :id="clientID"
      itemName="Client"
      :redirectBack="`/developer/domain/${domainID}/game/${gameID}`"
      :isNew="newClient"
      :createNew="createClient"
      :deleteOld="deleteClient"
    >
      <template slot="form">
        <v-layout row>
          <v-text-field label="Domain" v-model="domainIDCopy" disabled />
          <v-spacer />
          <v-text-field label="Game" v-model="gameIDCopy" disabled />
        </v-layout>
        <v-text-field
          label="Client ID"
          v-model="clientID"
          disabled
          placeholder="[generated by the server]"
        ></v-text-field>
        <v-layout>
          <v-textarea label="Description" v-model="description" rows="1" />
          <v-btn
            light
            @click="updateDescription"
            :disabled="!changedDescription"
          >
            Save
          </v-btn>
        </v-layout>
        <v-alert v-model="hasError">
          <v-layout row align-center>
            {{ error }}
            <v-spacer />
            <v-btn icon @click="error = ''"><v-icon small>close</v-icon></v-btn>
          </v-layout>
        </v-alert>
        <v-layout row>
          <v-combobox
            v-model="redirectURIs"
            label="Redirect URLs"
            chips
            clearable
            multiple
            append-icon=""
            hint="Press enter to add additional redirect URLs."
            persistent-hint
          >
            <template v-slot:selection="data">
              <v-chip
                :selected="data.selected"
                close
                @input="removeRedirectURI(data.item)"
              >
                <strong>{{ data.item }}</strong
                >&nbsp;
              </v-chip>
            </template>
          </v-combobox>
          <v-btn
            light
            @click="updateRedirectURIs"
            :disabled="!changedRedirectURIs"
          >
            Save
          </v-btn>
        </v-layout>
        <v-layout row>
          <v-select
            label="Roles"
            v-model="roles"
            multiple
            attach
            chips
            :items="gameRoles"
            item-text="tag"
            item-value="roleID"
            :disabled="newClient"
            prepend-icon="edit"
          />
          <v-btn light @click="updateRoles" :disabled="!changedRoles">
            Save
          </v-btn>
        </v-layout>
        <h2>Options</h2>
        <v-checkbox
          label="First Party: This client will be used by first party developers with access to additional PII (personally identifiable information)."
          v-model="firstParty"
          :disabled="!newClient"
        />
        <v-checkbox
          label="Is Secure: This client will be used in a SECURE backend, will not be exposed to end users, and may have admin permissions."
          v-model="isSecure"
          :disabled="!newClient"
        /><br />
        <v-dialog width="500" v-model="dialog" persistent v-if="newClient">
          <v-card>
            <v-card-title class="pb-0 pt-0 pr-2">
              Important Message!
              <v-spacer />
              <v-btn class="mr-0" icon @click="closeDialog">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p>
                This is the only time platform will show you this client secret,
                and it cannot be recovered if lost. Copy and securely store it.
              </p>
              <v-text-field label="Client ID" v-model="clientID" readonly />
              <v-text-field
                label="Secret"
                id="secretValue"
                v-model="clientSecret"
                readonly
              >
                <template v-slot:append>
                  <v-btn icon @click="copySecret">
                    <v-icon>file_copy</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--
        <hydra-client-details
          :clientID="this.clientID"
          :oauthClient="oauthClient"
          :clientIsSecure="isSecure"
          :redirectURIsMatches="redirectURIsMatches"
        >
        </hydra-client-details>
        -->
      </template>
    </genericDetails>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import genericDetails from './GenericDetails.vue';
  import HydraClientDetails from './HydraClientDetails.vue';

  export default {
    name: 'ClientDetails',
    components: { genericDetails, HydraClientDetails },
    props: ['clientID', 'gameID', 'domainID'],
    data() {
      return {
        gameIDCopy: this.gameID,
        domainIDCopy: this.domainID,
        clientSecret: '',
        oldDescription: '',
        description: '',
        roles: [],
        redirectURIs: [],
        firstParty: false,
        isSecure: false,
        newClient: false,
        hasError: false,
        error: '',
        dialog: false,
        gameRoles: [],
        changedRoles: false,
        changedRedirectURIs: false,
        changedDescription: false,
        hasLoaded: false, // only way to watch after intial data is loaded.
        oauthClient: '',
        redirectURIMatches: true,
      };
    },
    mounted: function () {
      if (typeof this.clientID === 'undefined' || !this.clientID) {
        this.newClient = true;
        this.getGameRoles(this.gameID);
      } else {
        this.newClient = false;
        this.getDetails();
      }
    },
    watch: {
      error: function (val) {
        if (val) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      },
      roles: function () {
        if (this.hasLoaded) {
          this.changedRoles = true;
        } else {
          this.hasLoaded = true;
        }
      },
      redirectURIs: function () {
        if (this.hasLoaded) {
          this.changedRedirectURIs = true;
        } else {
          this.hasLoaded = true;
        }
      },
      description: function () {
        this.changedDescription =
          this.oldDescription === this.description ? false : true;
      },
    },
    methods: {
      updateDomainID(domainID) {
        this.domainIDCopy = domainID;
      },
      updateGameID(gameID) {
        this.gameIDCopy = gameID;
      },
      getDetails() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/client/${this.clientID}`;
          axios
            .get(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.getGameRoles(response.data.gameID);
              this.updateGameID(response.data.gameID);
              this.updateDomainID(response.data.domainID);
              this.description = response.data.description;
              this.oldDescription = response.data.description;
              this.roles = response.data.roles;
              // Unpack the redirectURIs into an array since it gets returned as a string e.g. uri1,uri2,uri3
              this.redirectURIs = response.data.redirectURI
                ? response.data.redirectURI.split(',')
                : [];
              this.firstParty = response.data.firstParty;
              this.isSecure = response.data.isSecure;
              this.oauthClient = response.data.oauthClient;

              // this.$store.commit('setHydraClient', this.oauthClient);

              if (this.oauthClient) {
                this.redirectURIsMatches =
                  this.oauthClient.redirectURIs[0] === this.redirectURIs;
              }
            });
        });
      },
      getGameRoles(gameID) {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/accounts/game/${gameID}/roles`;
          axios
            .get(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.gameRoles = response.data.roles;
              this.error = '';
            })
            .catch((error) => {
              this.gameRoles = [];
              this.error = error;
            });
        });
      },
      updateRoles() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/client/${this.clientID}/roles`;
          let roles = { roleIDs: this.roles };
          axios
            .put(req, roles, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              // only added roles are included in the response, so leave the list as-is
              this.error = '';
              this.changedRoles = false;
            })
            .catch((error) => {
              this.error = error;
            });
        });
      },
      updateDescription() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/client/${this.clientID}`;
          let description = { description: this.description };
          axios
            .post(req, description, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.error = '';
              this.changedDescription = response.data.description;
              this.oldDescription = this.description;
            })
            .catch((error) => {
              this.error = error;
            });
        });
      },
      updateRedirectURIs() {
        let redirectString = this.redirectURIs.join(',');
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/client/${this.clientID}`;
          let redirectURIs = { redirectURI: redirectString };
          axios
            .post(req, redirectURIs, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.error = '';
              this.changedRedirectURIs = response.data.redirectURI;
            })
            .catch((error) => {
              this.error = error;
            });
        });
        console.log(this.redirectURIs);
      },
      createClient() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/client`;
          this.oauthClient = this.$store.getters.hydraClient;

          // The redirectURIs are currently stored in an array locally. They must be formatted as a string like this:
          //
          //      uri1,uri2,uri3
          //
          // a string of the URIs concatenated together and separated by commas without spaces

          const redirectURIString = this.redirectURIs
            ? this.redirectURIs.join(',')
            : [];

          // oauthClient is currently commented out as to not cause issues with creating legacy clients. Functionality is in the hydra-poc branch
          // in adminservice and will be recovered after PLAT-3763. This will be added back once Hydra clients are working.
          let newClient = {
            gameID: this.gameID,
            description: this.description,
            roles: this.roles,
            redirectURI: redirectURIString,
            firstParty: this.firstParty,
            isSecure: this.isSecure,
            // oauthClient: this.oauthClient,
          };

          axios
            .put(req, newClient, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.clientID = response.data.clientID;
              this.gameID = response.data.gameID;
              this.description = response.data.description;
              this.roles = response.data.roles;
              // Unpack the redirectURIs into an array since it gets returned as a string e.g. uri1,uri2,uri3
              this.redirectURIs = response.data.redirectURI
                ? response.data.redirectURI.split(',')
                : [];
              this.firstParty = response.data.firstParty;
              this.isSecure = response.data.isSecure;
              this.clientSecret = response.data.secret;
              this.oauthClient = response.data.oauthClient;
              this.dialog = true;
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      closeDialog() {
        this.dialog = false;
        this.$router.push(
          `/developer/domain/${this.domainID}/game/${this.gameID}/client/${this.clientID}`
        );
        this.newClient = false;
      },
      deleteClient() {
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/client/${this.clientID}`;
          axios
            .delete(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              resolve(`/developer/domain/${this.domainID}/game/${this.gameID}`);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      copySecret() {
        var el = document.getElementById('secretValue');
        el.select();
        document.execCommand('copy');
      },
      removeRedirectURI(uri) {
        this.redirectURIs = this.redirectURIs.filter((item) => item !== uri);
      },
    },
  };
</script>
