<template>
  <div>
    <v-layout v-if="pendingDelete" row justify-left align-center>
      <v-flex row wrap="false" xs4 sm4 md4 lg4 box pa4>
        <v-btn @click="undelete" color="yellow darken-3">
          Prevent Deletion
        </v-btn>
      </v-flex>
      <v-flex row wrap="false" xs8 sm8 md8 lg8 box pa8>
        Delete pending on: {{ pendingDate }}
      </v-flex>
    </v-layout>
    <v-layout v-else row justify-left align-center>
      <v-flex v-if="!success" row wrap="false" xs4 sm4 md4 lg4 box pa4>
        <v-btn @click="confirm" color="red darken-2">Delete Account</v-btn>
      </v-flex>
      <v-flex v-if="error" row wrap="false" xs8 sm8 md8 lg8 box pa8>
        <v-icon v-if="error" class="has-error">report_problem></v-icon>
        {{ errorString }}
      </v-flex>
      <v-flex v-else-if="success" row wrap="false" xs12 sm12 md12 lg12 box pa12>
        <p class="tall">Deletion submitted, check back to verify completion.</p>
      </v-flex>
      <v-flex v-else row wrap="false" xs6 sm6 md6 lg6 box pa6>
        <v-text-field
          :label="label"
          type="text"
          :error="inputHasError()"
          v-model="comment"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'delete',
    data() {
      return {
        pendingDate: '', // TODO: There needs to be an API or field on the Account Rec for this...
        pendingDelete: false, //       Until then the undelete feature will be dormant on the UI
        error: false,
        errorString: '',
        success: false,
        comment: '',
        hasError: false,
        label: 'Deletion Comment',
      };
    },
    methods: {
      confirm() {
        if (this.comment === null || this.comment.length == 0) {
          this.hasError = true;
          this.label = 'Deletion Comment Required';
          return;
        } else {
          this.hasError = false;
          this.label = 'Deletion Comment';
        }

        this.$refs.confirm
          .open(
            'Initiate Account Deletion',
            'Are you sure you want to delete this account?  This will happen IMMEDIATELY and is permanent.',
            {
              verification: [
                {
                  id: 'checkInfo',
                  kind: 'checkbox',
                  label: 'The user has requested this directly.',
                  valid: true,
                },
                {
                  id: 'checkInfo',
                  kind: 'checkbox',
                  label:
                    "I have verified this user's email address, first name (if available), and last name (if available).",
                  valid: true,
                },
                {
                  id: 'verifyDisplayName',
                  kind: 'text',
                  label: "Enter user's display name to confirm",
                  valid: this.displayName,
                },
              ],
              confirm: 'Delete',
              color: 'red darken-2',
              width: 350,
            }
          )
          .then((confirm) => {
            if (confirm) {
              this.submit(true, this.comment);
            } else {
              //no-op
            }
          });
      },
      undelete() {
        this.$refs.confirm
          .open(
            'Prevent Deletion',
            'Are you sure you want to prevent the deletion of this account?',
            {
              verification: [
                {
                  id: 'checkInfo',
                  kind: 'checkbox',
                  label: 'The user has requested this directly.',
                  valid: true,
                },
                {
                  id: 'checkInfo',
                  kind: 'checkbox',
                  label:
                    "I have verified this user's email address, first name (if available), and last name (if available).",
                  valid: true,
                },
                {
                  id: 'verifyDisplayName',
                  kind: 'text',
                  label: "Enter user's display name to confirm",
                  valid: this.displayName,
                },
              ],
              confirm: 'Prevent Deletion',
              color: 'yellow darken-3',
              width: 350,
            }
          )
          .then((confirm) => {
            if (confirm) {
              this.submit(false);
            } else {
              //no-op
            }
          });
      },
      submit(del, comment) {
        let opts = this.$store.getters.bearerAuthHeaders;

        if (comment != '') {
          opts['data'] = { comment: comment };
        }

        this.success = false;
        this.error = false;
        this.errorString = '';

        if (del) {
          axios
            .delete(this.endpoint, opts)
            .then((response) => {
              this.success = true;
              this.comment = '';
            })
            .catch((err) => {
              console.error(Object.assign({}, err));
              this.error = true;
              this.errorString = 'The request failed.';
            });
        } else {
          // this is a future feature, not yet hooked up
          // axios.post(this.$store.getters.restoreAccountURL, opts)
          //   .then(response => {
          //     this.success = true;
          //     this.comment = "";
          //   })
          //   .catch(err => {
          //     console.error(Object.assign({}, err));
          //     this.error = true;
          //     this.errorString = "The request failed.";
          //   });
        }
      },
      inputHasError() {
        return this.hasError;
      },
    },
    computed: {
      accountID() {
        return this.$store.getters.account.accountID;
      },
      displayName() {
        return this.$store.getters.account.displayName;
      },
      endpoint() {
        // returns either the account or the external persona deletion endpoint, which includes the accountID or external personaID.
        return this.$store.getters.deleteAccountURL;
      },
    },
  };
</script>

<style scoped>
  .tall {
    line-height: 50px;
    margin-bottom: 0;
  }
</style>
