<template>
  <v-dialog v-model="show" width="1000">
    <v-card>
      <v-card-title>
        <h2>Audit Trail Details</h2>
      </v-card-title>
      <v-card-text>
        <table>
          <tr>
            <td>ID of Target</td>
            <td>{{ details ? details.TargetID : '' }}</td>
          </tr>
          <tr>
            <td>Actor</td>
            <td>
              <span v-if="details && details.ActorClient">
                {{ 'Client: ' + details.ActorClient }}
                <br />
              </span>
              <span v-if="details && details.ActorAccount">
                {{ 'Account: ' + details.ActorAccount }}
                <br />
              </span>
              <span v-if="details && details.ActorPersona">
                {{ 'Persona: ' + details.ActorPersona }}
                <br />
              </span>
            </td>
          </tr>
          <tr>
            <td>IP Address</td>
            <td>{{ details ? details.IPAddress : '' }}</td>
          </tr>
          <tr>
            <td>Geolocation</td>
            <td v-if="details && details.Geolocation">
              <span v-for="(v, k) in JSON.parse(details.Geolocation)">
                {{ k + ': ' + v }}
                <br />
              </span>
            </td>
          </tr>
          <tr>
            <td>HTTP Action</td>
            <td>
              {{ details ? details.HTTPVerb + ' ' + details.HTTPPath : '' }}
            </td>
          </tr>
          <tr>
            <td>Service Route</td>
            <td>{{ details ? details.ServiceRoute.join(', ') : '' }}</td>
          </tr>
          <tr>
            <td>Action</td>
            <td>{{ details ? details.Action.Context : '' }}</td>
          </tr>
          <tr>
            <td>Old Values</td>
            <td v-if="details">
              <span v-for="(v, k) in details.Action.OldValues">
                {{ k + ': ' + v }}
                <br />
              </span>
            </td>
          </tr>
          <tr>
            <td>New Values</td>
            <td v-if="details">
              <span v-for="(v, k) in details.Action.NewValues">
                {{ k + ': ' + v }}
                <br />
              </span>
            </td>
          </tr>
          <tr>
            <td>Comment</td>
            <td>
              <span>{{ details ? details.Comment : '' }}</span>
            </td>
          </tr>
          <tr>
            <td>Timestamp</td>
            <td>
              {{
                details
                  ? details.Timestamp.replace('T', ' ').replace('Z', '')
                  : ''
              }}
            </td>
          </tr>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn @click="show = false">Close</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AuditDetails',
    props: {
      details: {},
      value: Boolean,
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>

<style scoped>
  td:first-child {
    text-align: right;
    vertical-align: top;
    color: gray;
    padding-right: 5px;
    white-space: nowrap;
  }
  td:last-child {
    white-space: pre-wrap;
  }
</style>
