<template lang="html">
  <v-dialog
    v-model="showing"
    overlay
    persistent
    scrollable
    transition="dialog-transition"
  >
    <v-btn slot="activator" color="gray">Add New</v-btn>
    <v-container>
      <v-card min-height="400">
        <v-toolbar>
          <v-toolbar-title>New Batch</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon v-on:click="hide">
            <v-icon color="red">close</v-icon>
          </v-btn>
        </v-toolbar>
        <BatchCreationForm
          v-if="!createdBatch"
          :offerID="this.offerID"
          v-on:close="hide()"
          v-on:created="onCreated"
        />
        <BatchCreationPoller
          v-else
          v-on:close="hide"
          :initalBatchDetails="batchDetails"
          :offerID="this.offerID"
        />
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script lang="js">

    import BatchCreationForm from './BatchCreationForm.vue'
    import BatchCreationPoller from './BatchCreationPoller.vue'

    export default  {
      name: 'batch-creation',
      props: ['offerID'],
      components: {
        BatchCreationForm,
        BatchCreationPoller,
      },
      mounted() {
      },
      data() {
        return {
          showing: false,
          createdBatch: false,
          batchDetails: {
            id: '',
            tag: '',
            totalCodes: 1, //default to 1 to prevent divide by 0
            domainID: '',
            availableCodes: 0,
          },
        }
      },
      methods: {
        hide() {
          this.showing = false;
          this.$store.dispatch('fetchRedemptionBatchesForOffer', this.offerID)
          //reset batch details
          this.createdBatch = false
        },
        onCreated(batchDetails) {
          this.batchDetails = batchDetails;
          this.createdBatch = true;
        }
      },
      computed: {
      }
  }
</script>

<style scoped>
  .background {
    background-color: gray;
  }
</style>
