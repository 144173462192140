<template>
  <v-container>
    <h3>Epic Online Services Credentials</h3>
    <v-spacer />
    <v-text-field
      label="Epic Client ID"
      v-model="epiconlineservicesClientID"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Client Secret"
      v-model="epiconlineservicesClientSecret"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Deployment ID"
      v-model="epiconlineservicesDeploymentID"
      :disabled="formDisabled()"
    />
  </v-container>
</template>

<script>
  export default {
    name: 'EpicClientForm',
    props: [
      'gameIdentityProviderID',
      'formData',
      'savedFormData',
      'updateInProgress',
    ],
    data() {
      return {
        epiconlineservicesClientID: '',
        epiconlineservicesClientSecret: '',
        epiconlineservicesDeploymentID: '',
      };
    },
    mounted: function () {
      if (this.formData != '') {
        this.epiconlineservicesClientID = this.formData.epiconlineservicesClientID;
        this.epiconlineservicesClientSecret = this.formData.epiconlineservicesClientSecret;
        this.epiconlineservicesDeploymentID = this.formData.epiconlineservicesDeploymentID;
      }
    },
    watch: {
      epiconlineservicesClientID: {
        handler() {
          this.formUpdated();
        },
      },
      epiconlineservicesClientSecret: {
        handler() {
          this.formUpdated();
        },
      },
      epiconlineservicesDeploymentID: {
        handler() {
          this.formUpdated();
        },
      },
    },
    methods: {
      formDisabled() {
        //Ensures that when an update is canceled, the form reverts to the saved data, but also that we don't save blank credentials
        if (this.gameIdentityProviderID !== '' && !this.updateInProgress) {
          this.epiconlineservicesClientID = this.savedFormData.epiconlineservicesClientID;
          this.epiconlineservicesClientSecret = this.savedFormData.epiconlineservicesClientSecret;
          this.epiconlineservicesDeploymentID = this.savedFormData.epiconlineservicesDeploymentID;
        }
        return this.gameIdentityProviderID !== '' && !this.updateInProgress;
      },
      formUpdated() {
        const credentials = {
          epiconlineservicesClientID: this.epiconlineservicesClientID,
          epiconlineservicesClientSecret: this.epiconlineservicesClientSecret,
          epiconlineservicesDeploymentID: this.epiconlineservicesDeploymentID,
        };
        this.$emit('formUpdated', credentials);
      },
    },
  };
</script>
