import Vuex from 'vuex';
import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import config from './config';
import _ from 'lodash';
import permissions from './permissions';
Vue.use(Vuex);

const permissionFlags = {
  create: 1,
  read: 2,
  update: 4,
  delete: 8,
};

const authStore = {
  state: {
    auth: {
      access_token: '',
      refresh_token: '',
      nextRefreshDate: moment(),
    },
    authURL: config.authURL,
    basicAuthHeader: 'Basic ' + config.basicAuthHeader,
    permissions: {
      'wotc-prms': [],
    },
    domains: [],
    allowedDomains: [],
    allowedUpdateDomains: [],
    allowedReadDomains: [],
  },
  getters: {
    authURL: (state) => {
      return state.authURL;
    },
    basicAuthHeader: (state) => {
      return state.basicAuthHeader;
    },
    domains: (state) => state.domains,
    allowedDomains: (state) => state.allowedDomains,
    getAllowedUpdateDomains: (state) => {
      return state.allowedUpdateDomains;
    },
    getAllowedReadDomains: (state) => {
      return state.allowedReadDomains;
    },
    authenticated: (state) => moment().isBefore(state.auth.nextRefreshDate),
    auth: (state) => state.auth,
    token: (state) => {
      return state.auth.access_token;
    },
    refreshToken: (state) => {
      return state.auth.refresh_token;
    },
    bearerAuthHeaders: (state) => {
      return {
        headers: {
          Authorization: 'Bearer ' + state.auth.access_token,
        },
      };
    },
    fileOpts: (state) => {
      return {
        headers: {
          Authorization: 'Bearer ' + state.auth.access_token,
          'Content-type': 'multipart/form-data',
        },
      };
    },
    testPermission: (state) => (requiredPerm) => {
      return testPermissions(requiredPerm, state.permissions);
    },
    permissionTo: (state) => (specific, action) => {
      for (let perm of state.permissions['wotc-prms']) {
        let permValues = perm.split(':');
        if (hasPermission(permValues, permValues, specific, action)) {
          return true;
        }
      }

      return false;
    },
    permissionGeneral: (state) => (general, action) => {
      for (let perm of state.permissions['wotc-prms']) {
        let permValues = perm.split(':');

        if (permValues.length > 1) {
          let permGeneral = permValues[1].split('/');
          if (hasPermission(permGeneral, permValues, general, action)) {
            return true;
          }
        }
      }
      return false;
    },
  },
  mutations: {
    setAuth(state, authInfo) {
      state.auth.access_token = authInfo.access_token;
      state.auth.refresh_token = authInfo.refresh_token;
      state.auth.nextRefreshDate = moment().add(authInfo.expires_in, 'seconds');
      let permissions = parseJwt(authInfo.access_token);
      if (Object.keys(permissions).length !== 0) {
        state.permissions = permissions;
      }
    },
    setDomains(state, domainsList) {
      state.domains = domainsList;
      state.allowedDomains = _.filter(domainsList, (domain) => {
        let requiredPerm = {
          domainID: domain,
          resource: 'account_admin',
          access: permissions.flags.read,
        };
        return testPermissions(requiredPerm, state.permissions);
      });

      state.allowedUpdateDomains = _.filter(domainsList, (domain) => {
        let requiredPerm = {
          domainID: domain,
          resource: 'accounts',
          access: permissions.flags.read | permissions.flags.update,
        };
        return testPermissions(requiredPerm, state.permissions);
      });

      state.allowedReadDomains = _.filter(domainsList, (domain) => {
        let requiredPerm = {
          domainID: domain,
          resource: 'accounts',
          access: permissions.flags.read,
        };
        return testPermissions(requiredPerm, state.permissions);
      });
    },
  },
  actions: {
    refreshToken(ctx) {
      const params = new URLSearchParams();
      const token = ctx.getters.auth.refresh_token;
      params.append('grant_type', 'refresh_token');
      params.append('refresh_token', token);

      return axios({
        method: 'post',
        url: `${config.authURL}${'/auth/oauth/token'}`,
        data: params,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: ctx.getters.basicAuthHeader,
        },
      }).then((response) => {
        ctx.commit('setAuth', response.data);
        return ctx.getters.auth;
      });
    },
    refreshIfNeeded(ctx) {
      let nextRefreshDate = ctx.getters.auth.nextRefreshDate;
      if (moment().isBefore(nextRefreshDate)) {
        //no need for refresh
        return Promise.resolve();
      } else {
        return ctx.dispatch('refreshToken');
      }
    },
    login(context, userInput) {
      const params = new URLSearchParams();
      params.append('grant_type', 'password');
      params.append('username', userInput.email);
      params.append('password', userInput.password);

      return axios({
        method: 'post',
        url: `${config.authURL}${'/auth/oauth/token'}`,
        data: params,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: context.getters.basicAuthHeader,
        },
      }).then((response) => {
        // success callback
        context.commit('setAuth', response.data);
        return context.getters.auth;
      });
    },
    getDomains({ getters, dispatch, commit }) {
      return axios
        .get(
          getters.serviceURL + '/admin/accounts/domain',
          getters.bearerAuthHeaders
        )
        .then((response) => {
          commit(
            'setDomains',
            response.data.domains.map((domain) => {
              return domain.domainID;
            })
          );
        });
    },
    permissionAllowed({ getters, dispatch, commit }, payload) {
      if (payload.general) {
        return getters.permissionGeneral(payload.permission, payload.action);
      }
      return getters.permissionTo(payload.permission, payload.action);
    },
  },
};

export default authStore;

function testPermissions(requiredPerm, jwt) {
  for (let permStr of jwt['wotc-prms']) {
    let actualPerm = permissions.parse(permStr);
    //if the permission string doesn't match our regex, abandon ship
    if (_.isNil(actualPerm)) {
      continue;
    }

    if (permissions.check(requiredPerm, actualPerm)) {
      return true;
    }
  }
  //We've gone through all the perms and they don't have permission
  return false;
}

function parseJwt(token) {
  let tokenSplit = token.split('.');
  return tokenSplit.length > 1 ? parseJwtChunk(tokenSplit[1]) : {};
}

function parseJwtChunk(rawData) {
  let base64 = rawData.replace(/-/g, '+').replace(/_/g, '/');
  let parsed = {};

  try {
    parsed = JSON.parse(atob(base64));
  } catch (err) {
    console.error(err);
  }

  return parsed;
}

function getDomainsFromPermissions(permissions) {
  let domains = [];
  let wotcPerms = permissions['wotc-prms'];
  let keys = Object.keys(wotcPerms);

  for (let permKey of keys) {
    let parts = wotcPerms[permKey].split(':');

    if (parts.length > 1) {
      let domain = parts[1].split('/');

      if (domain.length > 1 && domain[1] === 'account_admin') {
        domains.push(domain[0]);
      }
    }
  }

  return domains;
}

// Split the permissions from the JWT and determine which domains we have update permissions in.
function getDomainsWithUpdatePermissions(permissions) {
  return buildDomainListFromPermission(permissions, permissionFlags['update']);
}

// Split the permissions from the JWT and determine which domains we have read permissions in.
function getDomainsWithViewPermissions(permissions) {
  return buildDomainListFromPermission(permissions, permissionFlags['read']);
}

// Given the keys and the permissions we are looking for, parse through the permissions.
// d:<domain id>[.<game id>]/<resource>[/<target id>]:<access>
function buildDomainListFromPermission(permissions, requiredPerms) {
  let domainList = [];
  let wotcPerms = permissions['wotc-prms'];
  let keys = Object.keys(wotcPerms);

  for (let permKey of keys) {
    let parts = wotcPerms[permKey].split(':');

    if (parts.length > 1) {
      let domain = parts[1].split('/');

      if (domain.length > 1 && domain[1] === 'accounts') {
        let accessPerm = parseInt(parts[2]);

        // Make sure there are permissions to UPDATE the account in the domain.
        if ((accessPerm & requiredPerms) === requiredPerms) {
          domainList.push(domain[0]);
        }
      }
    }
  }

  return domainList;
}

function hasPermission(currentPerm, permValues, permission, action) {
  if (currentPerm.length > 1 && currentPerm[1] === permission) {
    if (action) {
      if (permValues.length > 2) {
        let p = parseInt(permValues[2]);
        let required = permissionFlags[action];
        if ((p & required) === required) {
          return true;
        }
      }
    }
    return true;
  }
  return false;
}
