var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('GenericLoadingScreen',{attrs:{"isLoading":_vm.processing}}),_c('v-layout',{staticClass:"wotcContainer",attrs:{"row":"","justify-center":"","align-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","box":"","pa12":""}},[_c('div',{attrs:{"if":_vm.isSuccess}},[_c('v-alert',{attrs:{"value":_vm.isSuccess,"type":"success","dismissible":""}},[_vm._v(" "+_vm._s(this.success)+" ")])],1),_c('div',{attrs:{"if":_vm.isError}},[_c('v-alert',{attrs:{"value":_vm.isError,"type":"error","dismissible":""}},[_vm._v(" "+_vm._s(this.error)+" ")])],1),_c('v-container',[_c('v-layout',{attrs:{"align-center":"","pb-4":""}},[_c('v-flex',[_c('h2',[(!_vm.bulkMode)?_c('b',[_vm._v("Create Accounts")]):_c('b',[_vm._v("Create Accounts In Bulk via .CSV")])])]),_c('v-flex',[_c('v-switch',{staticStyle:{"float":"right"},attrs:{"label":"Bulk Mode"},model:{value:(_vm.bulkMode),callback:function ($$v) {_vm.bulkMode=$$v},expression:"bulkMode"}})],1)],1),_c('v-layout',{attrs:{"row":"","justify-left":"","pb-5":""}},[_c('DomainsSelector',{on:{"domainsSelected":function (newDomain) {
                  _vm.formValues.domainsSelected = newDomain;
                }}})],1),_c('v-layout',{attrs:{"row":"","justify-left":"","pb-5":""}},[_c('DomainRolesSelector',{attrs:{"domain":_vm.formValues.domainsSelected},on:{"newRoles":function (newRoles) {
                  _vm.formValues.domainRolesSelected = newRoles;
                }}})],1),_c('v-layout',{attrs:{"row":"","justify-left":""}},[_c('GameRolesSelector',{attrs:{"domains-selected":_vm.formValues.domainsSelected},on:{"gameRoleUpdate":function (newRoles) {
                  _vm.formValues.gamesSelected = newRoles;
                }}})],1)],1),_c('v-layout',{attrs:{"row":"","justify-left":""}},[(!_vm.bulkMode)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","box":"","pa12":""}},[_c('v-container',[_c('v-form',{ref:"manual",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[(_vm.processing)?_c('v-progress-linear',{attrs:{"height":"3","indeterminate":true}}):_vm._e(),_c('br'),_c('v-layout',{attrs:{"row":"","justify-left":""}},[_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","lg6":"","box":"","pa6":""}},[_c('v-text-field',{staticClass:"spaced",attrs:{"label":"Display Name","rules":[
                        _vm.rules.required,
                        _vm.rules.nameShort,
                        _vm.rules.nameLong ],"disabled":_vm.bulkMode},model:{value:(_vm.formValues.displayName),callback:function ($$v) {_vm.$set(_vm.formValues, "displayName", $$v)},expression:"formValues.displayName"}})],1),_c('v-flex',{attrs:{"xs3":"","sm3":"","md3":"","lg3":"","box":"","pa3":""}},[_c('v-text-field',{staticClass:"spaced",attrs:{"label":"First Name","disabled":_vm.bulkMode},model:{value:(_vm.formValues.firstName),callback:function ($$v) {_vm.$set(_vm.formValues, "firstName", $$v)},expression:"formValues.firstName"}})],1),_c('v-flex',{attrs:{"xs3":"","sm3":"","md3":"","lg3":"","box":"","pa3":""}},[_c('v-text-field',{attrs:{"label":"Last Name","disabled":_vm.bulkMode},model:{value:(_vm.formValues.lastName),callback:function ($$v) {_vm.$set(_vm.formValues, "lastName", $$v)},expression:"formValues.lastName"}})],1)],1),_c('v-layout',{attrs:{"row":"","justify-left":""}},[_c('v-flex',{attrs:{"xs9":"","sm9":"","md9":"","lg9":"","box":"","pa9":""}},[_c('v-text-field',{staticClass:"spaced",attrs:{"label":"Email Address","rules":[_vm.rules.required, _vm.rules.emailValid],"disabled":_vm.bulkMode},model:{value:(_vm.formValues.emailAddress),callback:function ($$v) {_vm.$set(_vm.formValues, "emailAddress", $$v)},expression:"formValues.emailAddress"}})],1),_c('v-flex',{attrs:{"xs3":"","sm3":"","md3":"","lg3":"","box":"","pa3":""}},[_c('v-text-field',{attrs:{"label":"Country","rules":[_vm.rules.required],"disabled":_vm.bulkMode,"mask":"AA"},model:{value:(_vm.formValues.country),callback:function ($$v) {_vm.$set(_vm.formValues, "country", $$v)},expression:"formValues.country"}})],1)],1),_c('v-layout',{attrs:{"row":"","justify-left":""}},[_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","lg6":"","box":"","pa6":""}},[_c('v-text-field',{staticClass:"spaced",attrs:{"append-icon":_vm.show1 ? 'visibility' : 'visibility_off',"type":_vm.show1 ? 'text' : 'password',"rules":[
                        _vm.rules.required,
                        _vm.rules.passwordComplexity,
                        _vm.rules.passwordShort,
                        _vm.rules.passwordLong ],"label":"Password","disabled":_vm.bulkMode},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.formValues.password1),callback:function ($$v) {_vm.$set(_vm.formValues, "password1", $$v)},expression:"formValues.password1"}})],1),_c('v-flex',{attrs:{"xs6":"","sm6":"","md6":"","lg6":"","box":"","pa6":""}},[_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'visibility' : 'visibility_off',"type":_vm.show2 ? 'text' : 'password',"rules":[_vm.rules.required, _vm.rules.match],"label":"Confirm Password","disabled":_vm.bulkMode},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.formValues.password2),callback:function ($$v) {_vm.$set(_vm.formValues, "password2", $$v)},expression:"formValues.password2"}})],1)],1)],1)],1)],1):_vm._e(),(_vm.bulkMode)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","box":"","pa12":""}},[_c('v-container',[_c('csv-selector',{on:{"filePicked":function (newFile) {
                    this$1.formValues.accountsFile = newFile;
                  }}},[_c('div',[_vm._v(" \"display_name\",\"first_name\",\"last_name\",\"email_address\",\"password\",\"country\" "),_c('br'),_vm._v(" \"UserName0\",\"User\",\"Name0\",\"email0@wizards.com\",\"qwerty12340\",\"US\" "),_c('br'),_vm._v(" \"UserName1\",\"User\",\"Name1\",\"email1@wizards.com\",\"qwerty12341\",\"US\" "),_c('br')])])],1)],1):_vm._e()],1),_c('p',{staticClass:"pl-3"},[(!_vm.bulkMode)?_c('v-btn',{attrs:{"disabled":!_vm.canSubmitForm},on:{"click":_vm.create}},[_vm._v(" Create ")]):_c('v-btn',{attrs:{"disabled":!_vm.canSubmitForm || _vm.processing},on:{"click":function($event){$event.stopPropagation();return _vm.create($event)}}},[_vm._v(" Create ")]),_c('Poller',{attrs:{"showing":_vm.dialog,"initialBulkChangeDetails":_vm.bulkRegisterDetails,"ready":_vm.processing,"isCreate":true},on:{"create":_vm.create,"hide":function($event){return _vm.resetForm()},"done":function($event){return _vm.resetForm()}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }