<template>
  <div>
    <v-layout row justify-left align-center fill-height>
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <h2>Clients and Scopes</h2>
        <v-data-table :headers="headers" :items="grantedConsent">
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.uri }}</td>
            <td>
              <v-chip v-for="scope in props.item.scopes" :key="scope">{{
                scope
              }}</v-chip>
            </td>
            <td>{{ props.item.date }}</td>
            <td align="right">
              <v-btn v-on:click="revoke(props.item)">Revoke</v-btn>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'clientsAndScopes',
    data() {
      return {
        headers: [
          { text: 'Client ID', value: 'id' },
          { text: 'Client Description', value: 'name' },
          { text: 'Client URI', value: 'uri' },
          { text: 'Scopes Granted', value: 'scopes' },
          { text: 'Date Granted', value: 'date' },
        ],

        consentSessions: [],
        error: '',
      };
    },
    mounted() {
      this.getHydraConsent();
    },
    methods: {
      getHydraConsent() {
        this.$store
          .dispatch('getHydraConsentSessions')
          .then((response) => {
            this.consentSessions = this.parseHydraConsentData(
              response.consentSessions
            );
          })
          .catch((err) => {
            this.setError(err);
          });
      },

      parseHydraConsentData(data) {
        let sessionData = [];

        for (let i = 0; i < data.length; i++) {
          let session = data[i];
          sessionData.push({
            id: session.clientID,
            name: session.clientName,
            uri: session.clientURI,
            scopes: session.grantScopes,
            date: session.handledAt,
          });
        }

        return sessionData;
      },

      /**
       * Revokes the user's consent for all scopes and claims granted to
         the given client
       */
      revoke(clientItem) {
        this.$refs.confirm
          .open(
            'Confirm Revoke',
            `Do you wish to revoke this user's consent given to ` +
              clientItem.name +
              `? This means the user will need to grant consent ` +
              `again if the client wishes to access this data.`
          )
          .then((confirm) => {
            this.setError('');

            if (confirm) {
              this.$store
                .dispatch('revokeHydraConsent', {
                  clientID: clientItem.id,
                })
                .then((_) => {
                  // Empty response expected on success - 204 or 201

                  // Reload consent sessions because more than one session could
                  // be affected
                  // NOTE: ticket to capture UX improvements:
                  // https://wotc.atlassian.net/browse/PLAT-4282
                  this.getHydraConsent();
                })
                .catch((err) => {
                  this.setError('Unable to revoke consent: ' + err);
                });
            }
          })
          .catch((err) => {
            this.setError(err);
          });
      },

      setError(err) {
        this.error = '' + err;
      },
    },
    computed: {
      /**
       * Retrieves the user's current consent data, including the granted
         clients and scopes from Hydra
       */
      grantedConsent() {
        return this.consentSessions;
      },
    },
  };
</script>
