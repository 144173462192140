<template>
  <div>
    <v-layout row justify-left align-center fill-height class="wotcContainer">
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <h2>Domain Roles</h2>
        <v-data-table :headers="headers" :items="roles" hide-actions>
          <template slot="headers" slot-scope="props">
            <tr>
              <th>Role Tag</th>
              <th>Description</th>
              <th>DomainID</th>
              <th>RoleID</th>
              <th />
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.tag }}</td>
              <td>{{ props.item.description }}</td>
              <td>{{ props.item.domainID }}</td>
              <td>{{ props.item.roleID }}</td>
              <td align="right">
                <v-btn v-on:click="confirmRemove(props.item)">Remove</v-btn>
              </td>
            </tr>
          </template>
          <template slot="footer">
            <tr>
              <td colspan="3">
                <v-autocomplete
                  :items="allDomainRoleTags"
                  v-model="toAddTag"
                  label="Add Domain Role"
                  auto-select-first
                ></v-autocomplete>
              </td>
              <td>
                <v-btn
                  v-on:click="loadRoles()"
                  color="grey darken-3"
                  title="Reload Domain Role List"
                >
                  <v-icon>cached</v-icon>
                </v-btn>
              </td>
              <td align="right"><v-btn @click="confirmAdd">Add</v-btn></td>
            </tr>
          </template>
        </v-data-table>
        <v-alert v-model="hasError">{{ getError }}</v-alert>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';

  export default {
    name: 'rolesForm',
    data() {
      return {
        headers: [
          { text: 'Role tag' },
          { text: 'Description' },
          { text: 'DomainID' },
          { text: 'RoleID' },
        ],
        allDomainRoles: [],
        localRoles: [],
        toAddTag: '',
        error: '',
      };
    },
    created: function () {
      this.loadRoles();
    },
    mounted() {
      this.localRoles = this.$store.getters.account.roles;
    },
    watch: {
      getDomainID: function () {
        this.loadRoles();
      },
    },
    methods: {
      loadRoles() {
        if (typeof this.$store.getters.account.domainID != 'undefined') {
          axios
            .get(
              this.$store.getters.serviceURL +
                '/admin/accounts/domain/' +
                this.$store.getters.account.domainID +
                '/roles',
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.allDomainRoles = _.defaultTo(response.data.roles, []);
              this.error = '';
            })
            .catch((err) => {
              this.error = err;
            });
        }
      },
      confirmAdd() {
        this.$refs.confirm
          .open(
            'Confirm Add Role',
            'Are you sure you want to add ' +
              this.toAddTag +
              " to this account's roles?"
          )
          .then((confirm) => {
            if (confirm) {
              this.add();
            } else {
              //no-op
            }
          });
      },
      confirmRemove(role) {
        this.$refs.confirm
          .open(
            'Confirm Remove Role',
            'Are you sure you want to remove ' +
              role.tag +
              " from this account's roles?"
          )
          .then((confirm) => {
            if (confirm) {
              this.remove(role);
            } else {
              //no-op
            }
          });
      },

      add() {
        var rID = '';
        this.allDomainRoles.forEach((role) => {
          if (role.tag == this.toAddTag) {
            rID = role.roleID;
          }
        });
        var data = JSON.stringify({
          updates: [
            {
              roleID: rID,
              action: 0,
            },
          ],
        });
        axios
          .put(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.$store.getters.account.accountID +
              '/roles',
            data,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            // Adding an existing role still returns 200 without a meaningful body.
            if (response.data.roles != null) {
              this.$store.commit('setRoles', response.data.roles);
              this.localRoles = response.data.roles;
            }
            this.error = '';
          })
          .catch((err) => {
            this.error = err;
          });
      },
      remove(role) {
        var data = JSON.stringify({
          updates: [
            {
              roleID: role.roleID,
              action: 1,
            },
          ],
        });
        axios
          .put(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.$store.getters.account.accountID +
              '/roles',
            data,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setRoles', response.data.roles);
            this.localRoles = response.data.roles;
            this.error = '';
          })
          .catch((err) => {
            this.error = err;
          });
      },
    },
    computed: {
      hasError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      roles() {
        let roles = this.localRoles;
        const compare = (a, b) => {
          if (a.tag.toLowerCase() < b.tag.toLowerCase()) return -1;
          if (a.tag.toLowerCase() > b.tag.toLowerCase()) return 1;
          return 0;
        };
        return roles ? roles.sort(compare) : [];
      },
      getDomainID() {
        return this.$store.getters.account.domainID;
      },
      allDomainRoleTags() {
        return this.allDomainRoles
          .map((role) => {
            return role.tag;
          })
          .sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });
      },
    },
  };
</script>
