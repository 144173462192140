<template>
  <div>
    <v-container>
      <h2>Search Domain / Game Roles</h2>
      <v-layout justify-center align-center>
        <RoleSearchForm type="standalone"></RoleSearchForm>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import RoleSearchForm from './RoleSearchForm.vue';

  export default {
    name: 'searchDomainsLanding',

    components: { RoleSearchForm },
  };
</script>
