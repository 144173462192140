<template>
  <div>
    <v-container>
      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <div>
          <v-flex xs12 sm12 md12 lg12 box pa12>
            <div>
              <v-toolbar>
                <v-toolbar-title>{{ displayName }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-container>
                <v-toolbar flat color="rgba(0,0,0,0)">
                  <v-dialog
                    v-model="dialog.create"
                    max-width="900px"
                    persistent
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-on="on"
                        v-if="permissionAllowed('social', true, 'create')"
                        >+ Create Friendship</v-btn
                      >
                    </template>

                    <v-card>
                      <FriendshipSearchForm
                        :title="'Create a New Friendship'"
                        :mode="'create'"
                        :accountID="accountID"
                        :button="'Create'"
                        @close-dialog-create="closeDialogCreate()"
                        ref="friendshipSearchForm"
                      />
                      <v-btn @click="closeDialogCreate">Close</v-btn>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-toolbar dense flat color="rgba(0,0,0,0)">
                  Social Network
                </v-toolbar>
                <v-data-table
                  :headers="headers"
                  :items="tableData"
                  :loading="loading"
                >
                  <template v-slot:items="props">
                    <td>{{ props.item.displayName }}</td>
                    <td>{{ props.item.friendAccountID }}</td>
                    <td>{{ props.item.createdAt }}</td>
                    <td class="justify-center layout px-0">
                      <v-btn
                        class="mb-2"
                        v-if="permissionAllowed('social', true, 'delete')"
                        @click="confirmDelete(props.item.friendAccountID)"
                        >Remove</v-btn
                      >
                    </td>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog.delete" max-width="500px" persistent>
                  <v-card>
                    <v-container>
                      <v-card-title>
                        <h3 class="headline mb-0">
                          Are you sure you want to delete friendship
                          {{ this.removedFriendID }}?
                        </h3>
                        <DomainsSelector
                          @domainsSelected="
                            (newDomain) => {
                              domain = newDomain;
                            }
                          "
                        />
                        <p :if="isError">
                          <br />
                          <v-alert :value="isError" type="error">{{
                            getError
                          }}</v-alert>
                        </p>
                      </v-card-title>

                      <v-card-actions class="justify-center">
                        <v-btn
                          color="grey"
                          plain
                          text
                          @click="closeDialogDelete"
                        >
                          No
                        </v-btn>
                        <v-btn
                          color="error"
                          plain
                          text
                          @click="removeFriendship()"
                        >
                          Yes
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-container>
            </div>
          </v-flex>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import DomainsSelector from '../bulkchanges/DomainsSelector';
  import FriendshipSearchForm from './FriendshipSearchForm.vue';
  import _ from 'lodash';

  export default {
    name: 'friendshipResults',
    components: {
      DomainsSelector,
      FriendshipSearchForm,
    },
    data() {
      return {
        accountID: '',
        displayName: '',
        domain: '', // Used in remove friendship form
        error: '', // Used in remove friendship form
        headers: [
          {
            text: 'Display Name',
            align: 'left',
            sortable: true,
            value: 'displayName',
          },
          {
            text: 'Account ID',
            align: 'left',
            sortable: true,
            value: 'friendAccountID',
          },
          {
            text: 'Created At',
            align: 'left',
            sortable: true,
            value: 'createdAt',
          },
        ],
        dialog: {
          create: false,
          delete: false,
        },
        removedFriendID: '',
        loading: false,
      };
    },
    methods: {
      removeFriendship() {
        if (this.domain == '') {
          this.setError(
            'Must select a domain to delete friendship from first!'
          );
          return;
        }

        let url =
          this.$store.getters.serviceURL +
          '/presence/admin/account/' +
          this.accountID +
          '/friends/' +
          this.removedFriendID;

        // axios.delete does not allow request bodies, so we had to combine the JSON request with
        // the bearer tokens here with lodash
        axios
          .delete(
            url,
            _.merge(
              {
                data: {
                  domainID: this.domain,
                },
              },
              this.$store.getters.bearerAuthHeaders
            )
          )
          .then(() => {
            this.$store.dispatch('fetchFriendships', this.accountID);
            this.closeDialogDelete();
          })
          .catch(() => {
            this.setError('Failed to delete friendship');
          });
      },
      confirmDelete(friend) {
        this.removedFriendID = friend;
        this.dialog.delete = true;
      },
      closeDialogDelete() {
        this.removedFriendID = '';
        this.setError('');
        this.dialog.delete = false;
      },
      closeDialogCreate() {
        this.dialog.create = false;
        this.$refs.friendshipSearchForm.setError('');
        this.$refs.friendshipSearchForm.clearSearch();
      },
      setError(err) {
        this.error = '' + err;
      },
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
      getDisplayNameFrom(accountID) {
        let url =
          this.$store.getters.serviceURL +
          '/admin/accounts/account/' +
          accountID;

        axios
          .get(url, this.$store.getters.bearerAuthHeaders)
          .then((resp) => {
            this.displayName = resp.data.displayName;
          })
          .catch(() => {});
      },
    },
    watch: {
      tableData() {
        this.loading = false;
      },
    },
    mounted: function () {
      // Fetching friendships on mount should ensure that the user can deep-link and still see results
      this.accountID = this.$route.params.accountID;
      this.getDisplayNameFrom(this.accountID);
      this.$store.dispatch('fetchFriendships', this.accountID);
    },
    computed: {
      tableData() {
        return this.$store.getters.friendshipResults;
      },
      isError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
    },
  };
</script>

<style scoped>
  h2 {
    font-weight: bold;
    padding-top: 25px;
  }
  tr {
    cursor: pointer;
  }
</style>
