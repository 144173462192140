import Vue from 'vue';
import Vuex from 'vuex';
import config from './config.js';
import _ from 'lodash';
import axios from 'axios';

Vue.use(Vuex);

const mainStore = {
  state: {
    lastDomain: '',
    account: {
      roles: [],
      redemptions: [],
      redemptionCount: 0,
      domains: [],
    },
    siteEnv: config.siteEnv,
    serviceURL: config.apiURL,
    awsRegion: '', // config.awsRegion,
    awsAccessKeyId: '', // config.awsAccessKeyId,
    awsSecretAccessKey: '', // config.awsSecretAccessKey,
    games: [],
    personas: [],
    bans: [],
    personaHistory: [],
    entitlements: [],
    entitlementStates: ['unclaimed', 'claimed', 'refunded', 'clawedback'],
    entitlementSources: ['GooglePlay', 'AppStore', 'Xsolla'],
    userProfile: {
      accountID: '',
      personaID: '',
      gameID: '',
      email: '',
      externalID: '',
      targetedAnalyticsOptOut: false,
      emailOptIn: false,
      dataOptIn: false,
      ccpaProtectData: false,
      firstName: '',
      lastName: '',
      countryCode: '',
      language: '',
      emailVerified: false,
      createdAt: '',
      bans: [], //{gameID: "", startDate: "", endDate: ""}
      presenceSettings: {
        socialMode: '',
      },
    },
    searchResults: [],
    searchResultCount: 0,
    searchResultPage: 0,
    social: {
      friendshipResults: [],
      fuzzyAccountSearchResults: [],
    },
    redemption: {
      offers: [],
      offerCount: 0,
      offersPage: 1,
      currentOffer: {
        id: '',
        name: '',
        description: '',
        startDate: '',
        endDate: '',
        grants: [],
        codeMaxUses: 1,
        accountMaxUses: 1,
      },
      currentBatches: [],
      currentCustomCodes: [],
      batchCount: 0,
      batchsPage: 1,
      redemptions: [],
      redemptionCount: 0,
    },
    gameRoles: {
      gameID: '',
      roles: [],
    },
    clientsAndScopes: [],
  },
  getters: {
    lastDomain: (state) => state.lastDomain,
    bans: (state) => state.bans,
    personaHistory: (state) => state.personaHistory,
    entitlements: (state) => state.entitlements,
    entitlementStates: (state) => state.entitlementStates,
    entitlementSources: (state) => state.entitlementSources,
    account: (state) => {
      return state.account;
    },
    games: (state) => {
      return state.games;
    },
    accountDomains: (state) => {
      // Grabs domains the account is currently a member of.
      return state.account.domains;
    },
    isExternalPersona: (state) => {
      return state.isExternalPersona || false;
    },
    personas: (state) => {
      if (state.isExternalPersona) {
        return [state.account];
      } else if (state.personas) {
        return state.personas;
      }
      return [];
    },
    gameIDs: (state) => {
      if (state.games) {
        return state.games.map((g) => g.gameID);
      }
      return [];
    },
    isProduction: (state) => {
      return state.siteEnv === 'prod';
    },
    serviceURL: (state) => {
      return state.serviceURL;
    },
    awsRegion: (state) => {
      return state.awsRegion;
    },
    awsAccessKeyId: (state) => {
      return state.awsAccessKeyId;
    },
    awsSecretAccessKey: (state) => {
      return state.awsSecretAccessKey;
    },
    personaIDs: (state) => {
      if (state.isExternalPersona) {
        return [state.account.personaID];
      } else if (state.personas) {
        return state.personas.map((p) => p.personaID);
      }
      return [];
    },
    personaGameIDs: (state) => {
      if (state.isExternalPersona) {
        return [state.account.gameID];
      } else if (state.personas) {
        return state.personas.map((p) => p.gameID);
      }
      return [];
    },
    userProfile: (state) => {
      return state.userProfile;
    },
    searchResults: (state) => {
      return state.searchResults;
    },
    searchResultCount: (state) => {
      return state.searchResultCount;
    },
    searchResultPage: (state) => {
      return state.searchResultPage;
    },
    friendshipResults: (state) => {
      return state.social.friendshipResults;
    },
    fuzzyAccountSearchResults: (state) => {
      return state.social.fuzzyAccountSearchResults;
    },
    accountID: (state) => {
      return state.account.accountID || state.account.personaID;
    },
    updateDisplayNameURL: (state, getters) => {
      var lookupField = state.isExternalPersona ? 'personaID' : 'accountID';
      return `${state.serviceURL}/admin/accounts/user/displayName?${lookupField}=${getters.accountID}`;
    },
    deleteAccountURL: (state, getters) => {
      var x = state.isExternalPersona
        ? '/admin/accounts/external/'
        : '/admin/accounts/';
      return state.serviceURL + x + getters.accountID;
    },
    restoreAccountURL: (state, getters) => {
      return (
        state.serviceURL +
        '/admin/accounts/account/' +
        getters.accountID +
        '/restore'
      );
    },
    fetchBansURL: (state, getters) => {
      return (
        state.serviceURL +
        '/admin/bans/' +
        (state.isExternalPersona ? 'persona/' : 'account/') +
        getters.accountID
      );
    },
    createBanURL: (state) => {
      return state.serviceURL + '/admin/bans/';
    },
    fetchEntitlementsURL: (state, getters) => {
      return (
        getters.serviceURL +
        '/admin/entitlements/' +
        (state.isExternalPersona ? 'persona/' : 'account/') +
        getters.accountID
      );
    },
    personaURL: (getters, state) => {
      return (
        getters.serviceURL +
        (state.isExternalPersona
          ? '/admin/accounts/user/persona/'
          : '/admin/accounts/persona/')
      );
    },
    redemptionOffers: (state) => {
      return state.redemption.offers;
    },
    redemptionOfferCount: (state) => {
      return state.redemption.offerCount;
    },
    redemptionOffersPage: (state) => {
      return state.redemption.offersPage;
    },
    redemptionCurrentOffer: (state) => {
      return state.redemption.currentOffer;
    },
    redemptionCurrentBatches: (state) => state.redemption.currentBatches,
    redemptionCurrentCustomCodes: (state) =>
      state.redemption.currentCustomCodes,
    redemptions: (state) => {
      return state.redemption.redemptions;
    },
    redemptionCount: (state) => {
      return state.redemption.redemptionCount;
    },
    gameRoles: (state) => {
      return state.gameRoles;
    },
    clientsAndScopes: (state) => {
      return state.clientsAndScopes;
    },
  },
  mutations: {
    setLastDomain(state, n) {
      state.lastDomain = n;
    },
    setAccount(state, account) {
      state.isExternalPersona = false;
      var personas = [account.persona];
      state.account = account;
      state.account.personas = personas;
      if (typeof state.account.roles == 'undefined') {
        state.account.roles = [];
      }

      // Set domains the account is a part of.
      if (_.isNil(account.domainIDs)) {
        state.account.domains = [account.domainID];
      } else {
        state.account.domains = account.domainIDs;
      }
    },
    setExternalAccount(state, external) {
      state.account = external.User.Persona;
      state.personas = [];
      state.isExternalPersona = true;
    },
    setIsExternalPersona(state, isExternalPersona) {
      state.isExternalPersona = isExternalPersona === true;
    },
    setDisplayName(state, name) {
      state.account.displayName = name;
    },
    setRequireReset(state, requireReset) {
      state.account.requireReset = requireReset;
    },
    setRoles(state, roles) {
      state.account.roles = roles;
    },
    setGames(state, games) {
      state.games = games;
    },
    addDomains(state, domains) {
      state.account.domains.push(domains.domainID);
    },
    removeDomains(state, domains) {
      state.account.domains = state.account.domains.filter((domain) => {
        return domain !== domains;
      });
    },
    setPersonas(state, personas) {
      state.personas = personas;
    },
    setPersonaRoles(state, payload) {
      state.personas.forEach((p) => {
        if (p.personaID === payload.persona.personaID) {
          p.roles = payload.roles;
        }
      });
    },
    setBans(state, bans) {
      state.bans = bans;
    },
    setPersonaHistory(state, personaHistory) {
      state.personaHistory = personaHistory;
    },
    setEntitlements(state, entitlements) {
      state.entitlements = entitlements;
    },
    addPersona(state, persona) {
      state.personas.push(persona);
    },
    setProfile(state, profile) {
      state.userProfile = profile;
    },
    setFirstName(state, name) {
      state.userProfile.firstName = name;
    },
    setLastName(state, name) {
      state.userProfile.lastName = name;
    },
    setOptIns(state, optIns) {
      state.userProfile.targetedAnalyticsOptOut = optIns.targetedAnalytics;
      state.userProfile.emailOptIn = optIns.email;
      state.userProfile.dataOptIn = optIns.data;
      state.userProfile.ccpaProtectData = optIns.ccpa;
    },
    setSearchResults(state, results) {
      state.searchResults = results;
    },
    setSearchResultCount(state, total) {
      state.searchResultCount = total;
    },
    searchResultPage(state, page) {
      state.searchResultPage = page;
    },
    setFriendshipResults(state, results) {
      state.social.friendshipResults = results;
    },
    setFuzzyAccountSearchResults(state, results) {
      state.social.fuzzyAccountSearchResults = results;
    },
    updateRoles(state, data) {
      if (data.User != null) {
        // New structure
        if (data.User.Account != null) {
          let pID = data.personaID;
          let index = state.personas.findIndex((p) => p.personaID === pID);
          if (index < 0) {
            state.personas.push(data);
          } else {
            state.personas[index] = data;
          }
        } else if (data.User.Persona != null) {
          state.account.roles = data.User.Persona.roles || [];
        }
      } else if (data.personaID != null) {
        // Old structure
        let pID = data.personaID;
        let index = state.personas.findIndex((p) => p.personaID === pID);
        if (index < 0) {
          state.personas.push(data);
        } else {
          state.personas[index] = data;
        }
      }
    },
    setRedemptionOffers(state, offers) {
      state.redemption.offers = offers;
    },
    addRedemptionOffers(state, offers) {
      state.redemption.offers.push(...offers);
    },
    setRedemptionOfferCount(state, count) {
      state.redemption.offerCount = count;
    },
    setRedemptionOffersPage(state, newPage) {
      state.redemption.offersPage = newPage;
    },
    setRedemptionCurrentOffer(state, newOffer) {
      state.redemption.currentOffer = newOffer;
    },
    setRedemptionCurrentBatches(state, batchResponse) {
      state.redemption.currentBatches = batchResponse.batches;
      state.redemption.batchCount = batchResponse.count;
    },
    setRedemptionCurrentCustomCodes(state, codes) {
      state.redemption.currentCustomCodes = codes;
    },
    setRedemptions(state, redemptionsResponse) {
      state.redemption.redemptions = redemptionsResponse.redemptions;
      state.redemption.redemptionCount = redemptionsResponse.count;
    },

    setGameRoles(state, gameRoles) {
      if (
        gameRoles.gameID != state.gameRoles.gameID ||
        gameRoles.roles.length != state.gameRoles.roles.length
      ) {
        //game roles should not change that much in the backend, so do a cursory check with lengths
        state.gameRoles = gameRoles;
      }
    },
    setClientsAndScopes(state, clientsAndScopes) {
      state.clientsAndScopes = clientsAndScopes;
    },
  },
  actions: {
    fetchBans({ commit, state, dispatch, getters }) {
      dispatch('refreshIfNeeded').then(() => {
        axios
          .get(getters.fetchBansURL, getters.bearerAuthHeaders)
          .then((resp) => {
            commit('setBans', resp.data.bans);
            return Promise.resolve();
          })
          .catch((err) => {
            commit('setBans', []);
            return Promise.reject('Error fetching bans.');
          });
      });
    },
    fetchEntitlements({ commit, state, dispatch, getters }, payload) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios
          .get(getters.fetchEntitlementsURL, getters.bearerAuthHeaders)
          .then((resp) => {
            commit('setEntitlements', resp.data.entitlements);
            return Promise.resolve();
          })
          .catch((err) => {
            commit('setEntitlements', []);
            return Promise.reject('Error fetching entitlements', err);
          });
      });
    },
    fetchEntitlementsByReceiptSource(
      { commit, state, dispatch, getters },
      payload
    ) {
      return dispatch('refreshIfNeeded').then(() => {
        let receipt = null;
        let source = null;

        if (payload && 'query' in payload) {
          let query = payload.query;
          if ('receipt' in query) {
            receipt = query.receipt;
          }
          if ('source' in query) {
            source = query.source;
          }
        }

        if (receipt == null || source == null) {
          return Promise.reject('invalid arguments');
        }

        return axios
          .get(
            getters.serviceURL +
              '/admin/entitlements/' +
              receipt +
              '/source/' +
              source,
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            commit('setEntitlements', resp.data.entitlements);
            return Promise.resolve();
          })
          .catch((err) => {
            return Promise.reject(
              'Error fetching entitlements, your search parameters may not be correct',
              err
            );
          });
      });
    },
    fetchPersonaHistoryItems({ commit, state, dispatch, getters }) {
      dispatch('refreshIfNeeded').then(() => {
        axios
          .get(
            getters.serviceURL +
              '/admin/accounts/account/' +
              getters.account.accountID +
              '/removed',
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            commit('setPersonaHistory', resp.data.personas);
          })
          .catch((err) => {
            return Promise.reject('Error persona history', err);
          });
      });
    },
    createBan({ commit, state, dispatch, getters }, payload) {
      if (!isBanValid(payload.ban)) {
        return Promise.reject('invalid ban');
      }
      return dispatch('refreshIfNeeded')
        .then(() => {
          return axios.put(
            getters.createBanURL,
            payload.ban,
            getters.bearerAuthHeaders
          );
        })
        .then((resp) => this.dispatch('fetchBans'));
    },
    deleteBan({ getters, dispatch }, payload) {
      return dispatch('refreshIfNeeded').then(() => {
        let url = getters.serviceURL + '/admin/bans/ban/' + payload.ban.banID;
        axios.delete(url, getters.bearerAuthHeaders).then((resp) => {
          this.dispatch('fetchBans');
        });
      });
    },
    updateEmail({ getters, dispatch }, payload) {
      return dispatch('refreshIfNeeded')
        .then(() => {
          return axios.put(
            getters.serviceURL +
              '/accounts/admin/email/' +
              getters.account.accountID,
            { emailAddress: payload.newEmail },
            getters.bearerAuthHeaders
          );
        })
        .then((resp) => {
          console.log('updateEmail successful');
        })
        .catch((err) => {
          if (err.message.indexOf('400') != -1) {
            return Promise.reject('Invalid Email Address');
          } else if (err.message.indexOf('422') != -1) {
            return Promise.reject('Duplicate Email Address');
          }
        });
    },
    updateLanguage({ getters, dispatch }, payload) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios
          .put(
            `${getters.serviceURL}/admin/accounts/account/${getters.account.accountID}/language`,
            { languageCode: payload.newLanguage },
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            console.log('updateLanguage successful');
          });
      });
    },
    updateDisplayName({ getters, dispatch }, payload) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios
          .put(
            getters.updateDisplayNameURL,
            { newDisplayName: payload.newDisplayName },
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            console.log('update display name successful');
          })
          .catch((err) => {
            throw err;
          });
      });
    },
    fetchAllRedemptionOffers({ commit, dispatch, getters }, payload) {
      commit('setRedemptionOffers', []);
      return dispatch({
        type: 'fetchNextRedemptionOfferPage',
        offset: 0,
        limit: 100,
      }).then(() => {
        return Promise.resolve(true);
      });
    },
    fetchNextRedemptionOfferPage({ commit, dispatch, getters }, payload) {
      let offset = payload.offset;
      let limit = payload.limit;
      return axios
        .get(
          getters.serviceURL +
            `/admin/redemption/offers?offset=${offset}&limit=${limit}`,
          getters.bearerAuthHeaders
        )
        .then((resp) => {
          commit('addRedemptionOffers', resp.data.offers);
          if (offset + limit < resp.data.count) {
            return dispatch({
              type: 'fetchNextRedemptionOfferPage',
              offset: offset + limit,
              limit: limit,
            });
          } else {
            return Promise.resolve(true);
          }
        });
    },
    fetchRedemptionOffers({ getters, dispatch, commit, state }, payload) {
      return dispatch('refreshIfNeeded').then(() => {
        let offset = 0;
        let limit = 25;

        if (payload && 'query' in payload) {
          let query = payload.query;

          if ('offset' in query) {
            offset = query.offset;
          }
          if ('limit' in query) {
            limit = query.limit;
          }
        }
        return axios
          .get(
            getters.serviceURL +
              `/admin/redemption/offers?offset=${offset}&limit=${limit}`,
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            commit('setRedemptionOffers', resp.data.offers);
            commit('setRedemptionOfferCount', resp.data.count);
          });
      });
    },
    fetchRedemptionOffer({ getters, dispatch, commit, state }, offerID) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios
          .get(
            getters.serviceURL + '/admin/redemption/offers/' + offerID,
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            commit('setRedemptionCurrentOffer', resp.data);
          });
      });
    },
    createRedemptionOffer({ getters, dispatch, commit, state }, payload) {
      let headers = getters.bearerAuthHeaders.headers;
      return dispatch('refreshIfNeeded').then(() => {
        return axios({
          method: 'post',
          url: getters.serviceURL + `/admin/redemption/offers`,
          data: payload,
          headers: headers,
        }).then((resp) => {
          commit('setRedemptionCurrentOffer', resp.data);
          return resp.data;
        });
      });
    },
    updateRedemptionOffer({ getters, dispatch, commit, state }, payload) {
      let headers = getters.bearerAuthHeaders.headers;
      return dispatch('refreshIfNeeded').then(() => {
        return axios({
          method: 'put',
          url: getters.serviceURL + `/admin/redemption/offers/${payload.id}`,
          data: payload,
          headers: headers,
        }).then((resp) => {
          commit('setRedemptionCurrentOffer', resp.data);
          return resp.data;
        });
      });
    },
    fetchRedemptionBatchesForOffer(
      { getters, dispatch, commit, state },
      offerID
    ) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios
          .get(
            getters.serviceURL + `/admin/redemption/offers/${offerID}/batches`,
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            commit('setRedemptionCurrentBatches', resp.data);
          });
      });
    },
    fetchRedemptionCodesForOffer(
      { getters, dispatch, commit, state },
      offerID
    ) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios
          .get(
            getters.serviceURL + `/admin/redemption/offers/${offerID}/codes`,
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            commit('setRedemptionCurrentCustomCodes', resp.data.codes);
          });
      });
    },
    createRedemptionCodeForOffer(
      { getters, dispatch, commit, state },
      payload
    ) {
      let offerID = payload.offerID;
      let body = payload.codeDetails;
      let headers = getters.bearerAuthHeaders.headers;
      return dispatch('refreshIfNeeded')
        .then(() => {
          return axios({
            method: 'post',
            url:
              getters.serviceURL + `/admin/redemption/offers/${offerID}/codes`,
            data: body,
            headers: headers,
          });
        })
        .then((resp) => {
          let newCode = resp.data;
          let oldCodes = getters.redemptionCurrentCustomCodes;
          commit(
            'setRedemptionCurrentCustomCodes',
            _.concat([newCode], oldCodes)
          );
        });
    },
    deleteRedemptionCodeForOffer(
      { getters, dispatch, commit, state },
      payload
    ) {
      let offerID = payload.offerID;
      let domainID = payload.domainID;
      let codeID = payload.codeID;
      let headers = getters.bearerAuthHeaders.headers;
      return dispatch('refreshIfNeeded')
        .then(() => {
          return axios({
            method: 'delete',
            url:
              getters.serviceURL +
              `/admin/redemption/offers/${offerID}/codes/${domainID}/${codeID}`,
            headers: headers,
          });
        })
        .then((resp) => {
          let oldCodes = getters.redemptionCurrentCustomCodes;
          let newCodes = _.filter(oldCodes, (code) => {
            return !(code.id == codeID && code.domainID == domainID);
          });
          commit('setRedemptionCurrentCustomCodes', newCodes);
        });
    },
    createRedemptionBatch({ getters, dispatch }, payload) {
      let offerID = payload.offerID;
      let batchRequest = payload.batchDetails;
      let headers = getters.bearerAuthHeaders.headers;
      return dispatch('refreshIfNeeded').then(() => {
        return axios({
          method: 'post',
          url:
            getters.serviceURL + `/admin/redemption/offers/${offerID}/batches`,
          data: batchRequest,
          headers: headers,
        });
      });
    },
    fetchRedemptionBatch({ getters, dispatch }, payload) {
      let offerID = payload.offerID;
      let batchID = payload.batchID;
      return dispatch('refreshIfNeeded').then(() => {
        return axios.get(
          getters.serviceURL +
            `/admin/redemption/offers/${offerID}/batches/${batchID}`,
          getters.bearerAuthHeaders
        );
      });
    },
    createRedemptionExport({ getters, dispatch }, payload) {
      let headers = getters.bearerAuthHeaders.headers;
      return dispatch('refreshIfNeeded').then(() => {
        return axios({
          method: 'post',
          url: getters.serviceURL + '/admin/redemption/export',
          data: payload,
          headers: headers,
        });
      });
    },
    fetchRedemptionExportJob({ getters, dispatch }, exportID) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios.get(
          getters.serviceURL + `/admin/redemption/export/${exportID}`,
          getters.bearerAuthHeaders
        );
      });
    },
    fetchAccountRedemptions({ getters, dispatch, commit }, payload) {
      return dispatch('refreshIfNeeded').then(() => {
        let offset = 0;
        let limit = 25;

        if (payload && 'query' in payload) {
          let query = payload.query;

          if ('offset' in query) {
            offset = query.offset;
          }
          if ('limit' in query) {
            limit = query.limit;
          }
        }

        let accountID = payload.accountID;

        return axios
          .get(
            getters.serviceURL +
              `/admin/redemption/history/account/${accountID}?offset=${offset}&limit=${limit}`,
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            commit('setRedemptions', resp.data);
          });
      });
    },
    getHydraConsentSessions({ getters, dispatch, commit }, _) {
      return dispatch('refreshIfNeeded').then(() => {
        let url =
          getters.serviceURL +
          '/admin/accounts/account/' +
          getters.account.accountID +
          '/consent';
        return axios
          .get(url, getters.bearerAuthHeaders)
          .then((resp) => {
            commit('setClientsAndScopes', resp.data);
            return resp.data;
          })
          .catch((err) => {
            throw {
              error: err.response.data.error,
            };
          });
      });
    },
    revokeHydraConsent({ getters, dispatch }, payload) {
      return dispatch('refreshIfNeeded').then(() => {
        let url =
          getters.serviceURL +
          '/admin/accounts/account/' +
          getters.account.accountID +
          '/oauthclient/' +
          payload.clientID +
          '/revoke';
        return axios
          .delete(url, getters.bearerAuthHeaders)
          .then((_) => {
            // Empty response on success
            return;
          })
          .catch((err) => {
            throw {
              error: err.response.data.error,
            };
          });
      });
    },
    getConflictsForPlayer({ getters, dispatch, commit }, payload) {
      return dispatch('refreshIfNeeded').then((result) => {
        // Kick off all the async calls to get conflicts from the personas.
        let promises = [];
        let personaIDs = payload.personaIDs;
        for (let i = 0; personaIDs && i < personaIDs.length; i++) {
          promises.push(
            dispatch('getConflictInfoForPersona', {
              personaID: personaIDs[i],
            })
          );
        }

        // Promise.all will fail if even one promise fails. Wrap the promises with a success
        // flag so that we can still continue when we have a failure.
        const toResultObject = (promise) => {
          return promise
            .then((result) => ({ success: true, result }))
            .catch((error) => ({ success: false, error }));
        };

        return Promise.all(promises.map(toResultObject)).then((values) => {
          let conflictInfo = {};

          values.forEach((value) => {
            if (!value.success) {
              conflictInfo[value.error.personaID] = [
                'Error: ' + val.error.error,
              ];
            } else {
              conflictInfo[value.result.personaID] = value.result.conflictInfo;
            }
          });
          return conflictInfo;
        });
      });
    },
    getConflictInfoForPersona({ getters, dispatch, commit }, payload) {
      return axios
        .get(
          `${getters.serviceURL}/admin/accounts/persona/${payload.personaID}/conflict`,
          getters.bearerAuthHeaders
        )
        .then((response) => {
          return {
            personaID: payload.personaID,
            conflictInfo: response.data['conflictingPersonas'],
          };
        })
        .catch((err) => {
          throw {
            personaID: payload.personaID,
            error: err.response.data.error,
          };
        });
    },
    fetchFriendships({ getters, dispatch, commit }, accountID) {
      return dispatch('refreshIfNeeded').then(() => {
        return axios
          .get(
            getters.serviceURL +
              '/presence/admin/account/' +
              accountID +
              '/friends',
            getters.bearerAuthHeaders
          )
          .then((resp) => {
            let friends = resp.data.friends;

            // Retrieves the display name and email for each account in order to be displayed.
            // This incurs a brute force-y method of retrieving the data for each account ID, but
            // fortunately, the average number of friends per user is relatively low, so this
            // inefficient method should be fine in achieving this task for now.

            var updatedFriendsList = [];

            let serviceURL = getters.serviceURL;
            let bearerAuthHeaders = getters.bearerAuthHeaders;

            friends.forEach(function (friend) {
              let url =
                serviceURL +
                '/admin/accounts/account/' +
                friend.friendAccountID;

              axios
                .get(url, bearerAuthHeaders)
                .then((resp) => {
                  let transformedData = {
                    accountID: friend.accountID,
                    displayName: resp.data.displayName,
                    friendAccountID: friend.friendAccountID,
                    createdAt: friend.createdAt,
                  };

                  updatedFriendsList.push(transformedData);
                })
                .catch(() => {});
            });

            commit('setFriendshipResults', updatedFriendsList);
          })
          .catch((err) => {
            throw {
              error: err,
            };
          });
      });
    },
  },
};

function isBanValid(ban) {
  return !(
    isUndefinedOrEmpty(ban.DomainID) ||
    isUndefinedOrEmpty(ban.StartDate) ||
    isUndefinedOrEmpty(ban.PersonaID) ||
    isUndefinedOrEmpty(ban.Comment)
  );
}

function isUndefinedOrEmpty(field) {
  if (field === undefined) {
    return true;
  } else if (field === '') {
    return true;
  }
  return false;
}

export default mainStore;
