<template>
  <v-container fluid class="listContainer">
    <v-layout row justify-left align-baseline>
      <v-toolbar>
        <v-toolbar-title>
          <h2>Offers</h2>
        </v-toolbar-title>
        <v-spacer />
        <v-flex sm4 md4>
          <v-text-field
            label="Name search"
            append-icon="search"
            v-model="search"
            single-line
            hide-details
          />
        </v-flex>
        <v-divider vertical class="mx-2"></v-divider>
        <v-btn to="/redemption/offers/new" color="info">New Offer</v-btn>
      </v-toolbar>
    </v-layout>
    <v-layout justify-center class="tableContainer" d-flex>
      <v-data-table
        :headers="headers"
        :items="offers"
        :search="search"
        :pagination.sync="pagination"
        :loading="areOffersLoaded"
        :rows-per-page-items="[10, 25, 50, 100]"
      >
        <template slot="no-data">
          <v-alert :value="true" color="info" icon="info">No offers</v-alert>
        </template>
        <template slot="items" slot-scope="props">
          <tr @click="selectOffer(props.item.id)" class="text-xs-center">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.description }}</td>
            <td>{{ props.item.startDate | moment('LLL z') }}</td>
            <td>{{ props.item.endDate | moment('LLL z') }}</td>
            <td v-show="show">
              <v-btn :to="'/redemption/offers/' + props.item.id">Details</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
  </v-container>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'CodeRedemptionOffersView',
    inheritAttrs: false,

    props: {
      showButton: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        headers: [
          { text: 'ID', align: 'center', value: 'id', sortable: false },
          { text: 'Name', align: 'center', sortable: false, value: 'name' },
          {
            text: 'Description',
            align: 'center',
            value: 'description',
            sortable: false,
          },
          { text: 'Start Date', align: 'center', value: 'startDate' },
          { text: 'End Date', align: 'center', value: 'endDate' },
          { text: '', align: 'center', value: 'id', sortable: false },
        ],
        search: '',
        pagination: {
          rowsPerPage: 10,
        },
        error: '',
        areOffersLoaded: false,
      };
    },
    created() {
      this.fetchOffers();
    },
    methods: {
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
      selectOffer(offerID) {
        this.$router.push('/redemption/offers/' + offerID);
      },
      fetchOffers() {
        this.areOffersLoaded = true;
        this.$store
          .dispatch('fetchAllRedemptionOffers')
          .catch((err) => this.setError(err))
          .finally(() => {
            this.areOffersLoaded = false;
          });
      },
      setError(err) {
        this.error = '' + err;
      },
    },
    computed: {
      loggedIn() {
        return this.$store.getters.authenticated;
      },
      show() {
        return this.$props.showButton;
      },
      offers() {
        return this.$store.getters.redemptionOffers;
      },
      offerCount() {
        return this.$store.getters.redemptionOfferCount;
      },
    },
  };
</script>

<style scoped>
  .listContainer {
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .tableContainer {
    padding-top: 10px;
  }
</style>
