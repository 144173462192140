<template>
  <div>
    <v-layout
      row
      justify-left
      align-center
      fill-height
      :class="{ offsetTop: type === 'profile', wotcContainer: true }"
    >
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <div v-if="type === 'standalone'">
          <h2>Entitlement Search</h2>
          <v-text-field
            v-model="receipt"
            style="display: inline-block"
            append-icon="search"
            label="Receipt ID"
            :error="searchHasError"
            single-line
            hide-details
          ></v-text-field>
          <v-select
            style="display: inline-block; width: 150px; margin-left: 20px"
            :items="entitlementSources"
            v-model="source"
            placeholder="Source"
            :error="sourceHasError"
            type="text"
          ></v-select>
          <v-btn @click="searchReceipts">Search</v-btn>
        </div>
        <h2 v-if="type === 'profile'">
          <span :class="{ offsetTitle: type === 'profile' }" style="">
            Entitlements
          </span>
          <v-text-field
            style="display: inline-block; float: right; margin-bottom: 5px"
            v-model="search"
            append-icon="search"
            label="Search Entitlements"
            single-line
            hide-details
          ></v-text-field>
        </h2>
        <v-data-table
          :items="entitlementsList"
          :headers="headers"
          :loading="isListLoading"
          :search="search"
          item-key="entitlementID"
          :rows-per-page-items="[
            10,
            25,
            50,
            {
              text: '$vuetify.dataIterator.rowsPerPageAll',
              value: -1,
            },
          ]"
          v-if="type === 'profile' || submitted"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                <div
                  v-if="props.item.accountID && props.item.accountID.length > 0"
                >
                  <span class="note">Account ID:</span>
                  <a @click="viewAccount(props.item.accountID)">
                    {{ props.item.accountID }}
                  </a>
                </div>
                <div
                  v-if="props.item.personaID && props.item.personaID.length > 0"
                >
                  <span class="note">Persona ID:</span>
                  {{ props.item.personaID }}
                </div>
              </td>
              <td>{{ props.item.receiptID }}</td>
              <td>{{ props.item.sku }}</td>
              <td>{{ props.item.gameID }}</td>
              <td>{{ props.item.source }}</td>
              <td>
                <v-select
                  style="width: 120px"
                  :items="entitlementStates"
                  v-model="props.item.currentState"
                  @change="registerChange(props.item)"
                  placeholder="state"
                  type="text"
                ></v-select>
              </td>
              <td>{{ formatDate(props.item.created) }}</td>
              <td style="text-align: right">
                <v-btn
                  v-on:click="confirmEdit(props.item)"
                  :disabled="isDirty(props.item.entitlementID)"
                >
                  Save
                </v-btn>
              </td>
            </tr>
          </template>
          <template slot="no-results" :value="true">
            <v-alert icon="info" color="info" :value="true">
              No Entitlements Found
            </v-alert>
          </template>
        </v-data-table>
        <v-alert v-model="hasError">{{ getError }}</v-alert>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import _ from 'lodash';
  import axios from 'axios';
  import moment from 'moment';

  export default {
    name: 'entitlements',
    props: {
      type: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        headers: [
          { text: 'Owner IDs', value: 'accountID', sortable: true },
          { text: 'Receipt ID', value: 'receiptID', sortable: true },
          { text: 'SKU', value: 'sku', sortable: true },
          { text: 'Game', value: 'gameID', sortable: true },
          { text: 'Source', value: 'source', sortable: true },
          { text: 'State', value: 'currentState', sortable: true },
          { text: 'Created On', value: 'created', sortable: true },
          { text: '', value: '' },
        ],
        search: '',
        error: '',
        dirtyItems: [],
        receipt: '',
        source: '',
        submitted: false,
        isListLoading: false,
      };
    },
    watch: {
      accountID: function () {
        this.fetchEntitlements();
      },
    },
    mounted: function () {
      this.$store.commit('setEntitlements', []);
      this.isListLoading = true;

      if (this.type === 'profile') {
        this.fetchEntitlements();
      }
    },
    methods: {
      formatDate(isoDateString) {
        return isoDateString ? moment(isoDateString).format('YYYY/MM/DD') : '';
      },
      fetchEntitlements() {
        if (!_.isNil(this.accountID)) {
          this.error = '';
          this.$store
            .dispatch({
              type: 'fetchEntitlements',
            })
            .catch((err) => {
              this.setError(err);
            })
            .finally(() => {
              this.isListLoading = false;
            });
        }
      },
      isDirty(entitlementId) {
        return this.dirtyItems.indexOf(entitlementId) === -1;
      },
      registerChange(itemChanged) {
        let id = itemChanged.entitlementID;
        if (this.dirtyItems.indexOf(id) === -1) {
          this.dirtyItems.push(id);
        }
      },
      confirmEdit(item) {
        let id = item.entitlementID;
        this.$refs.confirm
          .open(
            'Confirm Update of State',
            'Do you wish to update state to `' + item.currentState + '`?'
          )
          .then((confirm) => {
            this.setError('');
            if (confirm) {
              axios
                .put(
                  this.$store.getters.serviceURL +
                    '/admin/entitlements/' +
                    id +
                    '/state',
                  { state: item.currentState },
                  this.$store.getters.bearerAuthHeaders
                )
                .then((_) => {
                  let index = this.dirtyItems.indexOf(id);
                  if (index !== -1) {
                    this.dirtyItems.splice(index, 1);
                  }
                })
                .catch((err) => {
                  this.setError('Unable to update state: ' + err);
                });
            }
          })
          .catch((err) => {
            this.setError('Unable to update state: ' + err);
          });
      },
      searchReceipts() {
        this.submitted = true;
        if (this.searchHasError || this.sourceHasError) {
          return false;
        }
        let queryParams = { receipt: this.receipt, source: this.source };
        this.error = '';
        this.$store
          .dispatch({
            type: 'fetchEntitlementsByReceiptSource',
            query: queryParams,
          })
          .catch((err) => {
            this.setError(err);
          });
        this.isListLoading = false;
        this.pagination.totalItems = this.entitlementsList.length;
        this.pages = this.calculatePages();
      },
      setError(err) {
        this.error = '' + err;
      },
      viewAccount(accountID) {
        let entitlement = this.entitlementsList.find(
          (e) => e.accountID === accountID
        );
        let routeData = this.$router.resolve(
          `/edit/${entitlement.domainID}/${accountID}`
        );
        window.open(routeData.href, '_blank');
      },
    },
    computed: {
      hasError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      accountID() {
        return this.$store.getters.accountID;
      },
      entitlementsList() {
        return this.$store.getters.entitlements;
      },
      entitlementStates() {
        return this.$store.getters.entitlementStates;
      },
      entitlementSources() {
        return this.$store.getters.entitlementSources;
      },
      searchHasError() {
        return this.submitted ? this.receipt === '' : false;
      },
      sourceHasError() {
        return this.submitted ? this.source === '' : false;
      },
    },
  };
</script>

<style scoped>
  .offsetTop {
    margin-top: -20px;
  }
  .offsetTitle {
    position: relative;
    top: 20px;
  }
  .note {
    color: #888;
  }
  table.v-table thead th {
    text-align: left;
  }
</style>
