<template>
  <v-date-picker
    class="date-range-picker"
    :value="value"
    @input="onInput"
    color="light-blue"
    event-color="light-blue lighten-4 date-in-range"
    :events="selectedRange"
    :allowed-dates="allowedDates"
    no-title
    multiple
  >
    <slot></slot>
  </v-date-picker>
</template>

<script>
  import moment from 'moment';
  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      allowedDates: Function,
    },
    data() {
      return {
        dates: [],
      };
    },
    created() {
      this.dates = this.value;
    },
    watch: {
      value(val) {
        this.dates = val;
      },
    },
    methods: {
      diff(a, b) {
        return a.filter((v) => !b.includes(v));
      },
      onInput(val) {
        let newRange;
        if (val.length > 2) {
          let newDate = this.diff(val, this.dates)[0];
          let currentRange = this.dates.sort();
          newRange = [currentRange[0], newDate];
        } else {
          newRange = val;
        }
        this.dates = newRange.sort();
        this.$emit('input', this.dates);
      },
      selectedRange(date) {
        if (this.dates.length == 2) {
          return moment(date).isBetween(...this.dates);
        }
      },
    },
  };
</script>
