<template><div></div></template>

<script>
  export default {
    name: 'jwt',
    data() {
      return {
        tokenKey: 'jwt-token',
        refreshKey: 'jwt-refresh-token',
      };
    },
    created() {
      this.consumeRefreshToken();
      setInterval(this.consumeRefreshToken, 10 * 60 * 1000);
    },
    methods: {
      consumeRefreshToken() {
        this.$store
          .dispatch('refreshToken')
          .then((authInfo) => {
            if (this.$router.currentRoute.path === '/login') {
              this.$router.push('/');
            }
          })
          .catch((err) => {
            this.$store.commit('clear');
            let location = window.location.pathname;

            if (location != '/login') {
              let cleanedPath = encodeURI(location);
              cleanedPath = cleanedPath.replace(/&/g, encodeURIComponent('&'));
              this.$router.push(`/login?redirectTo=${cleanedPath}`);
            } else {
              let redirectTo = window.location.search;
              this.$router.push(`/login${redirectTo}`);
            }
          });
      },
    },
  };
</script>
