<template lang="html">
  <v-container px-0 pt-2>
    <v-data-table
      :headers="headers"
      :items="codes"
      pagination.sync="pagination"
      :loading="loadingCodes"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert icon="info" color="info" :value="true">No Codes Found</v-alert>
      </template>
      <template slot="items" slot-scope="props">
        <tr class="text-xs-center">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.domainID }}</td>
          <td>{{ props.item.useCount }}</td>
          <td>
            <v-btn
              color="error"
              @click="deleteCode(props.item.id, props.item.domainID)"
            >
              Delete
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="footer">
        <tr>
          <td></td>
          <td align="center">
            <v-text-field
              name="codeID"
              label="Code"
              id="codeID"
              v-uppercase
              v-model="newCodeForm.id"
              :error-messages="codeIDErrorMessage"
              @blur="validateCodeID(newCodeForm.id)"
            ></v-text-field>
          </td>
          <td align="center">
            <v-select
              :items="domains"
              v-model="newCodeForm.domainID"
              label="Domain ID"
              :error-messages="codeDomainErrorMessage"
              @blur="validateDomainID(newCodeForm.domainID)"
            ></v-select>
          </td>
          <td align="center">
            <v-btn @click="submit">Add Code</v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="js">
    import Vue from 'vue'
    import _ from 'lodash'
    import permissions from '../../permissions';

    Vue.directive('uppercase',
    {
      inserted: function(el, _, vnode) {
        el.addEventListener('input', async function(e) {
          e.target.value = e.target.value.toUpperCase()
          vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
        })
      }
    })

    export default  {
      name: 'code-list',
      props: ['offerID'],
      created() {
        this.$store.dispatch('getDomains');

        this.$store.dispatch('fetchRedemptionCodesForOffer', this.offerID)
        .then(() => {
          this.loadingCodes = false;
        })
      },
      data() {
        return {
          search: '',
          codeIDErrorMessage: [],
          codeDomainErrorMessage: [],
          loadingCodes: true,
          newCodeForm: {
            id: '',
            domainID: '',
          },
          headers: [
          { text: 'ID', align: 'center', value: 'id', sortable: false},
          { text: 'Domain ID', align: 'center', value: 'domainID', sortable: false},
          { text: 'Times Used', align: 'center', value: 'useCount', sortable: false},
          { text: '', align: 'center', value: 'id', sortable: false},
          ],
        }
      },
      methods: {
        deleteCode(codeID, domainID) {
          this.$store.dispatch('deleteRedemptionCodeForOffer', {
            codeID: codeID,
            domainID: domainID,
            offerID: this.offerID,
          })
        },
        validateCodeID(value) {
          let length = _.size(value)
          if (length > 25) {
            this.codeIDErrorMessage = ['Must bet less than 25 characters']
          } else if (length < 1) {
            this.codeIDErrorMessage = ['Required']
          } else {
            this.codeIDErrorMessage = []
          }
        },
        validateDomainID(value) {
          if (_.size(value) < 1) {
            this.codeDomainErrorMessage = ['Required']
          } else {
            this.codeDomainErrorMessage = []
          }
        },
        validateForm(newVal) {
          this.validateCodeID(newVal.id);
          this.validateDomainID(newVal.domainID)
        },
        submit() {
          this.validateForm(this.newCodeForm)
          if (this.formValid) {
            this.$store.dispatch('createRedemptionCodeForOffer', {
              offerID: this.offerID,
              codeDetails: _.cloneDeep(this.newCodeForm),
            }).then(() => {
              this.newCodeForm = {
                id: '',
                domainID: '',
              }
            })
          }
        }
      },
      computed: {
        formValid() {
          return (_.size(this.codeIDErrorMessage) + _.size(this.codeDomainErrorMessage)) === 0
        },
        codes() {
          return this.$store.getters.redemptionCurrentCustomCodes;
        },
        domains() {
          let allDomains = this.$store.getters.domains
          return _.filter(allDomains, (domain) => {
            let requiredPerm = {
              domainID: domain,
              resource: 'redemption',
              access: permissions.flags.create
            }
            return this.$store.getters.testPermission(requiredPerm);
          })
        },
      },
      watch: {
      }
  }
</script>

<style scoped lang="scss">
  .code-list {
  }
</style>
