<template>
  <div>
    <template v-if="this.readonly">
      <v-text-field
        v-model="utcTimeDisplay"
        :label="title"
        :outline="outline"
        readonly
      />
    </template>
    <template v-else>
      <v-dialog width="610" full-width>
        <v-text-field
          slot="activator"
          v-model="utcTimeDisplay"
          :label="title"
          :outline="outline"
          readonly
        />
        <v-card width="610">
          <v-container>
            <v-layout column>
              <v-flex style="padding-bottom: 0px">
                <div><h3>UTC time</h3></div>
              </v-flex>
              <v-flex row style="padding-top: 4px; padding-bottom: 4px">
                <v-date-picker v-model="datePart" />
                <v-time-picker v-model="timePart" format="24hr" width="272" />
              </v-flex>
              <v-flex style="padding-top: 0px; padding-left: 20px">
                <div>Local time: {{ rentonTimeDisplay }}</div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'DateTimePicker',

    props: {
      title: {
        type: String,
      },

      value: {
        type: String,
      },

      outline: {
        type: Boolean,
        default: true,
      },

      readonly: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dateFormatRegex: new RegExp(
        '^[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}(\.[\d]{3})?Z$'
      ),
      lazyValue: '2019-01-01T00:00:00Z',
    }),

    methods: {
      isISO8601ZFormat(val) {
        return dateFormatRegex.test(val);
      },

      emitInput() {
        this.$emit('input', this.lazyValue);
      },
    },

    computed: {
      internalValue: {
        get() {
          return this.lazyValue;
        },
        set(val) {
          // TODO: check against regex
          this.lazyValue = val;
        },
      },

      datePart: {
        get() {
          return this.lazyValue.slice(0, 10);
        },
        set(val) {
          this.internalValue = `${val}T${this.timePart}Z`;
          this.emitInput();
        },
      },

      timePart: {
        get() {
          return this.lazyValue.slice(11, 19);
        },
        set(val) {
          this.internalValue = `${this.datePart}T${val}:00Z`;
          this.emitInput();
        },
      },

      utcTimeDisplay: {
        get() {
          let currentVal = new Date(this.lazyValue);
          return currentVal.toUTCString().replace('GMT', 'UTC');
        },
      },

      rentonTimeDisplay: {
        get() {
          let currentVal = new Date(this.lazyValue);
          return currentVal.toLocaleString();
        },
      },
    },

    watch: {
      value(val) {
        // TODO: check against regex
        this.lazyValue = val;
      },
    },
  };
</script>
