<template>
  <div
    flex
    v-if="isLoading"
    style="
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #ddd;
      opacity: 0.3;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
    "
  >
    <p style="font-size: 3em; font-weight: bold; color: #fff">Loading...</p>
  </div>
</template>

<script>
  export default {
    name: 'genericLoadingScreen',

    props: {
      isLoading: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
