<template>
  <v-container>
    <h2>Hydra Client Management</h2>
    <br />
    <v-alert :value="oauthClient === undefined" type="warning"
      >Client does not have a corresponding OAuth client and one can be created
      below</v-alert
    >
    <v-alert :value="oauthClient != '' && !redirectURIMatches" type="error">
      Client redirect URI does not match Hydra client
    </v-alert>
    <br />
    <v-combobox
      v-model="OAuthTypes.selectedTokenEndpointAuthMethod"
      :items="OAuthOptions.tokenEndpointAuthMethods"
      label="Token Endpoint Authentication Method"
      chips
    >
    </v-combobox>
    <v-combobox
      v-model="OAuthTypes.selectedGrants"
      :items="OAuthOptions.grantTypes"
      label="Grant Types"
      multiple
      chips
      deletable-chips
    >
    </v-combobox>
    <v-combobox
      v-model="OAuthTypes.selectedResponses"
      :items="OAuthOptions.responseTypes"
      label="Response Types"
      multiple
      chips
      deletable-chips
    >
    </v-combobox>
    <v-combobox
      v-model="OAuthTypes.selectedScopes"
      :items="OAuthOptions.scopes"
      label="Scopes"
      multiple
      chips
      deletable-chips
    >
    </v-combobox>
    <v-combobox
      v-model="OAuthTypes.selectedRedirectURIs"
      :items="OAuthOptions.redirectURId"
      label="Redirect URIs"
      multiple
      chips
      deletable-chips
    >
    </v-combobox>
    <v-combobox
      v-model="OAuthTypes.selectedPostLogoutCallbacks"
      :items="OAuthOptions.postLogoutCallbacks1"
      label="Post-Logout Callbacks"
      multiple
      chips
      deletable-chips
    >
    </v-combobox>
    <v-btn v-if="oauthClient == ''" @click="updateHydraClient"
      >Create Hydra Client</v-btn
    >
    <v-btn v-if="oauthClient !== ''" @click="updateHydraClient"
      >Update Hydra Client</v-btn
    >

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-text>
          {{ this.dialogText }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';

  // Required token authentication method for secure clients. This gets set below when a secure client is detected
  const secureTokenAuthMethod = 'client_secret_basic';

  export default {
    name: 'HydraClientDetails',
    data() {
      return {
        // The currently selected OAuth types by the user. Default options have been hardcoded here. May require different defaults
        // TODO: These fields may need to be changed later.
        OAuthTypes: {
          selectedTokenEndpointAuthMethod: 'client_secret_basic',
          selectedGrants: ['authorization_code'],
          selectedResponses: ['code'],
          selectedScopes: [],
          selectedRedirectURIs: [],
          selectedPostLogoutCallbacks: [],
        },
        // The supported OAuth2 type options for the dropdowns. These get retrieved in the getOAuthSupportedTypes function below
        OAuthOptions: {
          tokenEndpointAuthMethods: [
            'client_secret_post',
            'client_secret_basic',
            'private_key_jwt',
            'none',
          ],
          grantTypes: [],
          responseTypes: [],
          scopes: [],
          redirectURIs: [],
          postLogoutCallbacks: [],
        },
        dialog: false,
        dialogText: '',
      };
    },
    props: ['clientID', 'oauthClient', 'clientIsSecure', 'redirectURIMatches'],
    mounted: function () {
      // Set default options first. This gets updated below if a hydra client exists
      // TODO: These fields may need to be changed later depending on the chosen fields. To be done in PLAT-3753
      const defaultClient = {
        TokenEndpointAuthMethod: this.OAuthTypes.tokenEndpointAuthMethod,
        GrantTypes: this.OAuthTypes.selectedGrants,
        ResponseTypes: this.OAuthTypes.selectedResponses,
        Scope: this.OAuthTypes.selectedScopes,
        RedirectURIs: [],
      };

      this.$store.commit('setHydraClient', defaultClient);
      this.getOAuthSupportedTypes();
    },
    watch: {
      // Sets the token auth method to 'secure' if this client is secure
      clientIsSecure: function (isSecure, _) {
        if (isSecure) {
          this.OAuthTypes.selectedTokenEndpointAuthMethod = secureTokenAuthMethod;
        }
      },
      // Watch for updates on the client itself
      oauthClient: function (newClient, _) {
        if (newClient) {
          this.OAuthTypes.selectedGrants = newClient.grantTypes;
          this.OAuthTypes.selectedResponses = newClient.responseTypes;
          this.OAuthTypes.selectedScopes = newClient.scope;
          this.OAuthTypes.selectedTokenEndpointAuthMethod =
            newClient.tokenEndpointAuthMethod;
        }
      },
      OAuthTypes: {
        handler() {
          let redirectURIs = [];

          if (this.oauthClient) {
            redirectURIs = this.oauthClient.redirectURIs;
          }

          const updatedClient = {
            GrantTypes: this.OAuthTypes.selectedGrants,
            ResponseTypes: this.OAuthTypes.selectedResponses,
            Scope: this.OAuthTypes.selectedScopes,
            TokenEndpointAuthMethod: this.OAuthTypes
              .selectedTokenEndpointAuthMethod,
            RedirectURIs: redirectURIs,
          };

          this.$store.commit('setHydraClient', updatedClient);
        },
        deep: true,
      },
    },
    methods: {
      // Retrieve all the supported types for each field in a Hydra client.
      getOAuthSupportedTypes() {
        // NOTE: Hydra changes in the adminservice are in a different branch, so this endpoint will not work until those changes
        // are merged. To be done in PLAT-3753
        const req = `${this.$store.getters.serviceURL}/admin/developer/oauth/types`;
        axios
          .get(req, this.$store.getters.bearerAuthHeaders)
          .then((response) => {
            const types = response.data;

            // These fields may need to be changed later depending on the exact fields we show to the user. To be done in PLAT-3753
            this.OAuthOptions.grantTypes = types.grant_types_supported;
            this.OAuthOptions.responseTypes = types.response_types_supported;
            this.OAuthOptions.scopes = types.scopes_supported;
            this.OAuthOptions.tokenEndpointAuthMethods =
              types.token_endpoint_auth_methods_supported;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      updateHydraClient() {
        this.dialogText = '';
        this.dialog = true;

        if (
          this.OAuthTypes.selectedGrants.length == 0 ||
          this.OAuthTypes.selectedResponses.length == 0 ||
          this.OAuthTypes.selectedScopes.length == 0 ||
          this.OAuthTypes.selectedTokenEndpointAuthMethod === ''
        ) {
          this.dialogText = 'Hydra client must have options for all fields';
          return;
        }

        if (
          this.clientIsSecure &&
          this.OAuthTypes.selectedTokenEndpointAuthMethod !=
            secureTokenAuthMethod
        ) {
          this.dialogText = `Secure client requires token endpoint auth method '${secureTokenAuthMethod}'`;
          return;
        }
        // NOTE: Hydra changes in the adminservice are in a different branch, so this endpoint will not work until those changes
        // are merged. To be done in PLAT-3753
        const url = `${this.$store.getters.serviceURL}/admin/developer/client/${this.clientID}/oauth`;
        let redirectURIs = [];

        if (this.oauthClient) {
          redirectURIs = this.oauthClient.redirectURIs;
        }

        // Pull latest changes to hydra client
        const updateOAuthClientReq = {
          GrantTypes: this.OAuthTypes.selectedGrants,
          RedirectURIs: redirectURIs,
          ResponseTypes: this.OAuthTypes.selectedResponses,
          Scope: this.OAuthTypes.selectedScopes,
          TokenEndpointAuthMethod: this.OAuthTypes
            .selectedTokenEndpointAuthMethod,
        };

        axios
          .put(url, updateOAuthClientReq, this.$store.getters.bearerAuthHeaders)
          .then(() => {
            this.dialogText = 'Successfully updated Hydra client.';
          })
          .catch((error) => {
            this.dialogText = 'Error updating Hydra client ' + error;
          });
      },
    },
  };
</script>
