<template>
  <v-flex>
    <h5 class="grey--text">Domain Roles</h5>
    <treeselect
      :multiple="true"
      :options="domainRoles"
      :normalizer="domainRolesNormalizer"
      :flat="true"
      :sortValueBy="sortBy"
      placeholder="Select domain roles."
      noOptionsText="Domain roles are undefined
    for this domain."
      v-model="domainRolesSelected"
    />
  </v-flex>
</template>

<script>
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import Treeselect from '@riophae/vue-treeselect';
  import axios from 'axios';

  export default {
    name: 'DomainRolesSelector',
    components: {
      Treeselect,
    },
    props: {
      domain: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        domainRoles: [],
        domainRolesSelected: [],
        sortBy: 'LEVEL',
        error: '',
      };
    },
    methods: {
      fetchDomainRoles(domain) {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/domain/' +
              domain +
              '/roles',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            if ('roles' in response.data) {
              this.error = '';
              let domainRoles = response.data.roles.map((role) => {
                return {
                  id: role.roleID,
                  tag: role.tag,
                  desc: role.description,
                };
              });
              domainRoles.sort((a, b) => {
                if (a.tag.toLowerCase() < b.tag.toLowerCase()) return -1;
                if (a.tag.toLowerCase() > b.tag.toLowerCase()) return 1;
                return 0;
              });
              let domainsSeen = [];
              let domainsUnique = [];

              for (let domainRole of domainRoles) {
                if (domainsSeen.indexOf(domainRole.id) < 0) {
                  domainsSeen.push(domainRole.id);
                  domainsUnique.push(domainRole);
                }
              }

              this.domainRoles = this.domainRoles.concat(domainsUnique);
            }
          })
          .catch((err) => {
            this.error = err.toString();
          });
      },
      domainRolesNormalizer(node) {
        return {
          id: node.id,
          label: node.tag,
        };
      },
    },
    watch: {
      domain(newDomain) {
        this.domainRoles = [];
        this.domainRolesSelected = [];

        if (newDomain) {
          this.fetchDomainRoles(newDomain);
        }
      },
      domainRolesSelected() {
        this.$emit('newRoles', this.domainRolesSelected);
      },
    },
  };
</script>

<style scoped></style>
