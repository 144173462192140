<template>
  <v-container fluid class="listContainer">
    <v-layout row justify-left align-baseline>
      <h2>{{ this.$props.title }}</h2>
      <v-spacer />
      <v-flex sm4 md4>
        <v-text-field
          label="Name search"
          append-icon="search"
          v-model="search"
          single-line
          hide-details
        />
      </v-flex>
    </v-layout>
    <v-layout justify-center class="tableContainer" d-flex>
      <v-data-table
        :headers="headers"
        :items="offers"
        :search="search"
        :must-sort="true"
        :rows-per-page-items="[10, 5, 25]"
        :loading="areOffersLoaded"
      >
        <template slot="no-data">
          <v-alert :value="true" color="info" icon="info">No offers</v-alert>
        </template>
        <template slot="items" slot-scope="props">
          <td>
            <router-link :to="'/codeRedemption/offers/' + props.item.id">
              {{ props.item.name }}
            </router-link>
          </td>
          <td>{{ props.item.description }}</td>
          <td>{{ props.item.startDate }}</td>
          <td>{{ props.item.endDate }}</td>
          <td>{{ props.item.id }}</td>
          <td v-show="show">
            <v-btn
              color="primary"
              :to="'/codeRedemption/generatecodes/' + props.item.id"
              :disabled="
                !permissionAllowed('coderedemption_codes', false, 'create')
              "
            >
              Generate Codes
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name: 'CodeRedemptionOffersView',
    inheritAttrs: false,

    props: {
      title: {
        type: String,
        required: true,
      },
      offers: {
        type: Array,
        required: true,
      },
      showButton: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        headers: [
          { text: 'Name', align: 'left', sortable: false, value: 'name' },
          {
            text: 'Description',
            align: 'left',
            value: 'description',
            sortable: false,
          },
          { text: 'Start Date', align: 'left', value: 'startDate' },
          { text: 'End Date', align: 'left', value: 'endDate' },
          { text: 'ID', align: 'left', value: 'id', sortable: false },
          { text: '', align: 'left', value: 'id', sortable: false },
        ],
        search: '',
      };
    },
    methods: {
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
    },
    computed: {
      areOffersLoaded() {
        return _.isNull(this.offers) || this.offers.length < 1;
      },
      loggedIn() {
        return this.$store.getters.authenticated;
      },
      show() {
        return this.$props.showButton;
      },
    },
  };
</script>

<style scoped>
  .listContainer {
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .tableContainer {
    padding-top: 10px;
  }
</style>
