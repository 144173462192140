<template lang="html">
  <v-container px-0 pt-2>
    <v-data-table
      :headers="batchHeaders"
      :items="batches"
      pagination.sync="pagination"
      :loading="loadingBatches"
    >
      <template v-slot:no-data>
        <v-alert icon="info" color="info" :value="true">
          No Batches Found
        </v-alert>
      </template>
      <template slot="items" slot-scope="props">
        <tr class="text-xs-center">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.tag }}</td>
          <td>{{ props.item.domainID }}</td>
          <td>{{ props.item.availableCodes }}</td>
          <td>{{ props.item.totalCodes }}</td>
          <td align="right">
            <ExportDialog
              :exportRequest="{ batchID: props.item.id }"
              exportType="Batch"
            />
          </td>
        </tr>
      </template>
      <template slot="footer">
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td align="right">
            <BatchCreation :offerID="this.offerID" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="js">
  import BatchCreation from './BatchCreation.vue';
  import ExportDialog from './ExportDialog.vue';
  export default  {
    name: 'batch-list',
    props: ['offerID'],
    components: {
      BatchCreation,
      ExportDialog,
    },
    created() {
      this.$store.dispatch('fetchRedemptionBatchesForOffer', this.offerID)
      .then(() => {
        this.loadingBatches = false;
      });
    },
    data() {
      return {
        loadingBatches: true,
        batchHeaders: [
        { text: 'ID', align: 'center', value: 'id', sortable: false},
        { text: 'Tag', align: 'center', value: 'tag', sortable: false },
        { text: 'Domain ID', align: 'center', value: 'domainID', sortable: false},
        { text: 'Available Codes', align: 'center', value: 'availableCodes', sortable: false},
        { text: 'Total Codes', align: 'center', value: 'totalCodes', sortable: false},
        { text: '', align: 'center', value:'id', sortable: false},
        ],
      }
    },
    methods: {

    },
    computed: {
      batches() {
        return this.$store.getters.redemptionCurrentBatches;
      }
    }
  }
</script>

<style scoped lang="scss">
  .batch-list {
  }
</style>
