<template>
  <div>
    <v-layout row justify-left align-center fill-height class="wotcContainer">
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <h2>Game Roles</h2>
        <v-data-table :headers="headers" :items="roles" hide-actions>
          <template slot="headers" slot-scope="props">
            <tr>
              <th>Persona ID</th>
              <th>Game ID</th>
              <th>Role Tag</th>
              <th>Description</th>
              <th>RoleID</th>
              <th>Assigned On</th>
              <th />
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.personaID }}</td>
              <td>{{ props.item.gameID }}</td>
              <td>{{ props.item.tag }}</td>
              <td>{{ props.item.description }}</td>
              <td>{{ props.item.roleID }}</td>
              <td>{{ props.item.assignedOn }}</td>
              <td align="right">
                <v-btn v-on:click="confirmRemove(props.item)">Remove</v-btn>
              </td>
            </tr>
          </template>
          <template slot="footer">
            <tr>
              <td colspan="2">
                <v-select
                  :disabled="this.isExternalPersona"
                  :items="accountGameIDs"
                  v-model="toAddGame"
                  label="Game ID"
                ></v-select>
              </td>
              <td colspan="2">
                <v-autocomplete
                  :items="allGameRoleTags"
                  v-model="toAddTag"
                  label="Role"
                ></v-autocomplete>
              </td>
              <td></td>
              <td align="right"><v-btn @click="confirmAdd">Add</v-btn></td>
            </tr>
          </template>
        </v-data-table>
        <v-alert v-model="hasError">{{ getError }}</v-alert>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'rolesForm',
    data() {
      return {
        headers: [
          { text: 'Persona ID' },
          { text: 'Game ID' },
          { text: 'Role tag' },
          { text: 'Description' },
          { text: 'RoleID' },
          { text: 'Assigned On' },
        ],
        allGames: [],
        allGameRoles: [],
        localRoles: [],
        toAddGame: '',
        toAddTag: '',
        error: '',
      };
    },
    created: function () {
      this.getGameRoles();
    },
    mounted: function () {
      if (this.isExternalPersona) {
        this.toAddGame = this.$store.getters.account.gameID;
      } else {
        let g = this.$store.getters.gameRoles.gameID;
        let obj = this.$store.getters.games.find((o) => o.gameID == g);
        if (obj) {
          this.toAddGame = g;
        }
      }
    },
    watch: {
      toAddGame: function (newGame) {
        if (newGame == this.$store.getters.gameRoles.gameID) {
          this.allGameRoles = this.$store.getters.gameRoles.roles;
        } else {
          axios
            .get(
              this.$store.getters.serviceURL +
                '/admin/accounts/game/' +
                this.toAddGame +
                '/roles',
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.allGameRoles = response.data.roles;
              this.$store.commit('setGameRoles', {
                gameID: newGame,
                roles: response.data.roles,
              });
              this.setError('');
            })
            .catch((err) => {
              this.setError('Failed to Add Role to Game: ' + err);
            });
        }
      },
    },
    methods: {
      getGameRoles() {
        // Should move this and Persona.vue's getPersona into store.
        if (!this.$store.getters.isExternalPersona) {
          let reqURL = `${this.$store.getters.serviceURL}/admin/accounts/account/${this.$store.getters.account.accountID}/personas`;
          if (typeof this.$store.getters.account.accountID != 'undefined') {
            axios
              .get(reqURL, this.$store.getters.bearerAuthHeaders)
              .then((response) => {
                this.$store.commit('setPersonas', response.data.personas);
                this.errorMsg = '';
                this.updateRoles();
              })
              .catch((err) => {
                this.errorMsg = err;
              });
          }
        }
        this.updateRoles();
      },
      confirmAdd() {
        this.$refs.confirm
          .open(
            'Confirm Add Role',
            'Are you sure you want to add ' +
              this.toAddTag +
              " to this account's roles for game " +
              this.toAddGame +
              '?'
          )
          .then((confirm) => {
            if (confirm) {
              this.add();
            } else {
              //no-op
            }
          });
      },
      confirmRemove(role) {
        this.$refs.confirm
          .open(
            'Confirm Remove Role',
            'Are you sure you want to remove ' +
              role.tag +
              " from this account's roles for game " +
              this.toAddGame +
              '?'
          )
          .then((confirm) => {
            if (confirm) {
              this.remove(role);
            } else {
              //no-op
            }
          });
      },
      updateRoles() {
        let personas = this.getPersonas;
        let gameRoles = [];
        personas.forEach((persona) => {
          var roles = persona.roles;
          if (!Array.isArray(roles)) {
            roles = [];
          }
          roles.forEach((x) => {
            x.personaID = persona.personaID;
          });
          gameRoles = gameRoles.concat(roles);
        });
        this.localRoles = gameRoles;
      },
      add() {
        var rID = '';
        var pID = '';
        this.allGameRoles.forEach((role) => {
          if (role.tag === this.toAddTag && role.gameID === this.toAddGame) {
            rID = role.roleID;
          }
        });
        this.getPersonas.forEach((persona) => {
          if (persona.gameID === this.toAddGame) {
            pID = persona.personaID;
          }
        });
        var data = JSON.stringify({
          updates: [
            {
              roleID: rID,
              action: 0,
            },
          ],
        });
        axios
          .put(
            this.$store.getters.personaURL + pID + '/roles',
            data,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('updateRoles', response.data);
            this.updateRoles();
            this.setError('');
          })
          .catch((err) => {
            this.setError('Failed to Add Role: ' + err);
          });
      },
      remove(role) {
        var data = JSON.stringify({
          updates: [
            {
              roleID: role.roleID,
              action: 1,
            },
          ],
        });
        axios
          .put(
            this.$store.getters.personaURL + role.personaID + '/roles',
            data,
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('updateRoles', response.data);
            this.updateRoles();
            this.setError('');
          })
          .catch((err) => {
            this.setError('Failed to Remove Role: ' + err);
          });
      },
      setError(err) {
        this.error = '' + err;
      },
    },
    computed: {
      getPersonas() {
        return this.$store.getters.personas;
      },
      hasError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      accountGameIDs() {
        var personas = this.getPersonas;
        if (!Array.isArray(personas)) {
          personas = [];
        }
        return personas.map((p) => p.gameID);
      },
      roles() {
        let roles = this.localRoles;
        const compare = (a, b) => {
          if (a.tag < b.tag) return -1;
          if (a.tag > b.tag) return 1;
          return 0;
        };
        return roles ? roles.sort(compare) : [];
      },

      allGameRoleTags() {
        return this.allGameRoles
          ? this.allGameRoles.map((r) => {
              return r.tag;
            })
          : [];
      },
      isExternalPersona() {
        return this.$store.getters.isExternalPersona;
      },
    },
  };
</script>
