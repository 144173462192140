import Vuex from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
Vue.use(Vuex);

const bulkChangeStore = {
  state: {},
  actions: {
    async createInvitation({ getters, dispatch, commit }, payload) {
      return dispatch('refreshIfNeeded').then((_) => {
        let invitationForm = {
          domainID: payload.domainID,
          domainRoles: payload.domainRoles,
          games: payload.games,
          remainingUses: payload.uses,
          expiration: moment().add(1, 'days').unix(),
        };
        return axios
          .put(
            getters.serviceURL + '/admin/invitations/',
            invitationForm,
            getters.bearerAuthHeaders
          )
          .then((response) => {
            if (response.data) {
              return response.data;
            }
            throw new Error('Failed to create invitation');
          })
          .catch((err) => {
            console.log(err);
            throw new Error('Failed to create invitation');
          });
      });
    },
    fetchBulkChangeStatus({ getters, dispatch }, payload) {
      let jobID = payload.jobID;
      if (!jobID) {
        return Promise.reject('Invalid job ID.');
      }
      return dispatch('refreshIfNeeded').then(() => {
        return axios.get(
          getters.serviceURL + `/admin/bulk/job/${jobID}`,
          getters.bearerAuthHeaders
        );
      });
    },
  },
};
export default bulkChangeStore;
