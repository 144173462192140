<template>
  <div>
    <v-container fluid grid-list-xl>
      <v-alert v-model="this.error">{{ this.error }}</v-alert>
      <v-layout row>
        <v-btn to="/codeRedemption">Back</v-btn>
        <v-spacer />
      </v-layout>
      <v-layout row justify-center>
        <v-flex sm6 md4 lg4>
          <v-text-field
            label="Quantity"
            :rules="[rules.min1value]"
            v-model="offerRequest.quantity"
            mask="#######"
          />
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-flex sm6 md4 lg4>
          <v-text-field
            label="Prefix (optional)"
            :rules="[rules.min4chars, rules.max9chars]"
            v-model="offerRequest.prefix"
          />
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-flex sm6 md4 lg4>
          <v-text-field label="Description (optional)" />
        </v-flex>
      </v-layout>

      <v-layout row justify-center fill-height>
        <v-flex sm5 md4 lg3 class="btnGenerateCodes">
          <v-btn
            color="primary"
            @click="generateCodes()"
            :disabled="
              !permissionAllowed('coderedemption_codes', false, 'create')
            "
          >
            Generate Codes
          </v-btn>
        </v-flex>
        <v-flex sm5 md4 lg3>
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="12"
            :value="value"
            color="#1976d2"
          >
            {{ value }}%
          </v-progress-circular>
        </v-flex>
      </v-layout>

      <v-layout :if="response" row justify-center fill-height>
        <v-flex sm4 md4 lg4>
          <pre>{{ response }}</pre>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import { setTimeout } from 'timers';
  import moment from 'moment';

  export default {
    name: 'CodeGeneration',

    props: {
      offerId: {
        type: String,
        default: null,
      },
      quantity: {
        type: Number,
        default: 0,
      },
    },

    data() {
      return {
        value: 0,
        offerRequest: {},
        offer: {},
        response: '',
        filesList: [],
        error: '',
        rules: {
          min1value: (v) => v > 0 || 'Must be at least 1',
          min4chars: (v) =>
            (v && v.length > 3) ||
            v == null ||
            v == '' ||
            'Too short, min 4 characters',
          max9chars: (v) =>
            (v && v.length <= 9) ||
            v == null ||
            v == '' ||
            'Too long, max 9 characters',
        },
      };
    },

    methods: {
      setError(err) {
        this.$data.error = '' + err;
      },

      generateCodes() {
        let quantity = this.offerRequest.quantity;
        if (!quantity) {
          this.setError('Quantity can not be empty!');
          return;
        }
        this.value = 0;
        this.response = null;
        let codesPerBatch = 1000;
        let noOfBatches = 0;
        let lastBatchCodeCount = 0;
        let start = 0;
        let cnt = 0;

        if (quantity <= 1000) {
          codesPerBatch = quantity;
          noOfBatches = 1;
        } else {
          noOfBatches = Math.ceil(quantity / 1000);
          lastBatchCodeCount = quantity % 1000;
        }
        start = lastBatchCodeCount > 0 ? 0 : 1;

        //prepare api request
        this.offer.offerId = this.offerId;
        this.offer.quantity = codesPerBatch;
        this.offer.prefix = this.offerRequest.prefix;

        this.queueCalls(this, noOfBatches, quantity, 1);
      },

      callAPI(ctx) {
        let queryOpts = {
          headers: {
            Authorization: 'Bearer ' + ctx.$store.getters.token,
          },
        };

        axios
          .post(
            this.$store.getters.serviceURL + '/coderedemption/codes/generate',
            ctx.$data.offer,
            queryOpts
          )
          .then((response) => {
            if (response.data.length == 0) {
              ctx.response =
                'Please contact James McGinley to get your codes (temporarily).';
            } else {
              ctx.response = response.data;
            }
            ctx.setError('');
          })
          .catch((err, resp) => {
            ctx.setError(err);
          });
      },

      queueCalls(ctx, noOfBatches, qty, tempCount) {
        let cnt = tempCount;

        setTimeout(function () {
          let noOfRecordsToProcess = 1000;

          if (qty < 1000) {
            noOfRecordsToProcess = qty;
          }

          ctx.offer.quantity = noOfRecordsToProcess;
          ctx.callAPI(ctx);
          ctx.value = Math.ceil((cnt / noOfBatches) * 100); // set proegress bar

          if (cnt < noOfBatches) {
            cnt++;
            noOfBatches--;
            ctx.queueCalls(ctx, noOfBatches + 1, qty - 1000, cnt);
          }
        }, 2000);
      },

      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
    },

    created() {
      if (!_.isNil(this.offerId)) {
        // this.getOffer(this.offerId)
      } else {
        this.readWrite = true;
      }
    },
    computed: {
      loggedIn() {
        return true;
      },
    },
  };
</script>

<style scoped>
  .btnGenerateCodes {
    padding-left: 190px !important;
    padding-top: 40px !important;
    margin-right: 20px;
  }
  .btnDownloadCodes {
    padding-left: 136px !important;
    padding-top: 40px !important;
  }

  .tableContainer {
    padding-top: 10px;
  }

  .filelist {
    padding: 10px !important;
  }

  .anchor {
    color: #ffc107;
  }

  .btnWidth {
    width: 200px !important;
    height: 50px !important;
  }
</style>
