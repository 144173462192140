<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex xs8 sm8 md8 lg8 box pa8 class="holder">
        <v-layout row justify-left align-center v-if="editing">
          <v-flex xs8 sm8 md8 lg8 box pa8>
            <v-text-field
              label="Display Name"
              type="text"
              :error="hasError"
              v-model="newDisplayName"
            ></v-text-field>
          </v-flex>
          <v-flex xs4 sm4 md4 lg4 box pa4>
            <v-text-field
              label="Hash"
              type="text"
              :error="hasError"
              v-model="hash"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <div v-else>
          <v-text-field
            label="Display Name"
            type="text"
            :error="hasError"
            v-model="newDisplayName"
            @focus="loadEdit()"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex xs3 sm3 md3 lg3>
        <v-btn :disabled="disableUpdate" @click="confirm">Update</v-btn>
        <a class="copy" @click="copy()">
          <v-icon title="Copy">content_copy</v-icon>
        </a>
        <input
          type="text"
          id="DisplayNameCopyValue"
          :value="initialDisplayName"
        />
      </v-flex>
      <v-flex xs1 sm1 md1 lg1 v-if="error">
        <v-icon :title="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1 v-else-if="success">
        <v-icon>check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'displayNameForm',
    data() {
      return {
        newDisplayName: '',
        initialDisplayName: '', // Record initial value in order to disable 'Update' button with no changes.
        hash: '',
        error: '',
        success: false,
        editing: false,
      };
    },
    mounted() {
      if (this.getDisplayName) {
        this.initialDisplayName = this.getDisplayName;
        this.newDisplayName = this.getDisplayName;
      }
    },
    watch: {
      getDisplayName: function () {
        this.newDisplayName = this.getDisplayName;
      },
    },
    methods: {
      loadEdit() {
        this.editing = true;
        let hashPos = this.newDisplayName.lastIndexOf('#');
        let firstPart = this.newDisplayName.substr(0, hashPos);
        let secondPart = this.newDisplayName.substr(
          hashPos + 1,
          this.newDisplayName.length
        );

        this.hash = secondPart;
        this.newDisplayName = firstPart;
      },
      unloadEdit() {
        let hashPos = this.initialDisplayName.lastIndexOf('#');
        let firstPart = this.initialDisplayName.substr(0, hashPos);

        if (this.newDisplayName == firstPart) {
          this.editing = false;
          this.newDisplayName = this.initialDisplayName;
        }
      },
      confirm() {
        const testName = this.newDisplayName;
        this.editing = false;
        this.newDisplayName = this.newDisplayName + '#' + this.hash;
        this.$refs.confirm
          .open(
            'Update Display Name',
            "Are you sure you want to update this account's display name?"
          )
          .then((confirm) => {
            if (confirm) {
              this.submit();
            } else {
              this.newDisplayName = this.initialDisplayName;
            }
          });
      },
      submit() {
        this.$store
          .dispatch({
            type: 'updateDisplayName',
            newDisplayName: this.newDisplayName,
          })
          .then(() => {
            this.success = true;
            this.error = '';
            this.initialDisplayName = this.newDisplayName;
            this.unloadEdit();
          })
          .catch((err) => {
            this.error = err;
            this.success = false;
            this.newDisplayName = this.initialDisplayName;
            this.unloadEdit();
          });
      },
      copy() {
        try {
          let target = document.getElementById('DisplayNameCopyValue');
          target.focus();
          target.select();
          document.execCommand('copy');
        } catch (err) {
          alert('Your browser does not support automatic copy-paste.', err);
        }
      },
    },
    computed: {
      getDisplayName() {
        return this.$store.getters.account.displayName;
      },
      disableUpdate() {
        // Protect against setting the display name to an empty string.
        return (
          this.initialDisplayName == this.newDisplayName ||
          this.initialDisplayName == this.newDisplayName + '#' + this.hash ||
          _.isEmpty(this.newDisplayName) ||
          _.isEmpty(this.hash)
        );
      },
      hasError() {
        return this.error.length > 0;
      },
    },
  };
</script>

<style scoped>
  .holder {
    position: relative;
  }
  #DisplayNameCopyValue {
    position: absolute;
    left: -9999px;
  }
  .copy {
    position: relative;
    top: 4px;
  }
</style>
