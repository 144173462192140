<template>
  <div>
    <v-container v-if="!fuzzySearchPopulated">
      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <v-card max-width="500px" elevation="0" color="rgba(0,0,0,0)">
          <div>
            <form @submit.prevent="lookup">
              <v-layout justify-center>
                <h2>{{ this.title }}</h2>
              </v-layout>
              <br />
              <v-layout justify-center>
                <p class="buttonList">
                  <v-btn
                    small
                    v-for="method of findByMethods"
                    :color="isSelected(method)"
                    :key="method"
                    @click="setFindBy(method)"
                  >
                    {{ method }}
                  </v-btn>
                </p>
              </v-layout>
              <DomainsSelector
                @domainsSelected="
                  (newDomain) => {
                    domain = newDomain;
                  }
                "
              />
              <br />
              <v-text-field
                :label="findBy"
                type="text"
                :placeholder="getPlaceholder"
                v-model="identifier"
                @keyup.enter.native="lookup"
              ></v-text-field>
              <p v-if="isWildcardable" class="grey--text lighten-1">
                <em>
                  Enter
                  <b>*</b>
                  or partial search to search by wildcard; otherwise it will
                  expect a close match.
                </em>
              </p>
              <v-layout justify-center>
                <v-btn color="primary" @click="lookup(false)">{{
                  button
                }}</v-btn>
              </v-layout>
              <p :if="isError">
                <br />
                <v-alert :value="isError" type="error">{{ getError }}</v-alert>
              </p>
              <br />
            </form>
          </div>
        </v-card>
      </v-layout>
    </v-container>
    <v-container v-else>
      <v-layout
        row
        justify-center
        align-center
        fill-height
        class="wotcContainer"
      >
        <v-flex class="search-area" xs12 sm12 md12 lg12 box pa12>
          <FuzzyResultsTable :mode="mode" :domainID="domain" />
          <div class="closer">
            <v-btn @click="clearSearch()" flat title="Return to Search">
              &times;
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import FuzzyResultsTable from './FuzzyResultsTable.vue';
  import DomainsSelector from '../bulkchanges/DomainsSelector.vue';

  export default {
    name: 'friendshipSearchForm',
    props: {
      type: {
        default: null,
      },
      title: {
        type: String,
        default: "Lookup an Account's Friendships",
      },
      mode: {
        type: String,
        default: 'query',
      },
      accountID: {
        type: String,
      },
      button: {
        type: String,
        default: 'Lookup',
      },
    },
    components: {
      DomainsSelector,
      FuzzyResultsTable,
    },
    data() {
      return {
        error: '',
        domain: '',
        findByMethods: ['Email', 'Display Name', 'Account ID', 'Persona ID'],
        findByPlaceholder: {
          Email: '*wizards.com',
          'Display Name': 'drizzt#12345',
          'Account ID': '12345AZXH3SWFEVFQ7TPGK73D4',
          'Persona ID': '123453XJHCTHBH55MT2X2YTL7Y',
        },
        findBy: 'Email',
        identifier: '',
      };
    },
    methods: {
      getByEmailURL(elasticSearchOn) {
        if (elasticSearchOn || this.identifier.indexOf('*') != -1) {
          return (
            this.$store.getters.serviceURL +
            '/elasticsearch-proxy/email/search?domain_id=' +
            encodeURIComponent(this.domain) +
            '&term=' +
            encodeURIComponent(this.identifier)
          );
        } else {
          this.exactMatch = true;
          return (
            this.$store.getters.serviceURL +
            '/admin/accounts/domain/' +
            this.domain +
            '/email/' +
            encodeURIComponent(this.identifier)
          );
        }
      },
      getByDisplayNameURL(elasticSearchOn) {
        if (elasticSearchOn || this.identifier.indexOf('*') != -1) {
          return (
            this.$store.getters.serviceURL +
            '/elasticsearch-proxy/display_name/search?domain_id=' +
            encodeURIComponent(this.domain) +
            '&term=' +
            encodeURIComponent(this.identifier)
          );
        } else {
          this.exactMatch = true;
          return (
            this.$store.getters.serviceURL +
            '/admin/accounts/domain/' +
            this.domain +
            '/displayName/' +
            encodeURIComponent(this.identifier) +
            '/user'
          );
        }
      },
      lookup(elasticSearchOn) {
        if (this.domain == '') {
          this.setError('Must select a domain ID!');
          return;
        }

        if (this.identifier.length == 0) {
          this.setError('Must specify an account ID!');
          return;
        }

        if (this.identifier.indexOf('*') != -1) {
          elasticSearchOn = true;
        }

        var url = '';

        if (this.findBy === 'Email') {
          url = this.getByEmailURL(elasticSearchOn);
        } else if (this.findBy === 'Display Name') {
          url = this.getByDisplayNameURL(elasticSearchOn);
        } else if (this.findBy === 'Account ID') {
          this.doActionWithAccount(this.identifier);
          return;
        } else if (this.findBy === 'Persona ID') {
          this.lookupFriendsByPersonaID();
          return;
        }

        axios
          .get(url, this.$store.getters.bearerAuthHeaders)
          .then((response) => {
            this.setError('');
            if (elasticSearchOn) {
              if ('hits' in response.data && 'hits' in response.data.hits) {
                let results = [];
                if (response.data.hits.hits.length == 0) {
                  this.setError('No results found...');
                } else {
                  results = response.data.hits.hits.map((obj, i) => {
                    return { score: i + 1, source: obj._source };
                  });
                }
                if (results.length == 1) {
                  this.doActionWithAccount(results[0].source.account_id);
                } else {
                  this.$store.commit('setFuzzyAccountSearchResults', results);
                }
              }
            } else {
              var accountID = '';
              if (this.findBy == 'Email') {
                accountID = response.data.accountID;
              } else if (this.findBy == 'Display Name') {
                accountID = response.data.User.Account.account.accountID;
              }
              this.doActionWithAccount(accountID);
            }
          })
          .catch((err) => {
            if (err.toString().indexOf('404') != -1) {
              if (!elasticSearchOn) {
                this.lookup(true); // force another run thru with fuzzy search if 404 on normal service
              } else {
                this.setError('No results found...');
              }
            } else {
              this.setError('Failed Lookup: ' + err);
            }
          });
      },
      lookupFriendsByAccountID(accountID) {
        this.setError('');

        let url =
          this.$store.getters.serviceURL +
          '/admin/accounts/account/' +
          accountID;

        axios
          .get(url, this.$store.getters.bearerAuthHeaders)
          .then(() => {
            this.setError('');

            this.$store
              .dispatch('fetchFriendships', accountID)
              .then(() => {
                this.setError('');
              })
              .catch((err) => {
                if (err.toString().indexOf('404') != -1) {
                  this.setError('No results found...');
                } else {
                  this.setError('Failed query: ' + err);
                }
              });

            // Go to a new page that will display all friendships for a given account ID
            this.$router.push('/social/' + accountID);
          })
          .catch((err) => {
            if (err.toString().indexOf('404') != -1) {
              this.setError('No results found from given account ID...');
            } else {
              this.setError('Failed to lookup friendships!');
            }
          });
      },
      lookupFriendsByPersonaID() {
        let url =
          this.$store.getters.serviceURL +
          '/admin/accounts/user/persona/' +
          this.identifier;

        axios
          .get(url, this.$store.getters.bearerAuthHeaders)
          .then((resp) => {
            // Retrieve the associated account ID from the persona ID then get friends with the account ID
            let accountID = resp.data.User.Account.account.accountID;
            this.doActionWithAccount(accountID);
          })
          .catch((err) => {
            if (err.toString().indexOf('404') != -1) {
              this.setError('No results found from given persona ID...');
            } else {
              this.setError('Failed to lookup friendships!');
            }
          });
      },
      createFriendship(friendAccountID) {
        // For exact search only; fuzzy search goes to the fuzzy results table
        let currFriendsList = this.$store.getters.friendshipResults;

        // Check if friend exists in the user's list already
        if (
          currFriendsList.some((e) => e.friendAccountID === friendAccountID)
        ) {
          this.setError(
            'Friend exists already in ' + this.accountID + "'s friend list!"
          );
          return;
        }

        // Make sure you can't create friendship with self
        if (friendAccountID == this.accountID) {
          this.setError('Cannot create friendship with self!');
          return;
        }

        // Create friend
        let url =
          this.$store.getters.serviceURL +
          '/presence/admin/account/' +
          this.accountID +
          '/friends/create';

        let newFriendship = {
          DomainID: this.domain,
          AccountID: this.accountID,
          friendAccountID: friendAccountID,
        };

        axios
          .post(url, newFriendship, this.$store.getters.bearerAuthHeaders)
          .then(() => {
            this.setError('');
            this.$store.dispatch('fetchFriendships', this.accountID);
            this.$emit('close-dialog-create');
          })
          .catch((err) => {
            if (err.toString().indexOf('400') != -1) {
              this.setError(
                'Invalid account ID, friend account ID, or empty domain ID!'
              );
            } else {
              this.setError('Failed to create friendship.');
            }
          });
      },
      doActionWithAccount(accountID) {
        // This component is used for both looking up an accountID to query friendships for
        // and also for looking up friendships to create
        if (this.mode == 'query') {
          this.lookupFriendsByAccountID(accountID);
        } else if (this.mode == 'create') {
          this.createFriendship(accountID);
        }
      },
      setError(err) {
        this.error = '' + err;
      },
      isSelected(option) {
        if (option === this.findBy) {
          return 'blue-grey darken-3';
        }
      },
      setFindBy(option) {
        this.findBy = option;
      },
      clearSearch() {
        this.identifier = '';
        this.domain = '';
        this.$store.commit('setFuzzyAccountSearchResults', []);
      },
    },
    mounted: function () {
      this.setError('');
      this.clearSearch();
      this.domain = '';
      this.findBy = 'Email';
    },
    computed: {
      isError() {
        return this.error.length > 0;
      },
      getError() {
        return this.error;
      },
      getPlaceholder() {
        return this.findByPlaceholder[this.findBy];
      },
      isWildcardable() {
        return this.findBy === 'Email' || this.findBy === 'Display Name';
      },
      fuzzySearchPopulated() {
        return this.$store.getters.fuzzyAccountSearchResults.length > 0;
      },
    },
  };
</script>

<style scoped>
  .buttonList button:first-child {
    margin-left: 0;
  }
  .search-area {
    position: relative;
  }
  .closer {
    position: absolute;
    top: 0;
    right: 0;
  }
  .closer button {
    min-width: 38px;
    padding: 0;
    margin: 0;
    font-size: 30px;
    font-weight: normal;
  }
</style>
