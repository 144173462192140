<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex xs8 sm8 md8 lg8 box pa8>
        <v-text-field
          label="Last Name"
          type="text"
          :error="error"
          :success="success"
          v-model="newLastName"
          :maxlength="maxNameLength"
        ></v-text-field>
      </v-flex>
      <v-flex xs2 sm2 md2 lg2>
        <v-btn :disabled="disableUpdate" @click="confirm">Update</v-btn>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="success">check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';

  export default {
    name: 'lastNameForm',
    data() {
      return {
        newLastName: '',
        initialLastName: '',
        error: false,
        success: false,
        maxNameLength: 255,
      };
    },
    mounted() {
      this.getLastName;
    },
    watch: {
      getLastName: function () {
        this.newLastName = this.getLastName;
        // without this here the initialLastName is showing as blank
        // on the computed disable check
        this.initialLastName = this.getLastName;

        // Odd case on refresh -- initial retrieval would set initial values to undefined, and then update newLastName
        // again, leaving initialLastName as undefined.
        // However, some accounts can be created without a first or last name field, so just ignore otherwise.
        if (
          _.isUndefined(this.initialLastName) &&
          !_.isUndefined(this.newLastName)
        )
          this.initialLastName = this.getLastName;
      },
    },
    methods: {
      confirm() {
        this.$refs.confirm
          .open(
            'Update Last Name',
            "Are you sure you want to update this account's last name?"
          )
          .then((confirm) => {
            if (confirm) {
              this.submit(confirm);
            } else {
              this.newLastName = this.getLastName;
            }
          });
      },
      submit(confirm) {
        axios
          .put(
            this.$store.getters.serviceURL + '/profile/admin/' + this.accountID,
            { lastName: this.newLastName },
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            this.$store.commit('setLastName', response.data.lastName);
            this.initialLastName = this.newLastName;
            this.error = false;
          })
          .catch((err) => {
            this.newLastName = this.getLastName;
            console.error(err);
            this.error = true;
          });
      },
    },
    computed: {
      getError() {
        return this.error;
      },
      accountID() {
        return this.$store.getters.account.accountID;
      },
      getLastName() {
        return this.$store.getters.userProfile.lastName;
      },
      disableUpdate() {
        return (
          this.initialLastName === this.newLastName ||
          _.isEmpty(this.newLastName)
        );
      },
    },
  };
</script>
