<template lang="html">
  <div>
    <v-card>
      <v-sheet
        elevation="5"
        color="gray"
        class="xs-2 pa-2 ma-2"
        v-for="(grant, index) in grants"
        v-bind:key="index"
      >
        <v-layout row justify-center>
          <v-spacer xs2 sm2 md2 lg2 box pa2></v-spacer>
          <v-flex xs3 sm3 md3 lg3 box pa3>
            <v-select
              :items="allowedGrantTypes"
              v-model="grant.type"
              label="Type"
              :disabled="disableChangeGrantType"
            ></v-select>
          </v-flex>
          <v-spacer xs2 sm2 md2 lg2 box pa2></v-spacer>
          <v-flex xs3 sm3 md3 lg3 box pa3>
            <v-text-field label="Value" v-model="grant.value"></v-text-field>
          </v-flex>
          <v-spacer xs2 sm2 md2 lg2 box pa2></v-spacer>
          <v-flex xs3 sm3 md3 lg3 box pa3>
            <v-text-field label="Game ID" v-model="grant.gameID"></v-text-field>
          </v-flex>
          <v-flex xs2 sm2 md2 lg2 box pt-3 px-2>
            <v-btn color="red" @click="onRemove(index)">Remove</v-btn>
          </v-flex>
        </v-layout>
      </v-sheet>
      <v-layout row justify-center>
        <v-spacer xs2 sm2 md2 lg2 box pa2></v-spacer>
        <v-flex xs3 sm3 md3 lg3 box pa3>
          <v-select
            :items="allowedGrantTypes"
            v-model="newGrant.type"
            label="Type"
            :disabled="disableChangeGrantType"
          ></v-select>
        </v-flex>
        <v-spacer xs2 sm2 md2 lg2 box pa2></v-spacer>
        <v-flex xs3 sm3 md3 lg3 box pa3>
          <v-text-field label="Value" v-model="newGrant.value"></v-text-field>
        </v-flex>
        <v-spacer xs2 sm2 md2 lg2 box pa2></v-spacer>
        <v-flex xs3 sm3 md3 lg3 box pa3>
          <v-text-field
            label="Game ID"
            v-model="newGrant.gameID"
          ></v-text-field>
        </v-flex>
        <v-flex xs2 sm2 md2 lg2 box pt-3 px-2>
          <v-btn @click="emitGrant">Add</v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script lang="js">
  export default  {
    name: 'grant-list',
    props: ['grants', 'onRemove'],
    mounted() {

    },
    data() {
      return {
        allowedGrantTypes: ['product'],
        newGrant: {
          type: 'product',
          value: '',
          gameID: '',
        }
      }
    },
    methods: {
      emitGrant() {
        let newGrant = {
          type: this.newGrant.type,
          value: this.newGrant.value,
          gameID: this.newGrant.gameID
        };
        this.$emit('addGrant', newGrant);
        this.newGrant = {
          type: 'product',
          value: '',
          gameID: '',
        };
      },
    },
    computed: {
      disableChangeGrantType() {
        return _.size(this.allowedGrantTypes) <= 1
      }
    }
  }
</script>

<style scoped lang="scss">
  .grant-list {
  }
</style>
