<template>
  <v-flex>
    <h5 class="grey--text">Games / Game Roles</h5>
    <treeselect
      ref="selectGames"
      :value-consists-of="allNodes"
      :multiple="true"
      :flat="true"
      :options="gameTree"
      :loadOptions="loadGameOptions"
      :sort-value-by="sortByParent"
      placeholder="Select games and their roles."
      noOptionsText="Games are undefined for this domain."
      noChildrenText="Roles are undefined for this game."
      v-model="selectedGamesAndRoles"
    >
      <div
        slot="value-label"
        slot-scope="{ node }"
        :class="{ 'treeselect-game': !node.isLeaf }"
      >
        <span v-if="node.isLeaf" class="treeselect-role-prefix">
          {{ node.raw.gameID }}:
        </span>
        {{ node.label }}
      </div>
    </treeselect>
  </v-flex>
</template>

<script>
  import Treeselect, { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import axios from 'axios';

  export default {
    name: 'GameRolesSelector',
    components: {
      Treeselect,
    },
    props: {
      domainsSelected: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        gameTree: [],
        error: '',
        sortByParent: 'LEVEL',
        allNodes: 'ALL',
        selectedGamesAndRoles: [],
      };
    },
    methods: {
      fetchGames(domain) {
        axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/domain/' +
              domain +
              '/game',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            if ('games' in response.data) {
              this.error = '';
              this.gameTree = response.data.games.map((game) => {
                return { id: game.gameID, label: game.gameID, children: null };
              });
              this.gameTree.sort((a, b) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                return 0;
              });
            } else {
              this.gameTree = [];
            }
          })
          .catch((err) => {
            this.error = err.toString();
          });
      },
      async loadGameOptions({ action, parentNode }) {
        if (action === LOAD_CHILDREN_OPTIONS) {
          try {
            // make call to fetch game roles for the selected game
            let roles = await this.fetchGameRoles(parentNode.id);
            parentNode.children = [];
            this.error = '';

            //put the roles in the correct format for the children of checkbox
            roles.forEach((role) => {
              parentNode.children.push({
                id: role.roleID,
                label: role.tag,
                gameID: parentNode.id,
              });
            });
          } catch (err) {
            this.error = err.toString();
          }
        }
      },
      async fetchGameRoles(game) {
        return axios
          .get(
            this.$store.getters.serviceURL +
              '/admin/accounts/game/' +
              game +
              '/roles',
            this.$store.getters.bearerAuthHeaders
          )
          .then((response) => {
            if ('roles' in response.data) {
              return response.data.roles;
            }
            return [];
          });
      },
    },
    watch: {
      domainsSelected: function (newDomain) {
        this.error = '';

        this.domainRoles = [];
        this.selectedGamesAndRoles = [];
        this.$emit('gameRoleUpdate', []);

        if (newDomain) {
          this.fetchGames(newDomain);
        }
      },
      selectedGamesAndRoles(newValue, oldValue) {
        let added = newValue.filter((i) => {
          return oldValue.indexOf(i) < 0;
        });
        let removed = oldValue.filter((i) => {
          return newValue.indexOf(i) < 0;
        });
        const treeselect = this.$refs.selectGames;
        let changeCount = 0;

        // don't need to do anything if nothing was changed
        if (added.length < 1 && removed.length < 1) return;

        // if a game was removed, remove any game roles associated with it as well
        if (removed.length > 0) {
          const newValue = this.selectedGamesAndRoles.filter((id) => {
            const node = treeselect.getNode(id);
            if (
              node.isLeaf &&
              !this.selectedGamesAndRoles.includes(node.parentNode.id)
            ) {
              changeCount++;
              return false;
            }
            return true;
          });
          // Only assign new value when there was a change made
          // Otherwise we could run into infinite loop
          if (changeCount > 0) this.selectedGamesAndRoles = newValue;
        } else if (added.length > 0) {
          // if a game role was added, ensure that the game is also included
          added.forEach((id) => {
            const node = treeselect.getNode(id);
            if (
              node.isLeaf &&
              !this.selectedGamesAndRoles.includes(node.parentNode.id)
            ) {
              // put the parent node (game) in front of the leaf node (game role) in our array
              this.selectedGamesAndRoles.splice(
                this.selectedGamesAndRoles.indexOf(id),
                0,
                node.parentNode.id
              );
            }
          });
        }

        // output an array of game objects with associated game roles
        let games = [];
        this.selectedGamesAndRoles.forEach((id) => {
          const node = treeselect.getNode(id);
          if (node.isLeaf) {
            let lastIndex = games.length - 1;
            games[lastIndex].Roles = games[lastIndex].Roles.concat(id);
          } else {
            games = games.concat({
              Id: id,
              Roles: [],
              PreventDefaultRoles: true,
            });
          }
        });
        this.$emit('gameRoleUpdate', games);
      },
    },
  };
</script>

<style>
  .treeselect-game {
    padding: 4px;
  }
  .treeselect-role-prefix {
    font-size: 80%;
    opacity: 0.5;
  }
  .vue-treeselect__control {
    background: #303030;
    outline-color: #303030;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
    height: 16px;
    line-height: 16px;
    position: relative;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding-left: 0px;
  }
  .vue-treeselect__input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 16px;
  }
  .vue-treeselect__input-container {
    padding: 8px 8px !important;
  }
  .vue-treeselect__placeholder {
    padding-left: 0px;
  }
  .vue-treeselect__option {
    padding: 8px 8px;
  }
  .vue-treeselect__input-container {
    padding: 4px 0px 8px 0px !important;
  }
  .vue-treeselect__option:hover {
    background: #5a5a5a;
  }
  .vue-treeselect__option--highlight {
    background: inherit;
  }
  .vue-treeselect__option--selected {
    background: #424242 !important;
    color: #1976d2;
    font-weight: inherit !important;
  }
  .vue-treeselect__option--selected:hover {
    background: #5a5a5a !important;
  }
  .vue-treeselect__single-value {
    color: inherit;
  }
  .vue-treeselect__menu {
    background: #424242;
    border-width: 0px;
    font-size: 16px;
    font-weight: 400;
  }
  .vue-treeselect__multi-value-item {
    background: #b3d4fc;
    color: #000;
  }
  .vue-treeselect__multi-value-item-container {
    vertical-align: middle;
  }
  .vue-treeselect__icon {
    color: #000;
  }
  .vue-treeselect__checkbox--checked {
    background: #1976d2;
    border-color: #1976d2;
  }
</style>
