<template>
  <div>
    <h2>Personas</h2>
    <v-layout row justify-left align-center fill-height>
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <v-data-table
          item-key="personaID"
          :items="personas"
          hide-actions
          expand
          :headers-length="2"
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th style="text-align: left">Game ID</th>
              <th style="text-align: left">Created</th>
              <th>
                <v-layout row align-center>
                  <v-flex xs2 sm2 md2 lg2 box style="text-align: right">
                    Persona ID
                  </v-flex>
                  <v-spacer />
                  <v-flex xs3 sm3 md3 lg3 box>
                    <v-btn
                      v-on:click="loadPersonas()"
                      color="grey darken-3"
                      title="Reload Personas"
                    >
                      <v-icon>cached</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr @click="setSelectedPersona(props)">
              <td style="text-align: left">{{ props.item.gameID }}</td>
              <td style="text-align: left">{{ props.item.createdAt }}</td>
              <td>
                <v-layout row justify-left align-center fill-height>
                  <v-flex>{{ props.item.personaID }}</v-flex>
                  <v-icon v-if="!props.expanded">arrow_drop_down</v-icon>
                  <v-icon v-else>arrow_drop_up</v-icon>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="conflicts[props.item.personaID]"
                        v-bind="attrs"
                        v-on="on"
                        color="orange darken-3"
                      >
                        mdi-alert-octagon
                      </v-icon>
                    </template>
                    <span>
                      This persona is in a conflicted state. Expand the drop
                      down for details.
                    </span>
                  </v-tooltip>
                </v-layout>
              </td>
            </tr>
          </template>
          <template v-slot:expand="props">
            <v-container pl-5 xs6 sm6 md6 lg6 box pa6>
              <!-- CONFLICTS -->
              <v-layout class="pb-4" xs6 sm6 md6 lg6 box pa6>
                <div v-if="conflicts[props.item.personaID]">
                  <h3>THIS PERSONA IS IN CONFLICT</h3>
                  <p>
                    Persona with ID
                    {{ conflictInfo.selectedPersona.personaID }} is in conflict
                    with a
                    {{ conflictInfo.conflictingPersona.externalPlatform }}
                    persona with ID
                    <a
                      @click.prevent.stop="
                        viewExternalPersona(
                          conflictInfo.conflictingPersona.personaID
                        )
                      "
                      >{{ conflictInfo.conflictingPersona.personaID }} </a
                    >. Click the button below to choose which persona to keep.
                  </p>
                  <template>
                    <v-btn @click.stop="showDialog = true">
                      Resolve Conflict
                    </v-btn>
                    <v-dialog v-model="showDialog" max-width="700">
                      <v-card>
                        <v-card-title class="headline">
                          Resolve Conflicting Personas
                        </v-card-title>
                        <v-card-text>
                          Choose which persona to keep. The chosen persona will
                          be preserved and attached to the account. The other
                          will be deleted.
                        </v-card-text>
                        <v-container fluid>
                          <v-layout>
                            <v-flex
                              v-for="info in conflicts[props.item.personaID]"
                              :key="info.personaID"
                              xs6
                              sm6
                              md6
                              lg6
                              box
                            >
                              <v-card
                                class="pa-3 ma-1 fill-height conflict-card"
                              >
                                <h4>Persona ID</h4>
                                <p>{{ info.personaID }}</p>
                                <h4>Created At</h4>
                                <p>{{ info.createdAt }}</p>
                                <h4>Number of Entitlements</h4>
                                <p>{{ info.entitlementCount }}</p>
                                <h4 v-if="info.externalPlatform">
                                  External Platform
                                </h4>
                                <p v-if="info.externalPlatform">
                                  {{ info.externalPlatform }}
                                </p>
                                <v-card-actions class="card-actions">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    @click="
                                      confirmResolveConflict(
                                        info.personaID,
                                        info.externalPlatform,
                                        props.item.gameID
                                      )
                                    "
                                  >
                                    Select
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-container>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="showDialog = false">Cancel</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="showConfirmDialog" max-width="500">
                      <v-card>
                        <v-card-title class="headline">
                          Are you sure?
                        </v-card-title>
                        <v-card-text>{{ getConfirmationMessage }}</v-card-text>
                        <v-card-actions>
                          <v-btn text @click="resolveConflict">Confirm</v-btn>
                          <v-btn text @click="cancelResolveConflict">
                            Cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </div>
              </v-layout>
            </v-container>
            <hr style="border-bottom: 1px rgb(187, 187, 187)" />
          </template>
          <template slot="footer">
            <tr>
              <td>
                <v-select
                  :items="gameIDs"
                  v-model="toAddGame"
                  :label="
                    disabled ? 'User has a persona in all games' : 'Game ID'
                  "
                  :error="error"
                  :disabled="disabled"
                />
              </td>
              <td><v-btn @click="confirmCreate">Add</v-btn></td>
            </tr>
          </template>
        </v-data-table>
        <v-alert v-model="hasError">{{ getError }}</v-alert>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm" />
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';

  export default {
    name: 'personas',
    data() {
      return {
        toAddGame: '',
        error: false,
        errorMsg: '',
        disabled: false,
        conflicts: {},
        showDialog: false,
        showConfirmDialog: false,
        conflictInfo: {
          selectedPersona: {},
          conflictingPersona: {},
          chosenID: '',
          game: '',
          personaType: '',
        },
      };
    },
    watch: {
      toAddGame: function () {
        this.error = false;
      },
      accountID: function () {
        this.loadPersonas();
      },
    },
    created: function () {
      this.loadPersonas();
    },
    methods: {
      loadPersonas() {
        let reqURL = `${this.$store.getters.serviceURL}/admin/accounts/account/${this.$store.getters.account.accountID}/personas`;

        if (typeof this.$store.getters.account.accountID != 'undefined') {
          axios
            .get(reqURL, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.$store.commit('setPersonas', response.data.personas);
              this.errorMsg = '';
              // once we have all the personas, see if we have any conflicts
              if (response.data.personas) {
                this.getConflictInfo(
                  response.data.personas.map((p) => p.personaID)
                );
              }
            })
            .catch((err) => {
              this.errorMsg = err;
            });
        }
      },
      getConflictInfo(personaIDs) {
        this.$store
          .dispatch('getConflictsForPlayer', {
            personaIDs: personaIDs,
          })
          .then((result) => {
            this.conflicts = result;
          })
          .catch((err) => {
            this.errorMsg = err;
          });
      },
      getTags(roles) {
        if (!Array.isArray(roles)) {
          return [];
        }
        return roles.map((r) => r.tag).join(',');
      },
      confirmCreate() {
        if (_.isEmpty(this.toAddGame)) {
          this.error = true;
          return;
        } else {
          this.error = false;
        }

        let confirmText = `Creating a persona will prevent the user from linking an anonymous account to their Wizards Account for the "${this.toAddGame}" game. Are you sure you want to continue?`;
        this.$refs.confirm
          .open('Confirm Create Persona', confirmText)
          .then((confirm) => {
            if (confirm) {
              this.add();
            } else {
              //no-op
            }
          });
      },
      add() {
        let acct = this.$store.getters.account;
        let reqData = JSON.stringify({
          accountID: this.$store.getters.accountID,
          gameID: this.toAddGame,
          domainID: acct.domainID,
        });

        let reqURL = `${this.$store.getters.serviceURL}/admin/accounts/domain/${acct.domainID}/account/${acct.accountID}/game/${this.toAddGame}/persona`;

        axios
          .post(reqURL, reqData, this.$store.getters.bearerAuthHeaders)
          .then((response) => {
            this.$store.commit('addPersona', response.data);
            this.errorMsg = '';
          })
          .catch((err) => {
            this.errorMsg = err;
          });
      },
      getConflictMessage(persona) {
        let conflictInfo = this.conflicts[persona];
        let conflictingPersona =
          conflictInfo[0].personaID === persona
            ? conflictInfo[1]
            : conflictInfo[0];

        return (
          `Persona with ID ${persona} is in conflict with a ${conflictingPersona.externalPlatform} ` +
          `persona with ID ${conflictingPersona.personaID}. Click the button below to choose which persona ` +
          `to keep.`
        );
      },
      confirmResolveConflict(chosenPersona, externalPlatform, gameID) {
        // Open the dialog and save the info for the chosen persona for sending the request later.
        this.showConfirmDialog = true;
        this.conflictInfo = {
          chosenID: chosenPersona,
          game: gameID,
          personaType: externalPlatform === undefined ? 'Wizards' : 'External',
        };
      },
      cancelResolveConflict() {
        // Close the dialog and clear out any saved data.
        this.showConfirmDialog = false;
        this.conflictInfo = {
          chosenID: '',
          game: '',
          personaType: '',
        };
      },
      resolveConflict() {
        // Get account ID for conflict resolution.
        let accountID = this.accountID;

        // Build up the request.
        let reqURL = `${this.$store.getters.serviceURL}/admin/account/${accountID}/persona/${this.conflictInfo.chosenID}/resolve`;
        let reqData = JSON.stringify({
          gameID: this.conflictInfo.game,
          personaType: this.conflictInfo.personaType,
        });

        // Perform the resolution.
        axios
          .post(reqURL, reqData, this.$store.getters.bearerAuthHeaders)
          .then((response) => {
            // Reload the personas after call returns.
            this.showDialog = false;
            this.showConfirmDialog = false;
            this.conflictInfo = {
              chosenID: '',
              game: '',
              personaType: '',
            };
            this.loadPersonas();
          })
          .catch((err) => {
            this.errorMsg = err;
          });
      },
      setSelectedPersona(data) {
        data.expanded = !data.expanded;
        this.conflictInfo.selectedPersona = data.item;
        let conflictInfo = this.conflicts[data.item.personaID];

        if (conflictInfo.length > 1) {
          this.conflictInfo.conflictingPersona =
            conflictInfo[0].personaID === data.item.personaID
              ? conflictInfo[1]
              : conflictInfo[0];
        }
      },
      viewExternalPersona(personaID) {
        let routeData = this.$router.resolve(
          `/editexternal/${this.conflictInfo.selectedPersona.domainID}/${personaID}`
        );
        window.open(routeData.href, '_blank');
      },
    },
    computed: {
      hasError() {
        return this.errorMsg.length > 0;
      },
      getError() {
        return this.errorMsg;
      },
      accountID() {
        return this.$store.getters.account.accountID;
      },
      personas() {
        let data = this.$store.getters.personas;
        if (!Array.isArray(data)) {
          return [];
        }

        return data.map((p) => {
          p.roleTags = this.getTags(p.roles);
          return p;
        });
      },
      gameIDs() {
        let exclude = this.$store.getters.personas.map((p) => p.gameID);
        let ids = _.difference(this.$store.getters.gameIDs, exclude);

        this.disabled = _.size(ids) < 1;
        return ids;
      },
      getConfirmationMessage() {
        return (
          `By confirming, you will be keeping the persona ${this.conflictInfo.chosenID} and deleting any persona ` +
          `in conflict. This cannot be easily undone.`
        );
      },
    },
  };
</script>

<style scoped>
  .conflict-card {
    position: relative;
  }
  .conflict-card .card-actions button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
</style>
