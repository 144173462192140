<template>
  <div>
    <v-layout row justify-center align-center>
      <v-flex xs12 sm12 md12 lg12 box pa12>
        <v-toolbar flat>
          <v-toolbar-title>{{ this.title }}</v-toolbar-title>
          <span> (Using Fuzzy Search) </span>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <span v-if="isCreateMode()"
            >Friendships that exist for this user have been filtered out, if
            any</span
          >
        </v-toolbar>
        <v-data-table :headers="headers" :items="tableData" class="elevation-1">
          <template v-slot:items="props">
            <td>{{ props.item.source.display_name }}</td>
            <td>{{ props.item.source.account_id }}</td>
            <td>{{ props.item.source.email }}</td>
            <td class="justify-center layout px-0">
              <v-btn
                color="primary"
                @click="doAction(props.item.source.account_id)"
                >Select</v-btn
              >
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';

  export default {
    name: 'fuzzyResultsTable',
    props: {
      title: {
        type: String,
        default: 'Suggested Results',
      },
      mode: {
        type: String,
      },
      accountID: {
        type: String,
      },
      domainID: {
        type: String,
      },
    },
    data() {
      return {
        headers: [
          { text: 'Account Name', value: 'accountName' },
          { text: 'Account ID', value: 'accountID' },
          { text: 'Email', value: 'email' },
        ],
      };
    },
    methods: {
      doAction(item) {
        if (this.mode === 'query') {
          this.queryFriendship(item);
        } else if (this.mode === 'create') {
          this.createFriendship(item);
        }
      },
      queryFriendship(accountID) {
        let url =
          this.$store.getters.serviceURL +
          '/admin/accounts/account/' +
          accountID;

        axios.get(url, this.$store.getters.bearerAuthHeaders).then(() => {
          this.$store.dispatch('fetchFriendships', accountID);
        });

        this.$router.push('/social/' + accountID);
      },
      createFriendship(newFriendID) {
        let accountID = this.$route.params.accountID;

        let url =
          this.$store.getters.serviceURL +
          '/presence/admin/account/' +
          accountID +
          '/friends/create';

        let newFriendship = {
          DomainID: this.domainID,
          AccountID: accountID,
          friendAccountID: newFriendID,
        };

        axios
          .post(url, newFriendship, this.$store.getters.bearerAuthHeaders)
          .then(() => {
            // Refresh the page so friends update
            this.$router.go();
          })
          .catch(() => {});
      },
      isCreateMode() {
        return this.mode === 'create';
      },
    },
    computed: {
      tableData() {
        // Filter out friendships that already exist + the current account's ID from the results if we're doing create
        if (this.mode === 'create') {
          var currentFriends = this.$store.getters.friendshipResults;
          var fuzzyResults = this.$store.getters.fuzzyAccountSearchResults;
          var currAccountID = this.$route.params.accountID;

          // Filter out current account ID
          _.remove(fuzzyResults, function (el) {
            return el.source.account_id == currAccountID;
          });

          // Filter out existing friends
          _.remove(fuzzyResults, function (el) {
            return currentFriends.some(
              (e) => e.friendAccountID === el.source.account_id
            );
          });

          return fuzzyResults;
        } else {
          return this.$store.getters.fuzzyAccountSearchResults;
        }
      },
    },
  };
</script>

<style scoped>
  span {
    font-size: 12px;
    font-weight: normal;
    color: #a22;
    padding-left: 25px;
  }
</style>
